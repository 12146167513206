import { endPoints } from 'api/endpoints';
import axios from 'api/request';

const fetchMemberData = async (gpid) => {
    const response = await axios.get(`${endPoints.riServices.RI001.adGroupManager}?name=${gpid}`);
    return response.data;
};

export const fetchAllMembers = async (members) => {
    const memberPromises = members.map(fetchMemberData);
    const results = await Promise.all(memberPromises);

    return results
        .map((result) => {
            const memberData = result?.[0];
            if (!memberData) return null;
            return {
                label: `${memberData?.gpid} - ${memberData?.name}`,
                gpid: memberData?.gpid,
                name: memberData?.name,
                email: memberData?.email,
            };
        })
        .filter(Boolean); // Remove any null entries
};

export default fetchAllMembers;
