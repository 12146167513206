import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import toast from 'react-hot-toast';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HelpImg from 'pages/provisioning/_components/helpImg';
import throttle from 'lodash/throttle';
import { Grid, Typography, FormControl, FormControlLabel, Box, Stack, Alert, CircularProgress } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import useDebounce from 'pages/reference-implementation/provisioning/RI003O/hooks/use-debounce';
import Select, { CssTextField } from 'pages/reference-implementation/components/select';
import { getEndpointForCatalogCode } from 'pages/reference-implementation/utils';
import { CustomRadioGroup, CustomRadio, RIStyles } from 'pages/reference-implementation/Style';
import Btn from 'components/button';
import ProvisioningStyles from 'pages/provisioning/kafka/style';

const Sql = (props) => {
    const { resource, handleDrawerAction, secondApprover, handleSecondApprover, order } = props;

    const { catalogCode } = useParams();
    const riEndpoints = getEndpointForCatalogCode(catalogCode);

    const [formData, setFormData] = useState({
        IsADSQLAccount: resource?.configureData?.IsADSQLAccount || null,
        adSqlAccount: resource?.configureData?.adSqlAccount || null,
        sqlGrpOwner: resource?.configureData?.sqlGrpOwner || { groupOwner: null, groupOwnerDetails: null },
        adSqlAccountValid: resource?.configureData?.adSqlAccount !== null ? 'validated' : 'notValidated',
    });

    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [searched, setSearched] = React.useState(false);
    const [sqlGrpvalue, setSqlGrpValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [accountStatus, setAccountStatus] = React.useState({ valid: null });
    const [sqlSecondApprover, setSqlSecondApprover] = React.useState({
        selectedFieldFrom: { module: null, subModule: null },
        value: { groupOwner: null, groupOwnerDetails: null },
    });
    const [eligibleforGrpOwner, setEligibleforGrpOwner] = React.useState(false);
    const [approverChanged, setApproverChanged] = React.useState(false);
    const [disableGroupOwner, setDisableGroupOwner] = React.useState(false);
    const [grpOwnerChagned, setGrpOwnerChanged] = React.useState(false);
    const debouncedInputValue = useDebounce(formData?.adSqlAccount, 500);

    const serviceAccountYesApi = useMutation((payload) => axios.post(`${riEndpoints?.Jwt?.serviceAccountYesValidation}`, payload));
    const serviceAccountNoApi = useMutation((payload) => axios.post(`${riEndpoints?.Jwt?.serviceAccountNoValidation}`, payload));

    const adGroupOwnerApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.adGroupManager}?name=${ccInputValue}`));
    const adGroupOwnerPostApi = useMutation((payload) => axios.get(`${endPoints.riServices.COMMON.adGroupManager}?name=${payload}`));

    const handleOnSelectChange = (key, value) => {
        const tempFormData = { ...formData, adSqlAccount: null, sqlGrpOwner: { groupOwner: null, groupOwnerDetails: null } };
        let temperros = { sqlGrpOwner: '', adSqlAccount: '' };
        const tempTouched = {};
        toast.dismiss();
        if (key === 'IsADSQLAccount' && value === 'Yes') {
            setOptions([]);

            if (
                secondApprover?.value?.groupOwner !== null &&
                formData?.sqlGrpOwner?.groupOwnerDetails?.name &&
                formData?.sqlGrpOwner?.groupOwnerDetails?.name !== secondApprover?.value?.groupOwnerDetails?.name
            ) {
                setGrpOwnerChanged(false);
            }
            tempFormData.sqlGrpOwner = { groupOwner: null, groupOwnerDetails: null };
            temperros = { sqlGrpOwner: { type: '', message: '' }, adSqlAccount: { type: '', message: '' } };
        }
        if (key === 'IsADSQLAccount' && value === 'No') {
            setOptions([]);
            tempFormData.adSqlAccount = null;
            tempFormData.adSqlAccountValid = 'notValidated';
            temperros = { adSqlAccount: { type: '', message: '' } };
        }
        if (key === 'adSqlAccount' && value !== null) {
            tempFormData.adSqlAccountValid = 'notValidated';
        }
        if (
            key === 'sqlGrpOwner' &&
            (!secondApprover?.value?.groupOwnerDetails?.name ||
                (secondApprover?.value?.groupOwnerDetails?.name && secondApprover?.value?.groupOwnerDetails?.name !== value?.groupOwnerDetails?.name))
        ) {
            setSqlSecondApprover({
                selectedFieldFrom: { module: 'sql', subModule: null },
                value: { groupOwner: value.groupOwner, groupOwnerDetails: value.groupOwnerDetails },
            });
        }
        tempFormData[key] = value;
        tempTouched[key] = true;
        setFormData({
            ...formData,
            ...tempFormData,
        });

        setTouched(tempTouched);
        setErrors({ ...temperros });
    };
    const resetOptions = () => {
        setOptions([]);
        setFormData((prevdata) => ({
            ...prevdata,
            sqlGrpOwner: {
                groupOwner: null,
                groupOwnerDetails: null,
            },
        }));
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                adGroupOwnerApi.mutate();
            }, 1000),
        []
    );

    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };
    React.useEffect(() => {
        if (adGroupOwnerPostApi?.data?.data) {
            const combinedValue = adGroupOwnerPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [adGroupOwnerPostApi?.data?.data]);

    React.useEffect(() => {
        if (adGroupOwnerApi?.data?.data) {
            const combinedValue = adGroupOwnerApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [adGroupOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (sqlGrpvalue === null) {
            resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === sqlGrpvalue?.gpid));
            handleOnSelectChange('sqlGrpOwner', { groupOwner: sqlGrpvalue?.label, groupOwnerDetails: sqlGrpvalue });
        }
    }, [sqlGrpvalue]);

    const isExistsInOptions = (appownerValue) => options.find((option) => appownerValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && (formData?.sqlGrpOwner?.groupOwnerDetails || secondApprover?.value?.groupOwner)) {
            handleOnSelectChange('sqlGrpOwner', { groupOwner: options?.[0].label, groupOwnerDetails: options?.[0] });
        }
        if (options && options?.length > 1 && formData?.sqlGrpOwner?.groupOwnerDetails && !formData?.sqlGrpOwner?.groupOwner) {
            const adGroupObj = isExistsInOptions(formData?.sqlGrpOwner?.groupOwnerDetails?.name);
            handleOnSelectChange('sqlGrpOwner', { groupOwner: adGroupObj?.label, groupOwnerDetails: adGroupObj });
        }
    }, [options]);

    React.useEffect(() => {
        if (
            formData?.IsADSQLAccount === 'No' &&
            formData?.sqlGrpOwner?.groupOwnerDetails?.name &&
            secondApprover?.value?.groupOwnerDetails?.name &&
            !approverChanged &&
            !serviceAccountNoApi?.isLoading &&
            options?.length === 0 &&
            formData?.sqlGrpOwner?.groupOwnerDetails?.name !== secondApprover?.value?.groupOwnerDetails?.name
        ) {
            adGroupOwnerPostApi.mutate(secondApprover?.value?.groupOwnerDetails?.name);
        }

        if (
            formData?.IsADSQLAccount === 'No' &&
            secondApprover?.value?.groupOwnerDetails?.name &&
            approverChanged === false &&
            !serviceAccountNoApi?.isLoading &&
            grpOwnerChagned === false &&
            options?.length === 0 &&
            formData?.sqlGrpOwner?.groupOwnerDetails == null &&
            !adGroupOwnerPostApi?.isLoading
        ) {
            adGroupOwnerPostApi.mutate(secondApprover?.value?.groupOwnerDetails?.name);
        }
        if (
            formData?.IsADSQLAccount === 'No' &&
            formData?.sqlGrpOwner?.groupOwnerDetails?.name &&
            secondApprover?.value?.groupOwnerDetails?.name &&
            formData?.sqlGrpOwner?.groupOwnerDetails?.name === secondApprover?.value?.groupOwnerDetails?.name &&
            !approverChanged &&
            !serviceAccountNoApi?.isLoading &&
            options?.length === 0
        ) {
            adGroupOwnerPostApi.mutate(secondApprover?.value?.groupOwnerDetails?.name);
        }
    }, [formData, secondApprover, approverChanged, serviceAccountNoApi?.isLoading, options]);

    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };

    const validateForm = () => {
        const newErrors = {};
        if (formData?.IsADSQLAccount === 'Yes' && !formData.adSqlAccount) {
            newErrors.adSqlAccount = { type: 'required', message: 'AD SQL Account is required' };
            // newErrors.sqlGrpOwner = '';
        }
        if (
            formData?.IsADSQLAccount === 'Yes' &&
            formData.adSqlAccount &&
            formData.adSqlAccount !== null &&
            formData.adSqlAccount !== '' &&
            errors?.adSqlAccount?.type === 'required'
        ) {
            newErrors.adSqlAccount = errors?.adSqlAccount;
            // newErrors.sqlGrpOwner = '';
        }
        if (formData?.IsADSQLAccount === 'No' && !formData?.sqlGrpOwner) {
            newErrors.sqlGrpOwner = { type: 'required', message: 'AD SQL Account is required' };
            // newErrors.adSqlAccount = '';
        }
        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };
    const validateemptyForm = () => {
        const newErrors = {};
        if (formData?.IsADSQLAccount === null) {
            newErrors.IsADSQLAccount = { type: 'required', message: 'Please Select an Option' };
        }
        if (formData?.IsADSQLAccount === 'Yes' && !formData.adSqlAccount) {
            newErrors.adSqlAccount = { type: 'required', message: 'AD SQL Account is required' };
        }
        if (formData.IsADSQLAccount === 'Yes' && errors?.adSqlAccount?.type === 'required') {
            newErrors.adSqlAccount = errors?.adSqlAccount;
            // newErrors.sqlGrpOwner = '';
        }
        if (formData.IsADSQLAccount === 'Yes' && formData?.adSqlAccountValid === 'notValidated') {
            newErrors.adSqlAccount = errors?.adSqlAccount ? errors?.adSqlAccount : 'Given Service Account does not exist';
            // newErrors.sqlGrpOwner = '';
        }
        if (formData?.IsADSQLAccount === 'No' && !formData?.sqlGrpOwner?.groupOwnerDetails) {
            newErrors.sqlGrpOwner = { type: 'required', message: 'SQL Group Owner is required' };
        }
        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };
    React.useEffect(() => {
        if (serviceAccountYesApi?.data?.data !== null && accountStatus?.valid !== serviceAccountYesApi?.data?.data?.serviceAccountExist) {
            const res = { valid: serviceAccountYesApi?.data?.data?.serviceAccountExist };
            setAccountStatus(res);
            // handleOnChange(writeAccessUserValue, res);
            if (serviceAccountYesApi?.data?.data?.serviceAccountExist === false) {
                setFormData({ ...formData, adSqlAccountValid: 'notValidated' });

                setErrors({ ...errors, adSqlAccount: { type: 'required', message: 'Given Service Account does not exist' } });
            } else if (serviceAccountYesApi?.data?.data?.serviceAccountExist === true) {
                setFormData({ ...formData, adSqlAccountValid: 'validated' });

                setErrors({ ...errors, adSqlAccount: '' });
            }
        }
    }, [serviceAccountYesApi?.data?.data]);

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            IsADSQLAccount: true,
            adSqlAccount: true,
            sqlGrpOwner: true,
        });
        const checkFormValid = validateemptyForm();
        let finalCheck = { ...checkFormValid };
        if (formData?.adSqlAccount?.length > 2 && formData?.IsADSQLAccount === 'Yes' && formData?.adSqlAccountValid === 'notValidated') {
            finalCheck = { ...finalCheck, isValid: false };
        }
        if (
            (finalCheck?.isValid && !secondApprover?.value?.groupOwnerDetails?.name && formData?.sqlGrpOwner?.groupOwnerDetails?.name) ||
            (finalCheck?.isValid &&
                secondApprover?.value?.groupOwnerDetails?.name &&
                formData?.sqlGrpOwner?.groupOwnerDetails?.name &&
                secondApprover?.value?.groupOwnerDetails?.name !== formData?.sqlGrpOwner?.groupOwnerDetails?.name)
        ) {
            handleSecondApprover(sqlSecondApprover);
            toast.dismiss();
            toast.custom(
                <Box
                    sx={{
                        display: 'flex',
                        background: '#FFFFFF',
                        color: (theme) => theme.palette.black,
                        minWidth: '400px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: (theme) => theme.spacing(1),
                    }}
                >
                    <ReportProblemIcon sx={{ color: '#F99C24', margin: (theme) => theme.spacing(1) }} />
                    {sqlSecondApprover?.value?.groupOwnerDetails?.name} will be considered as Second Approver
                </Box>,
                { duration: 1200 }
            );
        }
        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && errors[field]?.type === 'required';
    };

    React.useEffect(() => {
        if (serviceAccountNoApi?.data?.data?.serviceAccountExist === false) {
            setDisableGroupOwner(false);
        } else if (serviceAccountNoApi?.data?.data?.serviceAccountExist === true) {
            setDisableGroupOwner(true);
        }
    }, [serviceAccountNoApi?.isSuccess]);
    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="sqlsave-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="sqlcancel-btn"
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        if (formData?.IsADSQLAccount === 'No' && eligibleforGrpOwner === false) {
            setEligibleforGrpOwner(true);
            serviceAccountNoApi.mutate({
                accountType: 'Active Directory',
                applicationShortName: order?.appShortName,
                environment: order?.environment,
            });
        }
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);
    useEffect(() => {
        if (debouncedInputValue) {
            serviceAccountYesApi.mutate({
                accountType: 'Active Directory',
                serviceAccountId: debouncedInputValue,
                environment: order?.environment,
            });
        }
    }, [debouncedInputValue]);
    return (
        <Box style={RIStyles.tags.wrapper} className="ri-layout">
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: 2 }}>
                            <Typography sx={RIStyles?.configuration?.cosmos?.heading}>Do you have an Active Directory SQL Account?</Typography>
                            <FormControl error={shouldShowError('IsADSQLAccount')}>
                                <CustomRadioGroup
                                    aria-label="IsADSQLAccount"
                                    name="isADSQLAccount"
                                    row
                                    value={formData.IsADSQLAccount}
                                    onChange={(event) => handleOnSelectChange('IsADSQLAccount', event.target.value)}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={
                                            <CustomRadio
                                                sx={{
                                                    color: `${shouldShowError('IsADSQLAccount') ? '#FF3939' : '#0047BA'}`,
                                                }}
                                            />
                                        }
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="No"
                                        sx={{
                                            marginLeft: '60px',
                                        }}
                                        control={
                                            <CustomRadio
                                                sx={{
                                                    color: `${shouldShowError('IsADSQLAccount') ? '#FF3939' : '#0047BA'}`,
                                                }}
                                            />
                                        }
                                        label="No"
                                    />
                                </CustomRadioGroup>
                            </FormControl>
                        </Box>
                        <Box sx={{ marginTop: '-25px' }}>
                            <HelpImg
                                maxmwidth="450px"
                                title={
                                    <>
                                        <Box sx={RIStyles.adinfoStyle.wrapper}>
                                            <Box sx={RIStyles.adinfoStyle.namingRuleWrapper}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sx={RIStyles.adinfoStyle.topLeftNamingRule}>
                                                        <Typography sx={RIStyles.infoStyle.typographyStyle}>
                                                            If you have an existing SQL Service Account, please provide it. Otherwise, a new Service Account
                                                            will be created for you in the below format :
                                                        </Typography>
                                                        <ul>
                                                            <li>
                                                                <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                                    {`Non-Prod: svcCV<appShortName>ite`}
                                                                </Typography>
                                                            </li>
                                                            <li>
                                                                <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                                                    {`Prod: svcCV<appShortName>prd`}
                                                                </Typography>
                                                            </li>
                                                        </ul>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </>
                                }
                            />
                        </Box>
                    </Grid>
                    {formData.IsADSQLAccount === 'Yes' && (
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} sx={RIStyles.stack}>
                                <CssTextField
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    placeholder="Active Directory SQL Account"
                                    label="Active Directory SQL Account *"
                                    value={formData?.adSqlAccount || ''}
                                    onChange={(e) => handleOnSelectChange('adSqlAccount', e?.target?.value)}
                                    className={shouldShowError('adSqlAccount') ? 'validate' : ''}
                                    error={shouldShowError('adSqlAccount')}
                                    helperText={shouldShowError('adSqlAccount') && errors.adSqlAccount.type === 'required' ? errors.adSqlAccount.message : ''}
                                    InputProps={{
                                        // ...params.InputProps,
                                        endAdornment: <>{serviceAccountYesApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                                    }}
                                />
                            </Stack>
                        </Grid>
                    )}

                    {serviceAccountNoApi?.data?.data.serviceAccountExist === true && formData.IsADSQLAccount === 'No' && (
                        <Alert severity="error" sx={{ mb: 1, ml: 1 }}>
                            Service Account already exists. Please use different Application Short Name
                        </Alert>
                    )}
                    {formData.IsADSQLAccount === 'No' && (
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} sx={RIStyles.stack}>
                                <Select
                                    key="sqlGrpOwner*"
                                    size="small"
                                    data-testid="sqlGrpOwner"
                                    noOptionsText={getNoOptionText()}
                                    loading={adGroupOwnerApi?.isLoading || adGroupOwnerPostApi?.isLoading}
                                    value={formData?.sqlGrpOwner.groupOwner || null}
                                    onInputChange={(event, newInputValue) => {
                                        if (secondApprover?.value?.groupOwnerDetails?.name && formData?.groupOwnerDetails?.name) {
                                            setApproverChanged(true);
                                        }
                                        if (secondApprover?.value?.groupOwnerDetails?.name && event?.target?.value) {
                                            setGrpOwnerChanged(true);
                                        }
                                        setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                                    }}
                                    disabled={disableGroupOwner || serviceAccountNoApi?.isLoading}
                                    fullWidth
                                    className={shouldShowError('sqlGrpOwner') ? 'validate' : ''}
                                    error={shouldShowError('sqlGrpOwner')}
                                    helpertext={errors?.showError && <span style={ProvisioningStyles.disposal.helpText}>{errors?.sqlGrpOwner?.message}</span>}
                                    onChange={(event, newValue) => {
                                        // istanbul ignore else
                                        if (typeof newValue === 'string') {
                                            setSqlGrpValue({
                                                label: newValue,
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a ccccccnew value from the user input
                                            setSqlGrpValue({
                                                label: newValue.inputValue,
                                            });
                                        } else {
                                            setSqlGrpValue(newValue);
                                        }
                                    }}
                                    filterOptions={(optionValues, params) => {
                                        // eslint-disable-next-line no-underscore-dangle
                                        const _results = [];
                                        // eslint-disable-next-line no-plusplus
                                        for (let i = 0; i < optionValues.length; i++) {
                                            // eslint-disable-next-line no-restricted-syntax
                                            for (const key in optionValues[i]) {
                                                if (
                                                    optionValues[i][key] !== null &&
                                                    typeof optionValues[i][key] !== 'boolean' &&
                                                    typeof optionValues[i][key] !== 'number'
                                                ) {
                                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                                        _results.push(optionValues[i]);
                                                    }
                                                }
                                            }
                                        }
                                        return [...new Set(_results)];
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    disableClearable
                                    id="free-solo-with-text-demo"
                                    options={options}
                                    isOptionEqualToValue={(option, value1) => option?.label === value1}
                                    renderOption={(renderprops, option) => (
                                        <li {...renderprops} key={option.gpid}>
                                            {option.label}
                                        </li>
                                    )}
                                    onBlur={() => {
                                        if (!formData.sqlGrpOwner || !formData?.sqlGrpOwner?.groupOwner) {
                                            setSearched(false);
                                            setOptions([]);
                                        }
                                    }}
                                    onKeyUp={(event) => {
                                        if (event.key === 'Enter') {
                                            setSearched(true);
                                            handleSearch();
                                        }
                                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                                            setSearched(false);
                                            resetOptions();
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <CssTextField
                                            size="large"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            label="SQL Service Owner *"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        <SearchIcon
                                                            sx={RIStyles?.form?.searchIcon}
                                                            onClick={() => {
                                                                setSearched(true);
                                                                handleSearch();
                                                            }}
                                                            data-testid="sqlsearch-btn"
                                                        />
                                                    </>
                                                ),
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

Sql.propTypes = {
    resource: PropTypes.any.isRequired,
    handleDrawerAction: PropTypes.func.isRequired,
    secondApprover: PropTypes.any.isRequired,
    handleSecondApprover: PropTypes.any.isRequired,
    order: PropTypes.any.isRequired,
};
// Sql.propTypes = {
//     secondApprover: null,
// };

export default Sql;
