import axios from 'api/request';
import { endPoints } from 'api/endpoints';

const fetchLandingZoneData = async (payload) => {
    const response = await axios.post(endPoints.landingZones.landingZones, payload);
    return response.data;
};

export const fetchLandingZones = async (payload) => {
    const result = await fetchLandingZoneData(payload);
    return result?.landingZones?.map((zone) => ({
        label: zone?.label,
        value: zone?.label,
        data: zone,
    }));
};

export const findLandingZoneById = (landingZones, id) => {
    return landingZones?.find((zone) => zone?.data?.id === id) || null;
};

export default fetchLandingZones;
