import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/myidm/store';
import { InputInfo } from 'pages/myidm/components/input-info';

export const ProjectName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [helperText, setHelperText] = React.useState(false);
    const [showHelperMsg, setHelperMsg] = React.useState('');

    const setProjectName = (projectName) => {
        setOrder({
            ...order,
            projectName,
        });
    };

    React.useEffect(() => {
        if (
            order?.projectName &&
            order?.projectName !== null &&
            order?.projectName !== '' &&
            order?.projectName !== undefined &&
            !/^[A-Za-z]{2,10}$/.test(order?.projectName)
        ) {
            setHelperText(true);
            setHelperMsg('Project name should be alphabets only with minimum 2 and maximum 10 characters.');
        } else {
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.projectName]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.projectName) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.projectName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.projectName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="project-name-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Project Name *"
                error={helperText}
                helperText={helperText && <span>{showHelperMsg}</span>}
                value={order?.projectName || ''}
                onChange={(e) => setProjectName(e.target.value)}
                className={validateInput()}
                data-testid="project-text"
                onKeyDown={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (event.target.value || event.target.value !== '')) {
                        setOrder({
                            ...order,
                            appShortName: null,
                        });
                    }
                }}
            />
            <InputInfo title="Project name should be alphabets only with minimum 2 and maximum 10 characters." />
        </Box>
    );
};

export default ProjectName;
