export const RI005 = {
    Jwt: {
        // provisioning
        previewOrder: 'api/reference-implementation/ri005/1.0/internal/order/preview',
        submitOrder: 'api/reference-implementation/ri005/1.0/internal/order/submit',
        reSubmit: 'api/reference-implementation/ri005/1.0/internal/order/resubmit',
        submitApproval: 'api/reference-implementation/ri005/1.0/internal/order/submit-approval',
        costCalculation: 'ri-cost-calculation.json', // 'api/reference-implementation/ri005/1.0/internal/cost-calculation',
        internalPreviewOrder: 'api/reference-implementation/ri005/1.0/internal/preview/order',
        downloadTF: 'api/reference-implementation/ri005/1.0/internal/order',
    },
};
export default RI005;
