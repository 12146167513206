/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Helmet } from 'react-helmet';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Typography, Stack, Grid } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { useRecoilState } from 'recoil';
import ErrorFallback from 'components/error-fallback';
import Empty from 'components/empty';
import Btn from 'components/button';
import { useHistory } from 'react-router-dom';
import { scope } from 'utils/scope';
import { getUserScopes } from 'utils';
import { catalogs } from 'store';
import { filterCatalogs, findNested, getModifiedCatalogs } from 'pages/services/utils';
import styles, { CssTextField } from './styles';

const CatalogService = React.lazy(() => import('pages/services/CatalogService'));
const ServiceFilter = React.lazy(() => import('pages/services/filter'));

function Services() {
    const history = useHistory();
    const [searchText, setSearchText] = React.useState('');
    const [catlogsData, setCatalogsData] = React.useState([]);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const refToTop = React.useRef();

    React.useEffect(() => {
        setCatalogsData(getModifiedCatalogs(cmpCatalogs));
    }, [cmpCatalogs]);

    const findSearched = (val) => {
        if (searchText === '') {
            setCatalogsData(getModifiedCatalogs(cmpCatalogs));
        } else {
            const find = findNested(cmpCatalogs, val);
            setCatalogsData(getModifiedCatalogs([...new Set(find)]));
        }
    };
    React.useEffect(() => {
        if (searchText === '') {
            setCatalogsData(getModifiedCatalogs(cmpCatalogs));
        } else {
            findSearched(searchText);
        }
    }, [searchText]);

    const filterData = (catalog) => {
        const result = filterCatalogs(cmpCatalogs, catalog);
        setCatalogsData(getModifiedCatalogs(result));
    };

    React.useEffect(() => {
        if (refToTop.current && typeof refToTop.current.scrollIntoView === 'function') {
            refToTop.current.scrollIntoView();
        }
    }, []);
    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Helmet>
                    <title>Services - CMP</title>
                </Helmet>
                <Suspense
                    fallback={
                        <Stack sx={styles.loadingStack}>
                            <Empty color="#000000" isLoading />
                        </Stack>
                    }
                >
                    <Stack spacing={2} sx={styles.mainstack} ref={refToTop} data-testid="services">
                        {cmpCatalogs?.length > 0 && (
                            <>
                                <Box sx={styles.headercontainer} spacing={1}>
                                    <Typography variant="h6" sx={styles.headingTypo}>
                                        {' '}
                                        Services
                                    </Typography>
                                    <Box sx={styles.actionContainer}>
                                        <Box sx={styles.serachbox} data-testid="search-box">
                                            <CssTextField
                                                fullWidth
                                                autoComplete="off"
                                                label="Search by App Name"
                                                size="small"
                                                variant="outlined"
                                                onChange={(e) => setSearchText(e.target.value)}
                                                value={searchText}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            {searchText && (
                                                                <IconButton
                                                                    aria-label="Search "
                                                                    sx={styles.clearicon}
                                                                    onClick={() => {
                                                                        setSearchText('');
                                                                    }}
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            )}
                                                            <>
                                                                <IconButton onClick={() => findSearched(searchText)} data-testid="find-search-icon">
                                                                    <SearchIcon sx={styles.searchicon} />
                                                                </IconButton>
                                                            </>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                        <ServiceFilter handleFilter={filterData} />
                                        {getUserScopes()?.includes(scope[2]) && (
                                            <Box sx={{ ml: 1 }}>
                                                <Btn size="medium" onClick={() => history.push('/catalogs')} variant="contained" color="cmpPrimary">
                                                    Edit Catalog
                                                </Btn>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                <Box sx={styles.gridWrapper} width="100%">
                                    <Grid container spacing={2} sx={styles.gridcontain}>
                                        {catlogsData?.length <= 0 && (
                                            <Grid item xs={12} key="empty-grid">
                                                <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" sx={styles.loadingStack}>
                                                    <Empty notFound color="#000000" />
                                                </Stack>
                                            </Grid>
                                        )}
                                        {catlogsData?.length > 0 &&
                                            catlogsData?.map((service, idx) => (
                                                <Grid item xs={3} md={3} lg={3} key={service.catalogDefinitionId} sx={styles.griditemcontain}>
                                                    <CatalogService service={service} shownumber={false} idx={idx + 1} color="#CCCCCC" />
                                                </Grid>
                                            ))}
                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Stack>
                </Suspense>
            </ErrorBoundary>
        </>
    );
}

export default Services;
