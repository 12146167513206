import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const headerStyles = {
    header: {
        background: '#000000',
        // background: '#001F50',
        flex: '0 1 auto',
        height: 'auto',
        //  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%)',
    },
    headermenu: {
        padding: { md: '18px  12px', lg: '18px 20px', xs: '10px 5px' },
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: 'normal',
    },

    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        // marginRight: (theme) => theme.spacing(4),
        '.menuIcon': {
            color: (theme) => theme.palette.white,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    small: {
        width: (theme) => theme.spacing(4),
        height: (theme) => theme.spacing(4),
        fontSize: (theme) => theme.spacing(1.7),
        fontWeight: 'bold',
        background: '#F79C36',
        color: '#000000',
        letterSpacing: 1,
    },
    listIcon: {
        minWidth: (theme) => theme.spacing(5),
        color: 'inherit',
    },
    button: {
        margin: (theme) => theme.spacing(1),
        color: (theme) => theme.palette.blue.light,
        height: (theme) => theme.spacing(5),
        padding: (theme) => theme.spacing(1, 2),
        '&:hover, &.active': {
            background: '#1D2434',
        },
        '& .btnTxt': {
            position: 'relative',
            top: 1,
        },
    },
    avatarTxt: {
        color: (theme) => theme.palette.white,
        fontSize: 18,
        textTransform: 'captalize',
        letterSpacing: 1,
    },
    menuitemColor: { color: (theme) => theme.palette.white, paddingTop: '5px', paddingBottom: '5px' },
    logoImgWidth: { width: 90 },
    dropdownArrow: { fontSize: (theme) => theme.spacing(4), paddingLeft: '10px', paddingBottom: '6px' },
    rightArrow: { fontSize: (theme) => theme.spacing(4), paddingLeft: '10px' },
    menuWrapper: { display: 'flex', height: '61px' },
    avatarSize: { minWidth: (theme) => theme.spacing(5) },
    cart: {
        container: {
            cursor: 'pointer',
            color: 'action.active',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiBadge-badge': {
                background: '#F99C24',
                color: '#FFFFFF',
                fontWeight: 400,
                fontSize: '12px',
            },
        },
        icon: {
            fontSize: 30,
            color: '#FFFFFF',
        },
    },

    listwrapper: { width: '100% !important', backgroundColor: '#5A5A5A', color: '#ffffff', padding: '0px 0px 0px 0px !important', transition: '0s' },

    collapsemenuitem: {
        backgroundColor: '#B9B6B6',
        paddingTop: '9px',
        paddingBottom: '15px',
        borderBottom: '1px solid #E5E4E4',
        width: '115%',
        transition: '0s',
        marginLeft: '-7.5%',
        '&:hover': {
            backgroundColor: '#B9B6B6',
            //    padding: '3px 2px',
        },
    },
    hovermenus: { '& ul.MuiList-root': { backgroundColor: '#5A5A5A', padding: 0 } },
    hovermenuitems: {
        backgroundColor: '#5A5A5A',
        borderBottom: '1px solid #242424',

        '&:hover': {
            backgroundColor: '#5A5A5A',
        },
    },
    disabled: {
        pointerEvents: 'none',
        color: '#aaa',
        cursor: 'not-allowed',
    },
    cascadingMenu: {
        menuItem: {
            navLink: {
                textDecoration: 'none',
                color: 'inherit',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:active': {
                    borderBottom: 'none',
                },
            },
            subMenu: {
                maxWidth: '350px',
                wordWrap: 'break-word',
                textWrap: 'wrap',
            },
            disabledNavLink: {
                pointerEvents: 'none',
                color: '#aaa',
                cursor: 'not-allowed',
            },
        },
    },
};

export const NavLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    textDecoration: 'none',
    fontFamily: 'Roboto',
    // margin: theme.spacing(1),
    color: theme.palette.white,
    height: 'auto',
    '&:hover': {
        background: '#1D2434',
    },
    '&:active': {
        borderBottom: '1px solid #64C6EA',
        borderRadius: '0px',
    },
}));

export default headerStyles;
