import _ from 'lodash';
import { AD_GROUP, LDAP_GROUP } from './constant';

export const getAddMemberPayload = ({ order, members }) => {
    const newArray = members?.map((obj) => {
        return _.omit(obj, ['label']);
    });

    const getMembers = (arr) => {
        const result = [];
        const pamsafeGPids = order?.mypamsafeDetails?.map((pam) => pam?.safeOwner);
        const getsafename = (safeobj) => order?.mypamsafeDetails?.filter((pam) => pam?.safeOwner === safeobj?.gpid)?.[0]?.safeName;

        arr?.map((obj) => {
            if (order?.mypamsafeDetails?.length > 0) {
                if (pamsafeGPids?.includes(obj?.gpid)) {
                    const mergedobj = { ...obj, safeName: getsafename(obj) };
                    result?.push(mergedobj);
                } else if (result?.length > 0 && result.some((res) => res?.gpid !== obj?.gpid)) {
                    const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                    result?.push(mergedobj);
                } else if (result?.length === 0 && result.some((res) => res?.gpid !== obj?.gpid)) {
                    const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                    result?.push(mergedobj);
                } else {
                    const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                    result?.push(mergedobj);
                }
            } else {
                const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                result?.push(mergedobj);
            }
            return '';
        });
        return result;
    };

    return {
        businessApplication: order?.businessApplication,
        environment: order?.environment,
        sector: order?.sector,
        groupType: order?.groupType,

        // Handling AD Group
        ...((!order?.isNewADGroup || order?.isNewADGroup === 'no') &&
            order?.groupType === AD_GROUP && {
                adGroup: order?.adGroup,
            }),

        // Handling LDAP Group
        ...((!order?.isNewADGroup || order?.isNewADGroup === 'no') &&
            order?.groupType === LDAP_GROUP && {
                ldapGroup: order?.adGroup,
            }),

        // New AD/LDAP Group flags
        newADGroup: order?.isNewADGroup === 'yes' && order?.groupType === AD_GROUP,
        newLDAPGroup: order?.isNewADGroup === 'yes' && order?.groupType === LDAP_GROUP,

        // AD Group specific details (when it's a new AD Group)
        ...(order?.groupType === AD_GROUP &&
            order?.isNewADGroup === 'yes' && {
                applicationShortName: order?.appShortName,
                groupOwner: {
                    gpid: order?.groupOwnerDetails?.gpid,
                    email: order?.groupOwnerDetails?.email,
                    name: order?.groupOwnerDetails?.name,
                },
                adGroupType: order?.adGroupTypeDetails?.value,
                applicationName: order?.applicationName,
                applicationService: order?.appService,
            }),

        // LDAP Group specific details (when it's a new LDAP Group)
        ...(order?.groupType === LDAP_GROUP &&
            order?.isNewADGroup === 'yes' && {
                project: order?.projectName,
                groupOwner: {
                    gpid: order?.groupOwnerDetails?.gpid,
                    email: order?.groupOwnerDetails?.email,
                    name: order?.groupOwnerDetails?.name,
                },
            }),

        // Members based on group type
        members: order?.adGroupType === 'Privileged' ? getMembers(newArray) : newArray,
    };
};

export const getDay2ResubmitRequestPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;
    const payload = {
        ...(catalog?.adGroup && {
            adGroup: catalog?.adGroup,
        }),
        ...(catalog?.groupType && {
            groupType: catalog?.groupType,
        }),
        ...(catalog?.ldapGroup && {
            ldapGroup: catalog?.ldapGroup,
        }),
        ...(catalog?.applicationShortName && {
            applicationShortName: catalog?.applicationShortName,
        }),
        ...(catalog?.groupOwner && {
            groupOwner: catalog?.groupOwner,
        }),
        ...(catalog?.adGroupType && {
            adGroupType: catalog?.adGroupType,
        }),
        ...(catalog?.applicationName && {
            applicationName: catalog?.applicationName,
        }),
        ...(catalog?.applicationService && {
            applicationService: catalog?.applicationService,
        }),
        ...(catalog?.project && {
            project: catalog?.project,
        }),
        newADGroup: catalog?.newADGroup,
        newLDAPGroup: catalog?.newLDAPGroup,
        businessApplication: catalog?.businessApplication,
        environment: catalog?.environment,
        sector: catalog?.sector,
        members: catalog?.members,
    };
    return payload;
};

export const formOrderObjFromOrderDetails = (data = null) => {
    if (data?.catalogServices?.[0]?.catalogConfig) {
        const response = data?.catalogServices?.[0]?.catalogConfig;
        const getPamsafedetails = (resmembers) => {
            const safeRes = resmembers?.filter((mem) => mem?.safeName !== 'SAFE NOT AVAILABLE');
            const result = safeRes?.map((safemem) => ({ safeOwner: safemem?.gpid, safeName: safemem?.safeName }));
            return result;
        };
        const getnonSafeDetails = (safemembers) => {
            const res = safemembers?.filter((mem) => mem?.safeName === 'SAFE NOT AVAILABLE');
            return res?.map((mems) => mems?.gpid);
        };
        return {
            businessApplication: response?.businessApplication,
            sector: response?.sector,
            environment: response?.environment,
            members: response?.members,
            adGroupType: response?.adGroupType,
            groupType: response?.groupType,
            ...(response?.adGroupType === 'Privileged' && {
                mypamsafeDetails: getPamsafedetails(response?.members),
                nonSafeDetailsGpid: getnonSafeDetails(response?.members),
            }),
            ...(response?.adGroupType !== 'Privileged' && {
                mypamsafeDetails: [],
                nonSafeDetailsGpid: [],
            }),
            ...((response?.adGroup || response?.ldapGroup) && {
                adGroup: response?.adGroup || response?.ldapGroup,
            }),
            ...(response?.groupType === AD_GROUP && {
                isNewADGroup: response?.newADGroup === true ? 'yes' : 'no',
            }),
            ...(response?.groupType === LDAP_GROUP && {
                isNewADGroup: response?.newLDAPGroup === true ? 'yes' : 'no',
            }),
            ...(response?.newADGroup === true &&
                response?.groupType === AD_GROUP && {
                    adGroupType: response?.adGroupType,
                    appShortName: response?.applicationShortName,
                    groupOwnerDetails: { name: response?.groupOwner?.name },
                    adGroupTypeDetails: { value: response?.adGroupType },
                    applicationName: response?.applicationName,
                    appService: response?.applicationService,
                }),
            ...(response?.newLDAPGroup === true &&
                response?.groupType === LDAP_GROUP && {
                    projectName: response?.project,
                    groupOwnerDetails: { name: response?.groupOwner?.name },
                }),
            isTouched: false,
        };
    }
    return null;
};
export const costPayload = ({ order = {}, catalogServiceId, day2Catalog }) => {
    const payload = {
        ...(catalogServiceId && { catalogServiceId }),
        cloudProvider: day2Catalog?.[0]?.cloudProvider,
        cloudService: day2Catalog?.[0]?.cloudService,
        catalogServiceCode: day2Catalog?.[0]?.catalogDefinitionId,
        catalogType: day2Catalog?.[0]?.catalogType,
        sector: order?.sector,
        quantity: 1,
        catalogConfig: {
            safeMemberCount: order?.nonSafeDetailsGpid?.length,
            isPrivileged: order?.adGroupType === 'Privileged',
        },
    };

    return payload;
};
