import { styled } from '@mui/material/styles';
import { Radio, RadioGroup } from '@mui/material';

const ProvisioningStyles = {
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchIcon: { cursor: 'pointer', color: '#A5A5A5' },
    tags: {
        subHeader: { fontWeight: 600, fontSize: '14px', color: '#000', fontFamily: 'Roboto', mb: 2 },
        wrapper: { minWidth: (theme) => theme.spacing(75), width: (theme) => theme.spacing(100), display: 'flex', flexDirection: 'column', height: '100%' },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2) },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(0.25) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#0047BA',
            textTransform: 'none',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#0047BA',
                border: '2px solid #0047BA',
                background: 'transparent',
            },
        },
    },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    project: {
        vsButton: { height: '32px', minWidth: '114px' },
        vsIcon: {
            minWidth: '42px',
            width: '42px',
            height: '32px',
        },
        configure: {
            duplicateError: {
                color: '#d32f2f',
                fontSize: '16px',
                fontFamily: 'Roboto',
            },
        },
    },
    vsType: {
        delete: {
            fontSize: '1.125rem',
            fill: '#F4273B',
            color: '#F4273B',
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
    },
    vsDetails: {
        boxWrapper: {
            background: '#e5ecf8',
            padding: '20px 15px',
            borderRadius: '6px',
        },
    },
    header: {
        wrapper: {
            background: (theme) => theme.palette.white,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
            height: '68px',
        },
        subWrapper: { display: 'flex', alignItems: 'center', ml: '32px', justifyContent: 'center' },
        ImgSpan: { display: 'flex', alignItems: 'center' },
        img: { width: '33.43px', height: '33.43px' },
        keyvaultimg: { width: '53.43px', height: '33.43px' },
        title: {
            pl: 2,
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: '20px',
            fontWeight: 'medium',
            fontFamily: 'Roboto',
        },
        infoIconStyle: { marginLeft: 1, marginTop: 1 },
        buttonWrapper: {
            mr: '53px',
        },
        backBtn: { marginLeft: '-20px' },
        backIcon: { color: (theme) => theme.palette.blue.main },
    },
};
export default ProvisioningStyles;

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
