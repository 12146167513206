import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { getEndpointForCatalogCode } from 'pages/reference-implementation/utils';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { useMutation } from 'react-query';
import axios from 'api/request';
import ProvisioningStyles from 'pages/provisioning/kafka/style';

const WriteAccessUser = (props) => {
    const { isRequired, handleOnSelect, writeAccessUserValue, errors, order, writeAccessUserValid } = props;
    const { catalogCode } = useParams();
    const riEndpoints = getEndpointForCatalogCode(catalogCode);
    const writeAccessUserApi = useMutation((payload) => axios.post(`${riEndpoints?.Jwt?.serviceAccountYesValidation}`, payload));

    const handleOnChange = (v, status = { valid: null }) => {
        handleOnSelect(v, status);
    };
    const handleField = () => {
        if (writeAccessUserValue?.length > 3) {
            writeAccessUserApi.mutate({
                accountType: 'Kafka',
                serviceAccountId: writeAccessUserValue,
                environment: order?.environment,
            });
        }
    };
    React.useEffect(() => {
        if (writeAccessUserApi?.data?.data?.serviceAccountExist === false) {
            ///      should be false
            handleOnChange(writeAccessUserValue, { valid: false });
        }
        if (writeAccessUserApi?.data?.data?.serviceAccountExist === true) {
            ///      should be true
            handleOnChange(writeAccessUserValue, { valid: true });
        }
    }, [writeAccessUserApi?.data?.data]);
    React.useEffect(() => {
        if (writeAccessUserValid === 'notValidated') {
            handleField();
        }
    }, [writeAccessUserValid]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                fullWidth
                placeholder="Write Access User"
                data-testid="writeAccessUser"
                label="Write Access Service Account*"
                autoComplete="off"
                error={errors?.showError || isRequired}
                helperText={errors?.showError && <span style={ProvisioningStyles.disposal.helpText}>{errors?.showMessage}</span>}
                value={writeAccessUserValue || ''}
                onChange={(event) => {
                    handleOnChange(event.target.value);
                }}
                onBlur={() => handleField()}
                className={isRequired || errors?.showError ? 'validate' : ''}
            />
            <HelpImg
                maxmWidth="450px"
                title={
                    <>
                        <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                            <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.topDividerRule}>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            Service account is required to provide access to Topics.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>2) Min.: 3 chars to Max.: 209 chars</Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            3) Can contain only underscore(_) as special char
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            4) Should not start or end with any special char
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            5) Should not contain consecutive underscores
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            6) Should not be same as Read Access User value
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

WriteAccessUser.propTypes = {
    isRequired: PropTypes.bool,
    handleOnSelect: PropTypes.func,
    writeAccessUserValue: PropTypes.any,
    errors: PropTypes.any,
    order: PropTypes.any,
    writeAccessUserValid: PropTypes.any,
};

WriteAccessUser.defaultProps = {
    isRequired: false,
    handleOnSelect: () => {},
    writeAccessUserValue: null,
    errors: {},
    order: {},
    writeAccessUserValid: 'notValidated',
};

export default WriteAccessUser;
