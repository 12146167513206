import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { customToast } from 'api/utils';
import { orderPayload, isDivisionRequired, isAppRegionRequired, isClusterLoading } from 'pages/provisioning/virtual-service/store';
import { platformManaged } from 'pages/provisioning/virtual-service/utils/getFunction';
import toast from 'react-hot-toast';

export const AksCluster = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const divisionReq = useRecoilValue(isDivisionRequired);
    const appRegionReq = useRecoilValue(isAppRegionRequired);
    const [clusterList, setClusterList] = React.useState([]);
    const [, setClusterLoading] = useRecoilState(isClusterLoading);
    const [invalidData, setInvalidData] = React.useState(false);

    const clusterApi = useMutation((payload) => axios.post(`${endPoints.virtualService.cluster}`, payload));

    React.useEffect(() => {
        if (clusterApi?.isSuccess) {
            setClusterLoading(false);
            if (!clusterApi?.data?.data) {
                setInvalidData(true);
                customToast(['Cluster details are not available for the given user inputs']);
            } else {
                setInvalidData(false);
                toast.dismiss();
                setClusterList(clusterApi?.data?.data?.clusters);
            }
        }
    }, [clusterApi?.isSuccess]);

    React.useEffect(() => {
        if (clusterApi?.isLoading) {
            setClusterLoading(true);
        }
    }, [clusterApi?.isLoading]);

    React.useEffect(() => {
        if (order?.department || invalidData) {
            setClusterLoading(false);
        }
    }, [order?.department]);

    React.useEffect(() => {
        if (clusterList?.length && order?.program) {
            const filterValue =
                order?.program === 'Not Applicable'
                    ? clusterList?.filter((item) => item?.program === 'Global Shared')
                    : clusterList?.filter((item) => item?.program === order?.program);

            setOrder({
                ...order,
                aksCluster: filterValue?.[0]?.aksClusterName,
                department: filterValue?.[0]?.department,
                istioGatewayUrl: filterValue?.[0]?.istioGatewayUrl,
            });
        }
    }, [clusterList, order?.program]);

    React.useEffect(() => {
        if (
            !(!order?.sector || !order?.environment || (divisionReq && order?.division === null) || (appRegionReq && order?.applicationRegionDetails === null))
        ) {
            const payload = {
                sector: order?.sector,
                environment: order?.environment,
                ...(order?.applicationRegion && { applicationRegion: order?.applicationRegion }),
                ...(order?.division && { division: order?.division }),
                platformManaged,
            };
            clusterApi.mutate(payload);
        }
    }, [order?.sector, order?.environment, divisionReq, order?.division, appRegionReq, order?.applicationRegion]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                    '& label.MuiInputLabel-shrink': {
                        color: '#0047BA !important',
                    },
                }}
                size="small"
                fullWidth
                autoComplete="off"
                label="AKS Cluster Name *"
                value={order?.aksCluster || ''}
                disabled
                InputProps={{
                    endAdornment: <>{clusterApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                }}
            />
        </Box>
    );
};

export default React.memo(AksCluster);
