import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import toast from 'react-hot-toast';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { OrderPayload, SecondaryApprover } from 'pages/reference-implementation/provisioning/RI003O/store';
import Select, { CssTextField } from 'pages/reference-implementation/components/select';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { RIStyles } from 'pages/reference-implementation/Style';

const PrivilegedADGroupOwner = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [searched, setSearched] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [secondApprover, setSecondaryApprover] = useRecoilState(SecondaryApprover);
    const [approverChanged, setApproverChanged] = React.useState(false);

    const adGroupOwnerApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.adGroupManager}?name=${ccInputValue}`));
    const adGroupOwnerPostApi = useMutation((payload) => axios.get(`${endPoints.riServices.COMMON.adGroupManager}?name=${payload}`));

    const setPrivilegedADGroupOwner = (privilegedADGroupOwner, privilegedADGroupOwnerDetails) => {
        if (privilegedADGroupOwner !== null && privilegedADGroupOwnerDetails !== null) {
            setSecondaryApprover({
                selectedFieldFrom: { module: 'AccessMangement', subModule: 'Privileged' },
                value: { groupOwner: privilegedADGroupOwner, groupOwnerDetails: privilegedADGroupOwnerDetails },
            });
            if (secondApprover?.value?.groupOwner && secondApprover?.value?.name !== privilegedADGroupOwnerDetails?.name) {
                toast.custom(
                    <Box
                        sx={{
                            display: 'flex',
                            background: '#FFFFFF',
                            color: (theme) => theme.palette.black,
                            minWidth: '300px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            padding: (theme) => theme.spacing(1),
                        }}
                    >
                        <ReportProblemIcon sx={{ color: '#F99C24', margin: (theme) => theme.spacing(1) }} />
                        {privilegedADGroupOwnerDetails?.name} will be considered as Second Approver
                    </Box>,
                    { duration: 5000 }
                );
            }
        }
        setOrder({
            ...order,
            privilegedADGroupOwner,
            privilegedADGroupOwnerDetails,
        });
    };
    React.useEffect(() => {
        if (order?.privilegedADGroup === 'No' && order?.nonPrivilegedADGroup === 'No' && order?.nonPrivilegedADGroupOwner) {
            if (!secondApprover?.value?.groupOwner) {
                setOrder({
                    ...order,
                    privilegedADGroupOwner: order?.nonPrivilegedADGroupOwner,
                    privilegedADGroupOwnerDetails: order?.nonPrivilegedADGroupOwnerDetails,
                });
            } else if (secondApprover?.value?.groupOwnerDetails?.name !== order?.nonPrivilegedADGroupOwnerDetails?.name) {
                setOrder({
                    ...order,
                    privilegedADGroupOwner: secondApprover?.value?.groupOwner,
                    privilegedADGroupOwnerDetails: secondApprover?.value?.groupOwnerDetails,
                });
            }
        }

        if (order?.nonPrivilegedADGroup === 'No' && order?.privilegedADGroup === 'No' && order?.privilegedADGroupOwner && secondApprover) {
            setOrder({
                ...order,
                privilegedADGroupOwner: secondApprover?.value?.groupOwner,
                privilegedADGroupOwnerDetails: secondApprover?.value?.groupOwnerDetails,
            });
        }

        if (
            order?.nonPrivilegedADGroup === 'No' &&
            order?.privilegedADGroup === 'No' &&
            !order?.privilegedADGroupOwner &&
            order?.nonPrivilegedADGroupOwner &&
            secondApprover &&
            order?.privilegedADGroupOwnerDetails?.name !== secondApprover?.value?.groupOwnerDetails?.name
        ) {
            setOrder({
                ...order,
                nonPrivilegedADGroupOwner: secondApprover?.value?.groupOwner,
                nonPrivilegedADGroupOwnerDetails: secondApprover?.value?.groupOwnerDetails,
            });
        }
    }, [order?.privilegedADGroup, order?.nonPrivilegedADGroup, order?.nonPrivilegedADGroupOwner, secondApprover]);
    const resetOptions = () => {
        setOptions([]);
        setPrivilegedADGroupOwner(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                adGroupOwnerApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };

    React.useEffect(() => {
        if (adGroupOwnerPostApi?.data?.data) {
            const combinedValue = adGroupOwnerPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [adGroupOwnerPostApi?.data?.data]);

    React.useEffect(() => {
        if (adGroupOwnerApi?.data?.data) {
            const combinedValue = adGroupOwnerApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [adGroupOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setPrivilegedADGroupOwner(value?.label, value);
        }
    }, [value]);

    const isExistsInOptions = (appownerValue) => options.find((option) => appownerValue === option.name);

    React.useEffect(() => {
        if (
            options &&
            options?.length === 1 &&
            (order?.privilegedADGroupOwnerDetails || secondApprover?.value?.groupOwner) &&
            order?.privilegedADGroupOwnerDetails?.name !== secondApprover?.value?.groupOwnerDetails?.name
        ) {
            setOrder({ ...order, privilegedADGroupOwner: options?.[0].label, privilegedADGroupOwnerDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.privilegedADGroupOwnerDetails && !order?.privilegedADGroupOwner) {
            const adGroupObj = isExistsInOptions(order?.privilegedADGroupOwnerDetails?.name);
            setOrder({ ...order, privilegedADGroupOwner: adGroupObj?.label, privilegedADGroupOwnerDetails: adGroupObj });
        }
    }, [options]);

    React.useEffect(() => {
        if (
            !order?.privilegedADGroupOwner &&
            order?.privilegedADGroupOwnerDetails &&
            order?.appOwner &&
            order?.businessContact &&
            !secondApprover?.value?.groupOwner
        ) {
            adGroupOwnerPostApi.mutate(order?.privilegedADGroupOwnerDetails?.name);
        }
        if (
            !order?.privilegedADGroupOwner &&
            !order?.privilegedADGroupOwnerDetails &&
            // order?.appOwner &&
            // order?.businessContact &&
            secondApprover?.value?.groupOwnerDetails?.name &&
            !approverChanged
        ) {
            adGroupOwnerPostApi.mutate(secondApprover?.value?.groupOwnerDetails?.name);
        }
        if (
            order?.privilegedADGroupOwner &&
            order?.privilegedADGroupOwnerDetails &&
            secondApprover?.value?.groupOwnerDetails &&
            secondApprover?.value?.groupOwnerDetails?.name !== order?.privilegedADGroupOwnerDetails?.name &&
            !approverChanged
        ) {
            adGroupOwnerPostApi.mutate(secondApprover?.value?.groupOwnerDetails?.name);
        }
    }, [order?.privilegedADGroupOwner, order?.privilegedADGroupOwnerDetails, order?.appOwner, order?.businessContact, secondApprover, approverChanged]);

    React.useEffect(() => {
        if (!order?.privilegedADGroupOwner && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.privilegedADGroupOwner, order?.isTouched]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.privilegedADGroupOwner) || isTouched) {
            return 'validate';
        }
        return '';
    };
    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };
    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    dataTestId="privileged-ad-group-owner-search"
                    key="AD Group Owner"
                    size="small"
                    noOptionsText={getNoOptionText()}
                    loading={adGroupOwnerApi?.isLoading}
                    value={order?.privilegedADGroupOwner || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);

                        if (secondApprover?.value?.groupOwnerDetails?.name && order?.privilegedADGroupOwnerDetails?.name) {
                            setApproverChanged(true);
                        }
                    }}
                    disabled={order?.environment?.toLowerCase() !== 'production' && order?.nonPrivilegedADGroup === 'No' && order?.privilegedADGroup === 'No'}
                    className={validateInput()}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a ccccccnew value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    isRequired={isTouched}
                    //    filterOptions={filterOptions}

                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    sx={{ width: '100%' }}
                    options={options}
                    isOptionEqualToValue={(option, value1) => option?.label === value1}
                    renderOption={(props, option) => (
                        <li {...props} key={option.gpid}>
                            {option.label}
                        </li>
                    )}
                    onBlur={() => {
                        if (!value || !order?.privilegedADGroupOwner) {
                            setSearched(false);
                            setOptions([]);
                            setApproverChanged(false);
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(true);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                        }
                    }}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label={<InputLabel label="AD Group Owner" isRequired />}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {order?.nonPrivilegedADGroup === 'No' && order?.privilegedADGroup === 'No' ? (
                                            <></>
                                        ) : (
                                            <SearchIcon
                                                sx={RIStyles?.form?.searchIcon}
                                                onClick={() => {
                                                    setSearched(true);
                                                    handleSearch();
                                                }}
                                                data-testid="search-btn"
                                            />
                                        )}
                                    </>
                                ),
                            }}
                            fullWidth
                        />
                    )}
                />
            </Box>
        </>
    );
};

export default React.memo(PrivilegedADGroupOwner);
