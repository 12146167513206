/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { Box, Typography, Button, Stack, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { cart, orderSuccessPopup, deleteCartItemId, deleteModalPopup, orderId, deleteByApplication, costSummaryData } from 'pages/cart/store';
import Empty from 'components/empty';
import { CartContainer, cartStyle } from 'pages/cart/style';
import ChekoutEmpty from 'assets/images/Chekout-Empty.png';
import AppAccordion from 'pages/cart/components/AppAccordion';
import DeleteConfirmModal from 'pages/cart/components/DeleteConfirmModal';
import OrderSuccessPopup from 'pages/cart/components/OrderSuccessPopup';
import Btn from 'components/button';
import CostSummary from './components/CostSummary';
import CostDetails from './components/CostDetails';
import CatalogDetails from './components/CatalogDetails';

function Cart() {
    const history = useHistory();
    const [cmpCart, setCart] = useRecoilState(cart);
    const [, setOrderId] = useRecoilState(orderId);
    const [orderSuccessModal, setOrderSuccessPopup] = useRecoilState(orderSuccessPopup);
    const [, setdeleteCartItemId] = useRecoilState(deleteCartItemId);
    const [, setDeleteByApplication] = useRecoilState(deleteByApplication);
    const [, setDeleteModalPopup] = useRecoilState(deleteModalPopup);
    const [isLoading, setLoading] = React.useState(false);
    const cartApi = useMutation(() => axios.get(endPoints.cart.getCart));
    const [, setCartData] = useRecoilState(costSummaryData);
    const resetStore = () => {
        setDeleteModalPopup(false);
        setdeleteCartItemId(undefined);
        setDeleteByApplication(undefined);
        setOrderSuccessPopup(false);
        setOrderId(undefined);
    };

    React.useEffect(() => {
        resetStore();
        cartApi.mutate();
    }, []);

    React.useEffect(() => {
        if (cartApi?.isSuccess) {
            setCartData(cartApi?.data?.data);
            setCart(cartApi?.data?.data?.cartSummary || []);
        }
    }, [cartApi?.isSuccess]);

    const handleSubmit = async () => {
        setLoading(true);
        const submitOrder = await axios.post(endPoints.cart.submitOrder);
        if (submitOrder?.isSuccess) {
            setOrderId(submitOrder?.data?.orderId);
            setOrderSuccessPopup(true);
            setLoading(false);
        }
    };
    const CartBtn = () => (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
            <Btn variant="contained" onClick={() => handleSubmit()} sx={cartStyle.submitOrdersBtn} disabled={isLoading}>
                {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit Orders'}
            </Btn>
            <Button
                disableRipple
                disableElevation
                variant="outlined"
                sx={cartStyle.continueShoppingBtn}
                onClick={() => {
                    resetStore();
                    history.push('/services');
                }}
            >
                Continue Shopping
            </Button>
        </Stack>
    );
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>Cart</title>
            </Helmet>
            <CartContainer>
                <Box sx={cartStyle.cartSummaryWrapper}>
                    <Typography sx={cartStyle.cartSummaryTitle}>Checkout</Typography>
                </Box>

                {cartApi?.isLoading && (
                    <Box display="flex" alignItems="center" justifyContent="center" height={250}>
                        <Empty color="#000000" isLoading={cartApi?.isLoading} />
                    </Box>
                )}
                {!cartApi?.isLoading && (cartApi?.isError || cmpCart?.length <= 0) && (
                    <Box width="100%" sx={cartStyle?.emptycontainer} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <img src={ChekoutEmpty} alt="" />
                        <Typography sx={cartStyle?.emptymsg}>There are no items in your cart</Typography>
                        <Button
                            disableRipple
                            disableElevation
                            variant="outlined"
                            sx={cartStyle.continueShoppingBtn}
                            onClick={() => {
                                resetStore();
                                history.push('/services');
                            }}
                        >
                            Keep Shopping
                        </Button>
                    </Box>
                )}
                {!cartApi?.isLoading && cmpCart?.length > 0 && (
                    <Box sx={cartStyle.costSummaryWrapper} display="flex" justifyContent="space-between">
                        <Box width="70%" sx={cartStyle?.costSummaryContainer}>
                            {cmpCart?.map((data, index) => (
                                <Box sx={cartStyle.appAccordianWrapper} key={index}>
                                    <AppAccordion data={data} defaultExpanded={index === 0} />
                                </Box>
                            ))}
                        </Box>
                        <Box width="30%" sx={{ paddingLeft: '15px' }}>
                            <CostSummary cartBtn={<CartBtn />} isLoading={cartApi?.isLoading} />
                        </Box>
                    </Box>
                )}
                <DeleteConfirmModal />
                {orderSuccessModal && <OrderSuccessPopup />}
                <CostDetails />
                <CatalogDetails />
            </CartContainer>
        </ErrorBoundary>
    );
}

export default Cart;
