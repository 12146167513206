import React from 'react';
import { Stack } from '@mui/material';
import { getScopes } from 'utils';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import SqlPaasProvisioningPage from 'pages/provisioning/sql';
import OracleProvisioningPage from 'pages/provisioning/oracle';
import IaaSSQLProvisioningPage from 'pages/provisioning/iaas-sql';
import StorageProvisioningPage from 'pages/provisioning/storage';
import AksProvisioningPage from 'pages/provisioning/aksns';
import KeyVaultProvisioningPage from 'pages/provisioning/key-vault';
import NSGProvisioningPage from 'pages/provisioning/nsg-common-rule';
import AlbProvisioningPage from 'pages/provisioning/alb';
import PrivateEndpointProvision from 'pages/provisioning/private-endpoint';
import PrivateEndpointProvision2 from 'pages/provisioning/private-endpoint-v2';
import KafkaProvisioningPage from 'pages/provisioning/kafka';
import Day2OperationPage from 'pages/provisioning/day2-operation';
import Day2OperationPaasPage from 'pages/provisioning/day2-operation-paas';
import EventHubProvisioningPage from 'pages/provisioning/event-hub';
import EipStackProvisioningPage from 'pages/provisioning/eip-stack';
import CouchbaseProvisioning from 'pages/provisioning/couchbase';
import AzueDataFactoryProvision from 'pages/provisioning/azure-data-factory';
import MyIdm from 'pages/myidm';
import OnboardDevelopers from 'pages/onboard-developers';
import ServiceAccountCreation from 'pages/service-account-creation';
import MdtOnboarding from 'pages/mdt-onboarding/provisioning';
import {
    LandingPage,
    LandingPageNew,
    OrdersPage,
    VmProvisioningPage,
    ApprovalsPage,
    PageNotFound,
    SkuPage,
    HelpDoc,
    // NewArticle,
    AppProfilePage,
    AppProfileDetailsPage,
    AppProfileDetailsPageNew,
    AppDefaultPage,
    LandingZonePage,
    Services,
    Dashboard,
    DashboardNew,
    Cart,
    Catalogs,
    InventoryList,
} from 'pages';
import SecurityTool from 'pages/security-tool';
import DashboardEnhancement from 'pages/dashboard-enhancement';
import RiServices from 'pages/reference-implementation/services';
import RiProvisioning from 'pages/reference-implementation/provisioning';
import SsoOktaPage from 'pages/sso-okta';
import ApplicationGateWayProvisioning from 'pages/provisioning/application-gateway';
import InternalApplicationGateWayProvisioning from 'pages/provisioning/internal-application-gateway';
import PublicLoadBalancerProvisioning from 'pages/provisioning/public-load-balancer';
import OnboardAppService from 'pages/onboard-app-service';
import ProjectOnboarding from 'pages/project-onboarding';
import DlopsProjectDashboard from 'pages/dlops-dashboard/projectDashboard';
import DlopsRegisteredModels from 'pages/dlops-dashboard/registeredModels';
import DlopsModelVersions from 'pages/dlops-dashboard/modelVersions';
import DlopsObservabilityModel from 'pages/dlops-dashboard/modelObservability';
import DlopsDriftImages from 'pages/dlops-dashboard/ModelDriftImages';
import DlopsModelComparision from 'pages/dlops-dashboard/modelComparision';
import DlopsModelOnboarding from 'pages/dlops-dashboard/modelOnBoarding';
import DlopsNewModelCreation from 'pages/dlops-dashboard/modelOnBoarding/createNewModel';
import DlopsNewModelTags from 'pages/dlops-dashboard/modelOnBoarding/createNewModelTags';
import DlopsEditExistingProject from 'pages/dlops-dashboard/editProjects';
import DlopsEditExistingModel from 'pages/dlops-dashboard/editProjects/editExistingModel';
import DlopsEditExistingModeltags from 'pages/dlops-dashboard/editProjects/editExistingModelTags';
import DlopsAddNewModel from 'pages/dlops-dashboard/addNewModel';
import DlopsAddNewModelTags from 'pages/dlops-dashboard/addNewModel/addNewModelTags';
import VirtualService from 'pages/provisioning/virtual-service';
import NewInternalAppGateWayProvisioning from 'pages/provisioning/internal-app-gateway-provisioning';

const style = {
    // flex: '1 1 0%',
    // display: 'flex',
    overflow: 'hidden',
    minHeight: 'calc(100vh - 98px)',
};

const MainRoute = [
    { path: '/landing', component: LandingPage, allowedScopes: [] },
    { path: '/', component: LandingPageNew, allowedScopes: [] },
    { path: '/dashboard-old', component: Dashboard, allowedScopes: ['orderMgmt.write', 'CMPDashboardViewer'] },
    { path: '/dashboard-new', component: DashboardNew, allowedScopes: ['orderMgmt.write', 'CMPDashboardViewer'] },
    { path: '/dashboard', component: DashboardEnhancement, allowedScopes: ['orderMgmt.write', 'CMPDashboardViewer'] },
    // { path: '/dlops-overview-dashboard', component: DlopsDashboard, allowedScopes: [] },
    { path: '/dlops-projects', component: DlopsProjectDashboard, allowedScopes: [] },
    { path: '/dlops-registered-models', component: DlopsRegisteredModels, allowedScopes: [] },
    { path: '/dlops-model-versions', component: DlopsModelVersions, allowedScopes: [] },
    { path: '/dlops-model-observability', component: DlopsObservabilityModel, allowedScopes: [] },
    { path: '/dlops-drift-images', component: DlopsDriftImages, allowedScopes: [] },
    { path: '/dlops-model-comparision', component: DlopsModelComparision, allowedScopes: [] },
    { path: '/dlops-model-onboarding', component: DlopsModelOnboarding, allowedScopes: [] },
    { path: '/dlops-new-modelCreation', component: DlopsNewModelCreation, allowedScopes: [] },
    { path: '/dlops-new-modeltags', component: DlopsNewModelTags, allowedScopes: [] },
    { path: '/dlops-edit-existing-project', component: DlopsEditExistingProject, allowedScopes: [] },
    { path: '/dlops-edit-existing-model', component: DlopsEditExistingModel, allowedScopes: [] },
    { path: '/dlops-edit-existing-modeltags', component: DlopsEditExistingModeltags, allowedScopes: [] },
    { path: '/dlops-add-new-model', component: DlopsAddNewModel, allowedScopes: [] },
    { path: '/dlops-add-newmodeltags', component: DlopsAddNewModelTags, allowedScopes: [] },
    { path: '/orders', component: OrdersPage, allowedScopes: [] },
    {
        path: '/vm-provisioning/:osType/:serverType/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: VmProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/oracle-provisioning/:osType/:serverType/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: OracleProvisioningPage,
        allowedScopes: [],
    },
    { path: '/sql-paas-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?', component: SqlPaasProvisioningPage, allowedScopes: [] },
    {
        path: '/iaas-sql-provisioning/:osType/:serverType/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: IaaSSQLProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/azure-storage-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: StorageProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/aks-nms-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: AksProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/azure-key-vault/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: KeyVaultProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/nsg-common-rule/:catalogCode',
        component: NSGProvisioningPage,
        allowedScopes: ['orderMgmt.write', 'nsg.write'],
    },
    {
        path: '/azure-load-balancer/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: AlbProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/kafka-topic-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: KafkaProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/private-endpoint-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: PrivateEndpointProvision2,
    },
    {
        path: '/private-endpoint-provisioning-old/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: PrivateEndpointProvision,
    },
    {
        path: '/day2-operation/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: Day2OperationPage,
        allowedScopes: [],
    },
    {
        path: '/day2-operation-paas/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: Day2OperationPaasPage,
        allowedScopes: [],
    },
    {
        path: '/event-hub-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: EventHubProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/app-microservices-stack/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: EipStackProvisioningPage,
        allowedScopes: [],
    },
    {
        path: '/couchbase-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: CouchbaseProvisioning,
        allowedScopes: [],
    },
    {
        path: '/azure-data-factory-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: AzueDataFactoryProvision,
        allowedScopes: [],
    },
    { path: '/approvals', component: ApprovalsPage, allowedScopes: [] },
    { path: '/sku', component: SkuPage, allowedScopes: ['orderMgmt.write'] },
    { path: '/help', component: HelpDoc, allowedScopes: [] },
    // {
    //     path: '/access-management-myidm',
    //     component: MyIdm,
    //     allowedScopes: ['orderMgmt.write'],
    // },
    {
        path: '/access-management-myidm/:catalogCode/:operation?/:orderId?/:catalogServiceId?',
        component: MyIdm,
        allowedScopes: [],
    },
    {
        path: '/service-account-creation/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: ServiceAccountCreation,
        allowedScopes: [],
    },
    {
        path: '/service-account-ci/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: ServiceAccountCreation,
        allowedScopes: [],
    },

    // { path: '/new-article', component: NewArticle, allowedScopes: [] }, // commented since we parked this feature.
    { path: '/app-profile', component: AppProfilePage, allowedScopes: [] },
    { path: '/app-details-new/:id', component: AppProfileDetailsPage, allowedScopes: [] },
    { path: '/app-details/:id/:operation?/:landingZoneId?', component: AppProfileDetailsPageNew, allowedScopes: [] },
    { path: '/app-default', component: AppDefaultPage, allowedScopes: [] },
    { path: '/landing-zones/:appProfileName/:appProfileId', component: LandingZonePage, allowedScopes: [] },
    { path: '/landing-zones/:appProfileName/:appProfileId/:profileType', component: LandingZonePage, allowedScopes: [] },
    { path: '/services', component: Services, allowedScopes: [] },
    { path: '/cart', component: Cart, allowedScopes: [] },
    { path: '/catalogs/:catalogDefinitionId?', component: Catalogs, allowedScopes: ['orderMgmt.write'] },
    { path: '/inventory-list', component: InventoryList, allowedScopes: [] },
    { path: '/sso-okta/:catalogCode?/:operation?/:cartItemId?/:catalogServiceId?', component: SsoOktaPage, allowedScopes: [] },
    { path: '/onboard-application-service/:catalogCode?/:operation?/:cartItemId?/:catalogServiceId?', component: OnboardAppService, allowedScopes: [] },
    { path: '/security-tool/:catalogCode?/:operation?/:cartItemId?/:catalogServiceId?', component: SecurityTool, allowedScopes: [] },
    { path: '/project-onboarding/:catalogCode?/:operation?/:cartItemId?/:catalogServiceId?', component: ProjectOnboarding, allowedScopes: [] },
    // RI Modules
    { path: '/reference-implementation-services', component: RiServices, allowedScopes: [] },
    {
        path: '/reference-implementation-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: RiProvisioning,
        allowedScopes: [],
    },
    {
        path: '/access-management-onboard-developers',
        component: OnboardDevelopers,
        allowedScopes: [],
    },
    {
        path: '/azure-application-gateway-url-onboarding/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: ApplicationGateWayProvisioning,
        allowedScopes: [],
    },
    {
        path: '/azure-internal-application-gateway-url-onboarding/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: InternalApplicationGateWayProvisioning,
        allowedScopes: [],
    },
    {
        path: '/azure-external-public-load-balancer-url-onboarding/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: PublicLoadBalancerProvisioning,
        allowedScopes: [],
    },
    {
        path: '/azure-new-internal-application-gateway-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: NewInternalAppGateWayProvisioning,
        allowedScopes: [],
    },
    {
        path: '/mdt-onboarding',
        component: MdtOnboarding,
        allowedScopes: [],
    },
    {
        path: '/aks-vs-provisioning/:catalogCode/:operation?/:cartItemId?/:catalogServiceId?',
        component: VirtualService,
        allowedScopes: [],
    },
];

function ModuleRouter() {
    // const AccessRoles = getUserRoles();
    const AccessScopes = getScopes();

    return (
        <Stack style={style}>
            <Switch>
                {MainRoute.map((route, index) => {
                    // const isRoleAllowed =
                    //     !route?.allowedRoles || route?.allowedRoles?.length === 0 || route?.allowedRoles?.some((role) => AccessRoles?.includes(role));
                    const isScopeAllowed =
                        !route?.allowedScopes || route?.allowedScopes?.length === 0 || route?.allowedScopes?.some((scope) => AccessScopes?.includes(scope));
                    // if (isRoleAllowed && isScopeAllowed) {
                    if (isScopeAllowed) {
                        return <SecureRoute key={`route-${index.toString()}`} exact path={route.path} component={route.component} />;
                    }

                    return null;
                })}
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Stack>
    );
}

export default ModuleRouter;
