import { atom } from 'recoil';

export const toggleSqlPassApplication = atom({
    key: 'toggleSqlPassApplication',
    default: false,
});

export const toggleSqlPassTags = atom({
    key: 'toggleSqlPassTags',
    default: false,
});

export const toggleCostSplit = atom({
    key: 'toggleCostSplit',
    default: false,
});

export const toggleSqlPassSubmit = atom({
    key: 'toggleSqlPassSubmit',
    default: false,
});

export const toggleSqlPassUpdate = atom({
    key: 'toggleSqlPassUpdate',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'isMandatoryTagsSQLPaas',
    default: false,
});

export const isLogicalHostNameAlreadyExist = atom({
    key: 'isLogicalHostNameAlreadyExist',
    default: false,
});
export const isSecondaryLogicalHostNameAlreadyExist = atom({
    key: 'sqlpaas-isLogicalHostNameAlreadyExist',
    default: false,
});
export const isPoolNameAlreadyExist = atom({
    key: 'isPoolNameAlreadyExist',
    default: false,
});

export const isLogicalHostNameValid = atom({
    key: 'isLogicalHostNameValid',
    default: false,
});
export const isSecondaryLogicalHostNameValid = atom({
    key: 'SqlPaas-isSecondayLogicalHostNameValid',
    default: false,
});
export const isSQLDbNameAlreadyExist = atom({
    key: 'isSQLDbNameAlreadyExist',
    default: false,
});
export const isSecondarySQLDbNameAlreadyExist = atom({
    key: 'sqlpaas-isSecondarySQLDbNameAlreadyExist',
    default: false,
});
export const isSQLDbNameValid = atom({
    key: 'isSQLDbNameValid',
    default: false,
});
export const isSecondarySQLDbNameValid = atom({
    key: 'sqlpaas-isSecondarySQLDbNameValid',
    default: false,
});

export const orderId = atom({
    key: 'sqlPaaSorderId',
    default: '',
});
export const toggleQuickConfig = atom({
    key: 'toggleQuickConfigSQLPaaS',
    default: false,
});
export const quickConfigDetails = atom({
    key: 'quickConfigDetailsSQLPaaS',
    default: undefined,
});
export const toggleSspModels = atom({
    key: 'toggleSspModelsSQLPaaS',
    default: {
        isCost: { costType: '', isCostOpen: false },
        iszoneRedundancyModal: false,
        isGeoReplicationModal: false,
    },
});
export const sspSubmitOrder = atom({
    key: 'sqlPassOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        sector: null,
        sectorDetail: null,
        environment: null,
        region: null,
        regionDisplayName: null,
        serverCollation: null,
        sqlDatabaseName: '',
        deploymentType: null,
        logicalHostExist: null,
        logicalHost: null,
        elasticPoolExist: null,
        elasticPoolName: '',
        geoReplication: null,
        deploymentModel: null,
        deploymentModelDetails: null,
        serviceTiers: null,
        serviceTiersDetails: null,
        type: null,
        storageSize: null,
        storageSizeDetails: null,
        vCoresCount: null,
        vCoreDetails: null,
        tags: [],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR services cost & any Pepsico internal chargebacks',
        },
        defaultTags: [],
        costCenter: null,
        costCenterDetails: null,
        billingEntity: null,
        billingEntityDetails: null,
        envDetails: null,
        hoursOfOperation: null,
        hoursOfOperationDetail: null,
        secondarylogicalHostExist: null,
        secondarylogicalHost: null,
        secondarysqlDatabaseName: '',
        geoSecondaryReplication: 'no',
        validationStatus: 'clearValidation',
        isTouched: false,
        isManualDBName: false,
        zoneRedundancy: 'no',
    },
});

export const environmentDetails = atom({
    key: 'sqlPaasEnvDetails',
    default: {
        subscription: '---',
        // Virtual_Network: '-',
        'DR Priority': '---',
        Project: '---',
        GeoReplication: '---',
        Resource_Group: '---',
        // Subnet_Mask: '-',
        Approval_Manager: '---',
        Backup_Retention: '---',
        App_Tier: '---',
        Service_Endpoints: '---',
    },
});

export const costEstimateState = atom({
    key: 'sqlPaaS-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'sqlPaas-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
export const isElasticPoolNameVaild = atom({
    key: 'isElasticPoolNameVaild',
    default: true,
});
