import React from 'react';
import { Grid, Box } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import CostSummary from 'pages/provisioning/_components/CostSummary';
import IsADGroupExistForm from 'pages/myidm/IsADGroupExistForm';
import ADGroupForm from 'pages/myidm/components/sections/ADGroupForm';
import { OrderPayload, showisExistADGrp, toggleSspModels, costEstimateState } from 'pages/myidm/store';
import MemberslList from 'pages/myidm/components/sections/MemberslList';
import { SubmitRequest, CancelRequest } from 'pages/myidm/components';
import ADGroupMembers from 'pages/myidm/components/sections/ADGroupMembers';
import LdapGroupMembers from 'pages/myidm/components/sections/LdapGroupMembers';
import { AD_GROUP, LDAP_GROUP } from 'pages/myidm/utils/constant';

const SspForm = () => {
    const isExistAdGrp = useRecoilValue(showisExistADGrp);
    const order = useRecoilValue(OrderPayload);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [costEstimate] = useRecoilState(costEstimateState);

    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };
    return (
        <Box padding={4}>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Grid container spacing={2} sx={{ marginTop: '0px' }}>
                        <Grid item xs={12}>
                            {(isExistAdGrp || order?.isNewADGroup === 'yes' || order?.isNewADGroup === 'no') && <IsADGroupExistForm />}
                        </Grid>
                        <ADGroupForm />
                        <Grid item xs={12}>
                            {' '}
                            <MemberslList />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={1} flexDirection="column">
                        {order?.estimatedCost?.details?.length > 0 && costEstimate?.details?.length > 0 && (
                            <Grid item xs={12}>
                                <Box sx={{ marginTop: `${order?.isNewADGroup === 'yes' ? '19px' : '31px'}` }}>
                                    <CostSummary
                                        handleCostInfoBtn={handleCostInfoBtn}
                                        cartBtn={
                                            <Box sx={{ disaplay: 'flex', flexDirection: 'column' }} gap={1}>
                                                <SubmitRequest />
                                                <CancelRequest />
                                            </Box>
                                        }
                                        costEstimate={costEstimate}
                                    />
                                </Box>
                            </Grid>
                        )}

                        {order?.adGroup && (
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        marginTop: `${
                                            (order?.estimatedCost?.details?.length > 0 && costEstimate?.details?.length > 0) || order?.isNewADGroup === 'no'
                                                ? '15px'
                                                : '32px'
                                        }`,
                                    }}
                                >
                                    {order?.groupType === AD_GROUP && <ADGroupMembers />}
                                    {order?.groupType === LDAP_GROUP && <LdapGroupMembers />}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SspForm;
