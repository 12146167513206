import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import Empty from 'components/empty';
import axios from 'api/request';
import ProvisioningStyles from 'pages/myidm/style';
import ProvisioningHeader from 'pages/myidm/ProvisioningHeader';
// import IsADGroupExistForm from 'pages/myidm/IsADGroupExistForm';
import { endPoints } from 'api/endpoints';
import {
    memberForm,
    optionsList,
    selectedMembersList,
    OrderPayload,
    toggleConfirmModel,
    invalidMember,
    orderSuccessPopup,
    showisExistADGrp,
    isAppShortNameExist,
    isAppShortNameValid,
    isAppNameValid,
    costApiLoading,
} from 'pages/myidm/store';
import { catalogs } from 'store';
// import ADGroupMembers from './components/Form/ADGroupMembers';
import { formOrderObjFromOrderDetails } from './utils/getPayload';
import ShowCostDetails from './ShowCostDetails';
import SspForm from './components/sections/SspForm';
import MembersListModal from './MembersListModal';

const MyIdmPage = () => {
    const [, setOptions] = useRecoilState(optionsList);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [modal, setModal] = useRecoilState(toggleConfirmModel);
    const [form, setForm] = useRecoilState(memberForm);
    const [, setMembers] = useRecoilState(selectedMembersList);
    const [, setInvalid] = useRecoilState(invalidMember);
    const [, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [, setAppShortNameExist] = useRecoilState(isAppShortNameExist);
    const [, setIsExistADGrp] = useRecoilState(showisExistADGrp);
    const [, resetSuccessPopup] = useRecoilState(orderSuccessPopup);
    const [, setApplicationNameVaild] = useRecoilState(isAppNameValid);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [, setiscostApiLoading] = useRecoilState(costApiLoading);

    const { catalogCode, operation, orderId } = useParams();
    const orderDetailsApi = useMutation(() => axios.get(`${endPoints.riServices.Jwt.orderDetails}/${orderId}`));
    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }
    const day2catalog = cmpCatalogs?.filter((catlg) => catlg?.catalogDefinitionId === catalogCode);
    React.useEffect(() => {
        toast.dismiss();
    }, [order]);
    const resetForm = () => {
        setOptions([]);
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
        setMembers([]);
        setOrder({
            ...order,
            cloudProvider: day2catalog?.[0]?.cloudProvider,
            cloudService: day2catalog?.[0]?.cloudService,
            catalogServiceCode: day2catalog?.[0]?.catalogDefinitionId,
            catalogType: day2catalog?.[0]?.catalogType,
            adGroup: null,
            businessApplication: null,
            sector: null,
            environment: null,
            adGroupType: null,
            adGroupTypeDetails: null,
            isNewADGroup: null,
            applicationName: null,
            appShortName: null,
            groupOwner: null,
            groupOwnerDetails: null,
            adGroupMembers: null,
            isUniversalADGroup: false,
            crossDomainUser: null,
            mypamsafeDetails: [],
            isTouched: false,
            nonSafeDetailsGpid: [],
        });
        setInvalid(true);
        setModal({ ...modal, isOpen: false, message: '', removeAll: false, removeAllAction: false });
        resetSuccessPopup(false);
        setAppShortNameVaild(false);
        setAppShortNameExist(false);
        setIsExistADGrp(false);
        setApplicationNameVaild(false);
        setiscostApiLoading(false);
    };
    const getOrderDetails = async () => {
        try {
            if (orderId && operation) {
                const { data: orderDetails } = await orderDetailsApi.mutateAsync();
                const costs = await getCosts();
                const orderObj = formOrderObjFromOrderDetails(orderDetails);
                if (operation === 'edit') {
                    setMembers(orderObj?.members);
                    setOrder({
                        ...order,
                        cloudProvider: day2catalog?.[0]?.cloudProvider,
                        cloudService: day2catalog?.[0]?.cloudService,
                        catalogServiceCode: day2catalog?.[0]?.catalogDefinitionId,
                        catalogType: day2catalog?.[0]?.catalogType,
                        ...orderObj,
                        emptyCost: costs,
                        isTouched: false,
                    });
                }
            }
        } catch (err) {
            // eslint-disable-next-line
            console.log('Error');
        }
    };
    useEffect(() => {
        resetForm();
        return () => resetForm();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        return () => resetForm();
        // eslint-disable-next-line
    }, [operation]);

    useEffect(() => {
        getOrderDetails();
    }, [operation, orderId]);

    return (
        <>
            <Helmet>
                <title>Adding Members to Active Directory Group - CMP</title>
            </Helmet>
            {orderDetailsApi?.isLoading ? (
                <Stack sx={ProvisioningStyles?.emptyStack}>
                    <Empty isLoading color="#000000" msg="Loading..." />
                </Stack>
            ) : (
                <>
                    <ProvisioningHeader />
                    {/* <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Grid display="flex" flexDirection="column" padding={4} gap={3}>
                                {(isExistAdGrp || order?.isNewADGroup === 'yes' || order?.isNewADGroup === 'no') && <IsADGroupExistForm />}
                                <ADGroupForm />
                                <MemberslList />
                            </Grid>
                        </Grid>
                        
                        {order?.adGroup && (
                            <Grid item xs={4}>
                                <ADGroupMembers />
                            </Grid>
                        )}
                    </Grid> */}
                    <SspForm />
                    <ShowCostDetails />
                    <MembersListModal />
                </>
            )}
        </>
    );
};

export default MyIdmPage;
