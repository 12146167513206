import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Drawer, Typography, Box, TextField, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, Select, MenuItem } from '@mui/material';
import { format } from 'date-fns';

const SidePanelBaselineProductionDrawer = ({ open, onClose, data, onTraningBaseline, onProductionDateRangeClick }) => {
    const [currentScreen, setCurrentScreen] = useState('initial'); // For managing screens: 'initial', 'production', 'baseline'
    const [rangeOption, setRangeOption] = useState('moving');
    const baselineSource = data?.tags?.find((tag) => tag.key === 'baseline_datasource')?.value || '';
    const [inputValue, setInputValue] = useState(baselineSource);
    const [productionToDataRange, setProductionToDataRange] = useState('0'); // default to "1 week"
    const [fromTimeUnit, setFromTimeUnit] = useState('week'); // default to "week"
    const [productionFromDataRange, setProductionFromDataRange] = useState('1'); // default to "0 days"
    const [toTimeUnit, setToTimeUnit] = useState('days'); // default to "days"
    // eslint-disable-next-line no-unused-vars
    const [fixedDateRange, setFixedDateRange] = useState({ start: '', end: '' });
    const [errors, setErrors] = useState({ toDataRange: '', fromDataRange: '' });
    // Handlers for navigation between screens
    const handleProductionSelect = () => setCurrentScreen('production');
    const handleBaselineSelect = () => setCurrentScreen('training');

    const handleBackClick = () => {
        setCurrentScreen('initial');
        // Reset states to initial values
        setRangeOption('moving');
        setProductionToDataRange('0');
        setProductionFromDataRange('1');
        setFromTimeUnit('week');
        setToTimeUnit('days');
        setErrors({ toDataRange: '', fromDataRange: '' });
        setInputValue(baselineSource);
    };

    // Calculate fixed date range (current date to one month ago)
    useEffect(() => {
        const currentDate = new Date();
        const oneMonthAgoDate = new Date();
        oneMonthAgoDate.setMonth(currentDate.getMonth() - 1);

        setFixedDateRange({
            start: format(oneMonthAgoDate, 'MM/dd/yyyy'),
            end: format(currentDate, 'MM/dd/yyyy'),
        });
    }, []);

    // Reset errors on screen change
    useEffect(() => {
        setErrors({ toDataRange: '', fromDataRange: '' }); // Clear errors on screen change
    }, [currentScreen]);

    useEffect(() => {
        if (open) {
            setCurrentScreen('initial');
            setRangeOption('moving');
            setFromTimeUnit('week');
            setProductionToDataRange('0');
            setProductionFromDataRange('1');
            setToTimeUnit('days');
            setInputValue(baselineSource);
        }
    }, [open, baselineSource]);

    useEffect(() => {
        setInputValue(baselineSource);
    }, [baselineSource]);
    const convertToSeconds = (value, unit) => {
        const numValue = parseFloat(value);
        if (Number.isNaN(numValue)) return 0;

        switch (unit.toLowerCase()) {
            case 'hour':
            case 'hours':
                return numValue * 3600;
            case 'day':
            case 'days':
                return numValue * 86400;
            case 'week':
            case 'weeks':
                return numValue * 604800;
            case 'month':
            case 'months':
                return numValue * 2592000;
            case 'year':
            case 'years':
                return numValue * 31536000;
            default:
                return 0;
        }
    };

    const handleButtonClick = () => {
        if (currentScreen === 'training') {
            // Trigger API call for baseline
            if (onTraningBaseline) {
                onTraningBaseline();
                // onClose();
            }
        } else if (currentScreen === 'production') {
            if (rangeOption === 'moving') {
                const projectName = localStorage.getItem('project_name');
                const movingWindowSeconds = convertToSeconds(productionFromDataRange, fromTimeUnit);
                const movingWindowDelaySeconds = convertToSeconds(productionToDataRange, toTimeUnit);
                const payload = {
                    project_name: projectName,
                    model_name: data.name,
                    model_version: data.version,
                    moving_window_seconds: movingWindowSeconds,
                    moving_window_delay_seconds: movingWindowDelaySeconds,
                };
                onProductionDateRangeClick(payload);
            }
            // onClose();
        }
    };

    const getHeadingText = () => {
        if (currentScreen === 'production') return 'Production Data Source';
        if (currentScreen === 'training') return 'Training Data Source';
        return 'Set up a Baseline';
    };

    // Dynamic button label based on screen
    const getButtonText = () => {
        if (currentScreen === 'training') return 'Submit';
        if (currentScreen === 'production') return 'Submit';
        return '';
    };
    const handleRangeOptionChange = (event) => setRangeOption(event.target.value);

    const handleProductionToDataRange = (e) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) {
            return;
        }
        setProductionToDataRange(value);

        // Perform validation for the "to" data range
        if (value === '') {
            setErrors((prev) => ({ ...prev, toDataRange: 'Integer only' }));
        } else {
            // Calculate total duration in seconds
            const totalDuration = convertToSeconds(value, toTimeUnit);
            if (totalDuration > 31536000) {
                // 1 year in seconds
                setErrors((prev) => ({ ...prev, toDataRange: 'The range cannot exceed 1 year.' }));
            } else {
                setErrors((prev) => ({ ...prev, toDataRange: '' }));
            }
        }
    };
    const handleProductionFromDataRange = (e) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) {
            return;
        }
        setProductionFromDataRange(value);

        // Perform validation for the "from" data range
        if (value === '') {
            setErrors((prev) => ({ ...prev, fromDataRange: 'Integer only' }));
        } else if (parseInt(value, 10) === 0) {
            setErrors((prev) => ({ ...prev, fromDataRange: '1 hour minimum' }));
        } else {
            // Calculate total duration in seconds
            const totalDuration = convertToSeconds(value, fromTimeUnit);
            if (totalDuration > 31536000) {
                // 1 year in seconds
                setErrors((prev) => ({ ...prev, fromDataRange: 'The range cannot exceed 1 year.' }));
            } else {
                setErrors((prev) => ({ ...prev, fromDataRange: '' }));
            }
        }
    };
    const handleTimeUnitChange = (event) => {
        const newTimeUnit = event.target.value;
        const movingWindowSeconds = convertToSeconds(productionFromDataRange, newTimeUnit);

        // Check if the new time unit for "from" exceeds 1 year
        if (movingWindowSeconds > 31536000) {
            setErrors((prev) => ({ ...prev, fromDataRange: 'The range cannot exceed 1 year.' }));
        } else {
            setErrors((prev) => ({ ...prev, fromDataRange: '' }));
        }

        setFromTimeUnit(newTimeUnit);
    };

    const handleFromTimeUnitChange = (e) => {
        const newToTimeUnit = e.target.value;
        const movingWindowDelaySeconds = convertToSeconds(productionToDataRange, newToTimeUnit);

        // Check if the new time unit for "to" exceeds 1 year
        if (movingWindowDelaySeconds > 31536000) {
            setErrors((prev) => ({ ...prev, toDataRange: 'The range cannot exceed 1 year.' }));
        } else {
            setErrors((prev) => ({ ...prev, toDataRange: '' }));
        }

        setToTimeUnit(newToTimeUnit);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box
                sx={{
                    width: '778px',
                    height: '100vh',
                    background: '#F4F4F4',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                }}
            >
                {/* Header with Close Icon and Heading in White Background */}
                <Box sx={{ background: '#FFFFFF', padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <IconButton onClick={onClose} sx={{ color: '#000000' }}>
                        <CloseIcon sx={{ color: '#000000', fontSize: '36px' }} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#021D49',
                            fontSize: '21px',
                            fontWeight: 'medium',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            textAlign: 'center',
                        }}
                    >
                        {getHeadingText()}
                    </Typography>
                    <Box sx={{ width: '36px' }} />
                </Box>

                {/* Initial Screen */}
                {currentScreen === 'initial' && (
                    <>
                        <Box
                            sx={{
                                background: '#FFFFFF',
                                padding: '16px',
                                marginTop: '16px',
                                boxShadow: '0px 0px 2px #00000029',
                                borderRadius: '4px',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Production Data Source
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Select part of production data as baseline dataset.
                            </Typography>
                            <Button variant="contained" color="primary" sx={{ width: '150px' }} onClick={handleProductionSelect}>
                                Select
                            </Button>
                        </Box>

                        <Box
                            sx={{
                                background: '#FFFFFF',
                                padding: '16px',
                                marginTop: '16px',
                                boxShadow: '0px 0px 2px #00000029',
                                borderRadius: '4px',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Training Data Source
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Select user defined dataset (example: model&#39;s training dataset) as baseline dataset.
                            </Typography>
                            <Button variant="contained" color="primary" sx={{ width: '150px' }} onClick={handleBaselineSelect}>
                                Select
                            </Button>
                        </Box>
                    </>
                )}

                {/* Production Data Screen */}
                {currentScreen === 'production' && (
                    <Box
                        sx={{
                            background: '#FFFFFF',
                            padding: '16px',
                            marginTop: '16px',
                            boxShadow: '0px 0px 2px #00000029',
                            borderRadius: '4px',
                            width: '100%',
                            height: '300px',
                        }}
                    >
                        <Button onClick={handleBackClick} variant="text">
                            ← Back
                        </Button>
                        <Typography variant="h6" gutterBottom>
                            Production Data Source
                        </Typography>

                        {/* Radio Group for Time Range Selection (Vertical Orientation) */}
                        <FormControl component="fieldset">
                            <RadioGroup value={rangeOption} onChange={handleRangeOptionChange}>
                                {/* <FormControlLabel value="fixed" control={<Radio />} label="Use a fixed time range" />
                                {rangeOption === 'fixed' && (
                                    <Box mt={2} sx={{ backgroundColor: '#f0f0f0', padding: '16px', borderRadius: '4px' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#021D49' }}>
                                            Data between {fixedDateRange.end} - {fixedDateRange.start}
                                        </Typography>
                                    </Box>
                                )} */}
                                <FormControlLabel value="moving" control={<Radio />} label="Use a moving time range" />
                            </RadioGroup>
                        </FormControl>

                        {/* Moving Time Range Input */}
                        {rangeOption === 'moving' && (
                            <Box mt={2} display="flex" alignItems="center">
                                {fromTimeUnit === 'hour' ? (
                                    <Select
                                        value={productionFromDataRange}
                                        onChange={handleProductionFromDataRange}
                                        error={!!errors.fromDataRange}
                                        helperText={errors.fromDataRange}
                                        FormHelperTextProps={{
                                            sx: {
                                                color: 'red',
                                                fontSize: '12px',
                                                mt: 0.5,
                                                position: 'absolute',
                                                top: '60px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            },
                                        }}
                                        sx={{ width: '70px', marginRight: '16px' }}
                                        variant="outlined"
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={12}>12</MenuItem>
                                        <MenuItem value={24}>24</MenuItem>
                                    </Select>
                                ) : (
                                    <TextField
                                        value={productionFromDataRange}
                                        onChange={handleProductionFromDataRange}
                                        error={!!errors.fromDataRange}
                                        helperText={errors.fromDataRange}
                                        FormHelperTextProps={{
                                            sx: {
                                                color: 'red',
                                                fontSize: '12px',
                                                mt: 0.5,
                                                position: 'absolute',
                                                top: '60px',
                                                left: '-10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            },
                                        }}
                                        sx={{ width: '70px', marginRight: '16px' }}
                                        variant="outlined"
                                    />
                                )}

                                <Select value={fromTimeUnit} onChange={handleTimeUnitChange} displayEmpty sx={{ width: '150px', marginRight: '16px' }}>
                                    <MenuItem value="hour">Hour</MenuItem>
                                    <MenuItem value="day">Day</MenuItem>
                                    <MenuItem value="week">Week</MenuItem>
                                    <MenuItem value="month">Month</MenuItem>
                                    <MenuItem value="year">Year</MenuItem>
                                </Select>
                                <Typography variant="body1" sx={{ marginRight: '16px' }}>
                                    of data from
                                </Typography>
                                <TextField
                                    value={productionToDataRange}
                                    onChange={handleProductionToDataRange}
                                    error={!!errors.toDataRange}
                                    helperText={errors.toDataRange}
                                    FormHelperTextProps={{
                                        sx: {
                                            color: 'red',
                                            fontSize: '12px',
                                            mt: 0.5,
                                            position: 'absolute',
                                            top: '60px',
                                            left: '-10px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        },
                                    }}
                                    sx={{ width: '70px', marginRight: '16px' }}
                                    variant="outlined"
                                />
                                <Select value={toTimeUnit} onChange={handleFromTimeUnitChange} displayEmpty sx={{ width: '150px' }}>
                                    <MenuItem value="hours">Hours</MenuItem>
                                    <MenuItem value="days">Days</MenuItem>
                                    <MenuItem value="weeks">Weeks</MenuItem>
                                    <MenuItem value="months">Months</MenuItem>
                                    <MenuItem value="years">Years</MenuItem>
                                </Select>
                                <Typography variant="body1" sx={{ marginLeft: '6px' }}>
                                    ago
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

                {/* Baseline Data Source Screen */}
                {currentScreen === 'training' && (
                    <Box
                        sx={{
                            background: '#FFFFFF',
                            padding: '16px',
                            marginTop: '16px',
                            boxShadow: '0px 0px 2px #00000029',
                            borderRadius: '4px',
                            width: '100%',
                        }}
                    >
                        <Button onClick={handleBackClick} variant="text">
                            ← Back
                        </Button>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '16px',
                            }}
                        >
                            <Typography variant="body1" sx={{ fontWeight: 'bold', width: '500px' }}>
                                Training Data Source:
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#021D49' }}>
                                {inputValue}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Dynamic Button (Baseline or Production Baseline) */}
                {(currentScreen === 'training' || currentScreen === 'production') && (
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Button variant="contained" color="primary" fullWidth onClick={handleButtonClick}>
                            {getButtonText()}
                        </Button>
                    </Box>
                )}
            </Box>
        </Drawer>
    );
};

SidePanelBaselineProductionDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.any.isRequired,
    onTraningBaseline: PropTypes.func.isRequired,
    onProductionDateRangeClick: PropTypes.func.isRequired,
};

export default SidePanelBaselineProductionDrawer;
