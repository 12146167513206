import React from 'react';
import { Stack, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isServiceAccountValid, selectedMembersList } from 'pages/myidm/store';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import ProvisioningStyles from 'pages/myidm/style';
import useDebounce from 'utils/use-debounce';
import PropTypes from 'prop-types';

const ServiceAccount = ({ setLoader, setError }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [members] = useRecoilState(selectedMembersList);
    const [, setIsServiceAccountValid] = useRecoilState(isServiceAccountValid);
    const [showHelperText, setHelperText] = React.useState(false);
    const [showHelperMsg, setHelperMsg] = React.useState('');
    const isPresent = (serviceAccount) => members?.some((element) => element?.gpid === serviceAccount);

    const debouncedInputValue = useDebounce(order?.serviceAccount, 500);
    const serviceAccApi = useMutation((payload) => axios.post(`${endPoints?.ssp?.kafka?.serviceAccountYesValidation}`, payload), {
        onMutate: () => {
            setLoader(true);
        },
        onSettled: () => {
            setLoader(false);
        },
    });

    const handleValueChange = (value) => {
        if (value !== order?.serviceAccount) {
            setOrder({
                ...order,
                serviceAccount: value,
            });
        }
    };
    React.useEffect(() => {
        if (serviceAccApi?.data?.data?.serviceAccountExist === false) {
            setHelperText(true);
            setIsServiceAccountValid(true);
            setHelperMsg('Given Service Account does not exist');
        }
        if (serviceAccApi?.data?.data?.serviceAccountExist === true) {
            setHelperText(false);
            setIsServiceAccountValid(false);

            setHelperMsg('');
        }
    }, [serviceAccApi?.data?.data]);
    React.useEffect(() => {
        if (debouncedInputValue && order?.environment && !showHelperText && debouncedInputValue?.length >= 3) {
            const payload = {
                accountType: 'Kafka',
                serviceAccountId: debouncedInputValue,
                environment: order?.environment,
            };
            serviceAccApi.mutate(payload);
        }
    }, [debouncedInputValue, order?.environment, showHelperText]);

    React.useEffect(() => {
        if (
            order?.serviceAccount &&
            order?.serviceAccount !== null &&
            order?.serviceAccount !== '' &&
            order?.serviceAccount !== undefined &&
            !/^(?![_])(?!.*__)[a-zA-Z0-9_]{3,209}(?<!_)$/.test(order?.serviceAccount)
        ) {
            setIsServiceAccountValid(true);
            setHelperText(true);
            setHelperMsg('Service Account should be min. 3 chars, starts with alphanumeric and contains only underscore(_) as special char.');
        } else if (members?.length && order?.serviceAccount && isPresent(order?.serviceAccount)) {
            setIsServiceAccountValid(true);
            setHelperText(true);
            setHelperMsg('Service Account already present in the LDAP Group and please choose different service account if you wish to continue further.');
        } else {
            setIsServiceAccountValid(false);
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.serviceAccount]);
    React.useEffect(() => {
        setError(showHelperText);
    }, [showHelperText?.toString()]);
    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.serviceAccount) || showHelperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.serviceAccount && order?.isTouched) setIsTouched(true);
    }, [order?.serviceAccount, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack} width="100%">
            <CssTextField
                data-testid="service-acc-text"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Service Account"
                autoComplete="off"
                error={showHelperText}
                helperText={showHelperText && <span style={ProvisioningStyles.disposal.helpText}>{showHelperMsg}</span>}
                value={order?.serviceAccount || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
                InputProps={{
                    endAdornment: <>{serviceAccApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                }}
            />
        </Stack>
    );
};
ServiceAccount.propTypes = {
    setLoader: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
};
export default ServiceAccount;
