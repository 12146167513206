export const getExternalPublicLoadBalancerSummary = (item) => {
    return {
        'Application Name': item?.catalogConfig?.applicationName,
        'Application Owner ID': item?.catalogConfig?.applicationOwnerId,
        'Application Service': item.catalogConfig?.applicationService,
        'Budget Code': item?.catalogConfig?.budgetCode,
        'Load Balancer URL': item?.catalogConfig?.loadBalancerUrl,
        'Load Balancer Frontend Port': item?.catalogConfig?.loadBalancerFrontEndPort,
        'SSL Encryption': item?.catalogConfig?.sslEncryption === true ? 'Yes' : 'No',
        ...(item?.catalogConfig?.sslEncryption === true && {
            SupportBackendCertificate: item?.catalogConfig?.supportBackendCertificate,
        }),
        ...(item?.catalogConfig?.isaNumber && {
            'ISA Number': item?.catalogConfig?.isaNumber,
        }),
        ...(item?.catalogConfig?.scanNumber && {
            'SCAN Number': item?.catalogConfig?.scanNumber,
        }),
        ...(item?.catalogConfig?.certificateOwnerOne && {
            'Certificate Owner 1 ID': item?.catalogConfig?.certificateOwnerOne,
        }),
        ...(item?.catalogConfig?.certificateOwnerTwo && {
            'Certificate Owner 2 ID': item?.catalogConfig?.certificateOwnerTwo,
        }),
        'AD Group': item?.catalogConfig?.adGroup,
        'Application Support Mail ID': item?.catalogConfig?.appSupportMailId,
        ...(item?.catalogConfig?.dataClassification && {
            'Data Classification': item?.catalogConfig?.dataClassification,
        }),
    };
};
export default {};
