/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import toast from 'react-hot-toast';
import { formattedDate } from 'utils';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, PieChart, Tooltip, Pie, Legend, ResponsiveContainer } from 'recharts';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Collapse,
    IconButton,
    CircularProgress,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    Radio,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useHistory } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { StyledTableHeader, StyledTableCell, ProgressIcon, Item, style } from './style';
import stickyNotes from '../../../assets/images/dlopsImages/sticky-note-solid.svg';
import AccessToken from '../components/accessToken/accessToken';
import 'assets/style/app.css';

function CompareModel() {
    const [chartsTitle, setChartsTitle] = useState([]);
    const { getAccessToken, getOrRefreshAccessToken, handleTokenError } = AccessToken();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [uniqueTitles, setUniqueTitles] = useState([]);
    const [metricInfo, setMetricInfo] = useState([]);
    const [tableData, setTableData] = useState([]);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [donutPlotData, setDonutPlotData] = useState([]);
    const [mlplotData, setmlPlotData] = useState([]);
    const [selectedChartType, setSelectedChartType] = useState('bar');
    const [tableDataMetrics, setTableDataMetrics] = useState([]);
    const [modelVersionStorage, setModelVersionStorage] = useState('');
    const [filteredHeaders, setFilteredHeaders] = useState([]);

    useEffect(() => {
        const modelDetails = JSON.parse(localStorage.getItem('modelnameByVersion'));
        const sortByLatestVersion = modelDetails.sort((a, b) => {
            const versionA = parseInt(a.version, 10);
            const versionB = parseInt(b.version, 10);

            return versionB - versionA;
        });
        setTableData(sortByLatestVersion);
    }, []);

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [openRows, setOpenRows] = useState([]);

    const handleRowToggle = (index) => {
        setOpenRows((prevOpenRows) => {
            const newOpenRows = [...prevOpenRows];
            newOpenRows[index] = !newOpenRows[index];
            return newOpenRows;
        });
    };

    const handleChartTypeChange = (event) => {
        setSelectedChartType(event.target.value);
    };
    const [selectedVersionCount, setSelectedVersionCount] = useState();
    const [modelAddressList, setModelAddressList] = useState([]);
    const handleChange = (item, e) => {
        if (e != null && e.target.checked === true) {
            const pushedData = [];
            const arrayData = item;
            pushedData.push(arrayData);
            setSelectedVersionCount([...modelAddressList, arrayData].length);
            setModelAddressList([...modelAddressList, arrayData]);
        } else if (e.target.checked === false) {
            let pushedData = [];
            const arrayData = item;
            pushedData = modelAddressList.filter((items) => items.version !== arrayData.version);
            setModelAddressList(pushedData);
            setSelectedVersionCount(pushedData.length);
        }
    };

    const ChartTypes = {
        BAR: 'bar',
        DONUT: 'donut',
    };

    const columnsmui = [
        { name: '' },
        {
            name: 'Model name',
            renderCell: ({ row }) => row.projectName || 'undefined',
        },
        {
            name: 'Version',
            renderCell: ({ row }) => row.projectName || 'undefined',
        },
        { name: 'Stage', renderCell: ({ row }) => row.projectName || 'undefined' },
        { name: 'Last modified', renderCell: ({ row }) => row.projectName || 'undefined' },
        {
            name: 'Compare',
            renderCell: ({ row }) => row.projectName || 'undefined',
        },
        // { name: 'Status' },
    ];

    const columnsmuibelow = [
        { name: '' },
        {
            name: 'Model name',
            renderCell: ({ row }) => row.projectName || 'undefined',
        },
        {
            name: 'Version',
            renderCell: ({ row }) => row.projectName || 'undefined',
        },
        { name: 'Stage', renderCell: ({ row }) => row.stage || 'undefined' },
    ];

    const metricsColumns = filteredHeaders[0]?.metric
        ? filteredHeaders[0]?.metric.map((title) => ({
              name: title.key.replace(/^./, title.key[0].toUpperCase()),
              renderCell: ({ row }) => {
                  const metric = row.metrics.find((metrics) => metrics.title === title.key);
                  return metric ? metric.value : 'undefined';
              },
              center: true,
          }))
        : [];

    if (localStorage.getItem('modelVersionsCheck') === 'false') {
        columnsmuibelow.push(
            ...chartsTitle.map((title) => ({
                name: title.replace(/^./, title[0].toUpperCase()),
            }))
        );
    } else {
        columnsmuibelow.push(...metricsColumns);
    }

    const coloursSelection = (version) => {
        const colors = ['rgb(0, 143, 251)', 'rgb(0, 227, 150)', 'rgb(254, 176, 25)', '#00984A', '#56B4E9'];

        return colors[version % colors.length];
    };

    const fetchRunId = async (accessToken, item) => {
        // const getRunId = localStorage.getItem('run_Id');
        const getRunId = item.run_id;
        try {
            const responseData = await axios.get(endPoints.registeredModelRunId(getRunId), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return responseData?.data;
        } catch (error) {
            return handleTokenError(error, endPoints.registeredModelRunId(getRunId), 'get', null);
        }
    };

    // eslint-disable-next-line consistent-return
    const getMLMetrics = async () => {
        const pushedData = [];
        let metricsData = [];
        let latestVersionsArray = [];
        const sampleArray = [];
        setIsLoading(true);
        try {
            const accessToken = await getAccessToken();
            const metricsReturnData = await Promise.all(
                modelAddressList.map(async (item) => {
                    try {
                        const response = await fetchRunId(accessToken, item);

                        const metrics = response?.run?.data?.metrics;
                        const object = {
                            name: item.name,
                            version: item.version,
                            stage: item.current_stage,
                            metrics,
                        };
                        sampleArray.push(object);
                        metricsData = metrics.map((obj) => {
                            return { ...obj, name: `${item.name}.${item.version}` };
                        });
                        pushedData.push(metricsData);
                        latestVersionsArray = pushedData.reduce((a, b) => a.concat(b), []);
                        const groupedDonutData = latestVersionsArray.reduce((acc, item1) => {
                            const existingItem = acc.find((group) => group.key === item1.key);
                            if (existingItem) {
                                existingItem.series.push({ name: item1.name, data: [item1.value] });
                            } else {
                                acc.push({ key: item1.key, series: [{ name: item1.name, data: [item1.value] }] });
                            }
                            return acc;
                        }, []);
                        const groupedCompareData = latestVersionsArray.reduce((acc, item2) => {
                            const existingItem = acc.find((group) => group.key === item2.key);
                            if (existingItem) {
                                existingItem.series.push({ name: item2.name, data: [item2.value] });
                            } else {
                                acc.push({ key: item2.key, series: [{ name: item2.name, data: [item2.value] }] });
                            }
                            return acc;
                        }, []);
                        const barChartInfo = groupedCompareData.map((item3) => {
                            const pageData = [
                                {
                                    name: item3.key,
                                    ...item3.series.reduce((acc, entry) => {
                                        acc[entry.name] = entry.data[0] || 0;
                                        return acc;
                                    }, {}),
                                },
                            ];
                            return pageData;
                        });
                        const keys = barChartInfo.flatMap((items) => Object.keys(items[0]).filter((key) => key !== 'name'));

                        const uniqueValues = [...new Set(keys)];
                        setUniqueTitles(uniqueValues);
                        const barTitles = groupedCompareData.map((items) => items.key);
                        setChartsTitle(barTitles);
                        const transformedData = groupedDonutData.map((items) =>
                            items.series.map((entry, index) => ({
                                label: items.key,
                                name: entry.name,
                                value: entry.data[0] || 0,
                                fill: coloursSelection(index),
                            }))
                        );
                        setmlPlotData(barChartInfo);
                        setDonutPlotData(transformedData);
                        setTableDataMetrics(sampleArray);
                        setIsLoading(false);
                        return latestVersionsArray;
                    } catch (error) {
                        // You can handle the error for this specific item here, if needed.
                        return error;
                    }
                })
            );
        } catch (error) {
            return error;
        }
    };
    const getMLOnboardedModel = () => {
        modelAddressList.forEach((obj) => {
            const { tags } = obj;
            const modelMetricsTag = tags.find((tag) => tag.key === 'model_metrics');
            if (modelMetricsTag) {
                const jsonString = modelMetricsTag.value.replace(/'/g, '"');
                let arrayr;
                try {
                    arrayr = JSON.parse(jsonString);
                } catch (error) {
                    arrayr = [jsonString];
                }

                // eslint-disable-next-line no-param-reassign
                obj.metric = arrayr.map((item) => {
                    const valueTag = tags.find((tag) => tag.key === item);
                    let value;

                    if (valueTag) {
                        if (typeof valueTag.value === 'string' && valueTag.value.startsWith('[') && valueTag.value.endsWith(']')) {
                            // Parse the string representation of the array
                            const [firstValue] = JSON.parse(valueTag.value);
                            value = firstValue;
                        } else {
                            value = valueTag.value;
                        }
                    } else {
                        value = 'NA';
                    }

                    return {
                        key: item,
                        value,
                    };
                });
            } else {
                // eslint-disable-next-line no-param-reassign
                obj.metric = [{ key: 'NA', value: 'NA' }]; // Set default metric
            }
        });

        setMetricInfo(modelAddressList);

        const plots = [];

        modelAddressList.forEach((obj, index) => {
            obj?.metric.forEach((metric) => {
                let plotIndex = plots.findIndex((plot) => plot.categories[0] === metric.key);

                if (plotIndex === -1) {
                    plotIndex = plots.length;
                    plots.push({
                        series: [],
                        categories: [metric.key],
                    });
                }

                plots[plotIndex].series.push({
                    name: `${obj.name}-${obj.version}`,
                    data: [parseFloat(metric.value)],
                });
            });
        });

        const metricLengths = modelAddressList.map((obj1) => obj1?.metric?.length);
        const maxLength = Math.max(...metricLengths);
        const filteredObjects = modelAddressList.filter((obj) => obj?.metric?.length === maxLength);
        setFilteredHeaders(filteredObjects);

        const barInfo = [];
        const pieInfo = [];

        // Iterate over each metric
        modelAddressList.forEach((obj) => {
            obj?.metric.forEach((metric) => {
                const metricName = metric.key;
                const seriesName = `${obj.name}-${obj.version}`;
                const value = parseFloat(metric.value);
                const color = coloursSelection(obj.version);

                // Bar chart info
                const barIndex = barInfo.findIndex((item) => item.name === metricName);
                if (barIndex === -1) {
                    barInfo.push({
                        name: metricName,
                        [seriesName]: value,
                    });
                } else {
                    barInfo[barIndex][seriesName] = value;
                }

                // Pie chart info
                const pieIndex = pieInfo.findIndex((item) => item[0].label === metricName);
                if (pieIndex === -1) {
                    pieInfo.push([
                        {
                            label: metricName,
                            name: `${obj.name}.${obj.version}`,
                            value,
                            fill: color,
                        },
                    ]);
                } else {
                    pieInfo[pieIndex].push({
                        label: metricName,
                        name: `${obj.name}.${obj.version}`,
                        value,
                        fill: color,
                    });
                }
            });
        });

        const formattedBarInfo = barInfo.map((obj) => [obj]);
        const keys = formattedBarInfo.flatMap((item) => Object.keys(item[0]).filter((key) => key !== 'name'));
        const uniqueValues = [...new Set(keys)];
        setUniqueTitles(uniqueValues);
        setDonutPlotData(pieInfo);
        setmlPlotData(formattedBarInfo);
        setTableDataMetrics(modelAddressList);
        setSelectedVersionCount(modelAddressList.length);
    };

    const compareModels = async () => {
        const modelVersionStora = localStorage.getItem('modelVersionsCheck');
        setModelVersionStorage(modelVersionStora);
        if (modelVersionStora === 'false') {
            if (modelAddressList.length >= 2) {
                await getMLMetrics();
            } else {
                toast.error('Please select atleast two versions to compare', { autoClose: 2000 });
            }
        } else if (modelAddressList.length >= 2) {
            getMLOnboardedModel();
        } else {
            toast.error('Please select atleast two versions to compare', { autoClose: 2000 });
        }
    };

    const colourSelection = (version) => {
        switch (version) {
            case 0:
                return 'rgb(0, 143, 251)';
            case 1:
                return 'rgb(0, 227, 150)';
            case 2:
                return 'rgb(254, 176, 25)';
            case 3:
                return '#00984A';
            case 4:
                return '#56B4E9';
            default:
                return null;
        }
    };
    const index = 0;
    const getFillColor = (ind) => {
        const colors = ['#8884d8', '#82ca9d', '#82ca9d'];
        return colors[ind] || colors[colors.length - 1]; // Fallback to the last color if there are more items than colors
    };

    const handleNavigation = () => {
        history.push('/dlops-model-observability');
    };
    const accuracyValue = (row) => {
        chartsTitle.map((metricsTitle) => {
            return (
                <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                    {row.metrics.find((metric) => metric.key === metricsTitle).value}
                </StyledTableCell>
            );
        });
    };

    const customLegend = (item, uniqueLabel) => (
        <div style={style.legendView}>
            <div style={style.labelStyle}>{uniqueLabel}</div>
            {item.map((entry, i) => (
                <div key={`legend-item-${i}`} style={style.legendContainer}>
                    <div style={{ width: '10px', height: '10px', backgroundColor: entry.fill, marginRight: '5px' }} />
                    <div style={{ color: '#000000', fontSize: '13px' }}>{`${entry.name}: ${entry.value}`}</div>
                </div>
            ))}
        </div>
    );

    const ChartWithBarCard = (items) => {
        return (
            <Card className="mt-4" style={{ marginRight: '20px' }}>
                <CardContent>
                    <div className="dlops-custom-bar-chart" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <ResponsiveContainer width={330} height={350}>
                            <BarChart
                                width={200}
                                height={300}
                                // eslint-disable-next-line react/destructuring-assignment
                                data={items.item}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend style={{ fontSize: '13px', color: 'black' }} />
                                {uniqueTitles.map((item, index1) => (
                                    <Bar dataKey={item} fill={coloursSelection(index1)} />
                                ))}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const ChartWithCard = (items) => {
        // eslint-disable-next-line react/destructuring-assignment
        const uniqueLabel = items.item.length > 0 ? items.item[0].label : '';
        return (
            <Card className="mt-4" style={{ marginRight: '20px' }}>
                <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <PieChart width={330} height={350}>
                            <Pie
                                dataKey="value"
                                // eslint-disable-next-line react/destructuring-assignment
                                data={items.item}
                                cx={150}
                                cy={100}
                                innerRadius={40}
                                outerRadius={80}
                            />
                            <Tooltip />
                            <Legend verticalAlign="bottom" height={130} content={() => customLegend(items.item, uniqueLabel, index)} />
                        </PieChart>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const chartMetricsValue = (row) => {
        return chartsTitle.map((title) => {
            const metric = row.metrics.find((metrics) => metrics.key === title);
            return <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>{metric?.value || 'NA'}</StyledTableCell>;
        });
    };
    const getFirstValue = (value) => {
        if (typeof value === 'string') {
            try {
                const parsedValue = JSON.parse(value);
                if (Array.isArray(parsedValue)) {
                    return parsedValue.length > 0 ? parsedValue[0] : 'NA';
                }
            } catch (error) {
                if (value.includes(',')) {
                    const splitValues = value.split(',').map((val) => val.trim());
                    return splitValues.length > 0 ? splitValues[0] : 'NA';
                }
            }
        }
        if (Array.isArray(value)) {
            return value.length > 0 ? value[0] : 'NA';
        }
        return value !== null && value !== undefined && value !== '' ? value : 'NA';
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>DLOPS Dashboard</title>
            </Helmet>
            <Box style={{ marginBottom: '20px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <Item>
                                <div
                                    style={{
                                        marginLeft: '28px',
                                        marginTop: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    <span>DLOPS</span>
                                    <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                        /Model observability
                                    </span>
                                    <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Model comparision</span>
                                </div>
                            </Item>
                            <Button
                                style={{
                                    background: '#309BF8 0% 0% no-repeat padding-box',
                                    borderRadius: '15px',
                                    color: '#ffffff',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    marginTop: '-40px',
                                    textTransform: 'none',
                                    float: 'right',
                                    marginRight: '100px',
                                }}
                                onClick={() => compareModels()}
                            >
                                Compare model version
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <div style={style.dataTable}>
                <div className="d-flex justify-content-start col-12 mt-4">
                    <div className="datatables col-11">
                        <div style={style.allProjectDiv}>
                            <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                            <h5 style={style.projectText}>Model registry</h5>
                        </div>
                        {isLoading && (
                            <Box sx={ProgressIcon.provisioning_wrapper}>
                                <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                                <Typography variant="body2" sx={ProgressIcon.circularProgressColor}>
                                    Loading
                                </Typography>
                            </Box>
                        )}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columnsmui.map((column, index2) => (
                                            <StyledTableHeader key={index2} maxWidth={column.maxWidth}>
                                                {column.name}
                                            </StyledTableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData?.map((row, i) => (
                                        <>
                                            <React.Fragment key={i}>
                                                <TableRow key={i}>
                                                    <StyledTableCell>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => handleRowToggle(i)}>
                                                            {openRows[i] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </StyledTableCell>

                                                    <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>{row?.name}</StyledTableCell>

                                                    <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>{row?.version}</StyledTableCell>
                                                    <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>{row?.current_stage}</StyledTableCell>

                                                    <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                        {formattedDate(row?.last_updated_timestamp || row?.creation_timestamp)}
                                                    </StyledTableCell>

                                                    <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                        <>
                                                            <input type="checkbox" value={row.model_address} onChange={(e) => handleChange(row, e)} />
                                                        </>
                                                    </StyledTableCell>
                                                    {/* {localStorage.getItem('modelVersionsCheck') !== 'false' ? (
                                                        <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>{row?.status}</StyledTableCell>
                                                    ) : (
                                                        ''
                                                    )} */}
                                                </TableRow>

                                                <TableRow>
                                                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                                        <Collapse in={openRows[i]} timeout="auto" unmountOnExit>
                                                            <Box margin={1} style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                                                                <div>
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <div>
                                                                            {' '}
                                                                            <span style={{ fontSize: '14px', fontWeight: 600 }}>Description : </span>
                                                                            {row?.model_desc || 'undefined'}
                                                                        </div>
                                                                        {/* <div>
                                                                            <span style={{ fontSize: '14px', fontWeight: 600, marginLeft: '200px' }}>
                                                                                Description Version :{' '}
                                                                            </span>
                                                                            {row?.tags.find((tag) => tag.key === 'desc_version')?.value || 'undefined'}
                                                                        </div> */}
                                                                    </div>
                                                                    <hr />
                                                                    <div>
                                                                        <span style={{ fontSize: '14px', fontWeight: 600 }}> Training Dataset Url : </span>
                                                                        <span style={{ color: '#1378D4' }}>
                                                                            {row?.tags.find((tag) => tag.key === 'baseline_datasource')?.value || 'undefined'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Box>
                                                        </Collapse>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                            {tableData && tableData.length > 0 ? (
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={tableData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            ) : (
                                ''
                            )}
                        </TableContainer>
                    </div>
                </div>
                <div className="bargraphs">
                    {donutPlotData.length || mlplotData.length ? (
                        <>
                            <h3 style={style.chartText}>View the Chart with different types</h3>
                            <div style={{ display: 'flex', margin: '10px', flexDirection: 'row' }}>
                                <div style={{ display: 'flex !important' }}>
                                    <Radio
                                        style={{ marginRight: '10px', marginBottom: '2px', cursor: 'pointer' }}
                                        value="bar"
                                        checked={selectedChartType === 'bar'}
                                        onChange={handleChartTypeChange}
                                    />
                                    Bar Chart
                                </div>
                                <div style={{ display: 'flex !important' }}>
                                    <Radio
                                        style={{ marginRight: '10px', marginLeft: '10px', cursor: 'pointer' }}
                                        value="donut"
                                        checked={selectedChartType === 'donut'}
                                        onChange={handleChartTypeChange}
                                    />
                                    Donut Chart
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div
                            className="d-flex flex-wrap"
                            style={{ height: '400px', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', flex: '1' }}
                        >
                            {selectedChartType === ChartTypes.DONUT ? (
                                <div style={style.graphContainer}>
                                    <Grid container spacing={3}>
                                        {donutPlotData.map((item, index3) => (
                                            <Grid item key={index3} xs={12} sm={6} md={4} lg={3}>
                                                <ChartWithCard item={item} index={index3} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            ) : (
                                <div style={style.graphContainer}>
                                    <Grid container spacing={3}>
                                        {mlplotData.map((item, index4) => (
                                            <Grid item key={index4} xs={12} sm={6} md={4} lg={3}>
                                                <ChartWithBarCard key={index4} item={item} index={index4} />
                                            </Grid>
                                        ))}{' '}
                                    </Grid>
                                </div>
                            )}
                        </div>
                        {selectedVersionCount === tableDataMetrics.length ? (
                            <div style={{ flex: '0 0 auto', marginTop: '40px' }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {columnsmuibelow.map((column, index5) => (
                                                    <StyledTableHeader key={index5} maxWidth={column.maxWidth}>
                                                        {column.name}
                                                    </StyledTableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableDataMetrics?.map((row, index1) => {
                                                return (
                                                    <>
                                                        <React.Fragment key={index1}>
                                                            <TableRow key={index1}>
                                                                <StyledTableCell>
                                                                    <div
                                                                        style={{
                                                                            background: colourSelection(index1),
                                                                            padding: '10px',
                                                                            borderRadius: '2px',
                                                                            width: '10px',
                                                                        }}
                                                                    />
                                                                </StyledTableCell>

                                                                <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>{row?.name}</StyledTableCell>

                                                                <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                                    {row?.version}
                                                                </StyledTableCell>
                                                                <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                                    {localStorage.getItem('modelVersionsCheck') === 'false' ? row?.stage : row?.current_stage}
                                                                </StyledTableCell>
                                                                {localStorage.getItem('modelVersionsCheck') === 'false'
                                                                    ? chartMetricsValue(row)
                                                                    : row?.metric?.map((item, indexs) => (
                                                                          <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }} key={indexs}>
                                                                              {getFirstValue(item?.value)}
                                                                          </StyledTableCell>
                                                                      ))}
                                                            </TableRow>
                                                        </React.Fragment>
                                                    </>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    {tableDataMetrics && tableDataMetrics.length > 0 ? (
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            component="div"
                                            count={tableDataMetrics.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </TableContainer>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
}

export default CompareModel;
