import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Select from 'components/select';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { OrderPayload, showisExistADGrp, selectedMembersList, memberForm, costEstimateState } from 'pages/myidm/store';
import { resetCostEstimateDetails } from 'pages/myidm/utils';
import useDebounce from 'pages/myidm/components/hooks/use-debounce';
import { InputInfo } from 'pages/myidm/components/input-info';

const LDAPGroup = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setIsExistADGrp] = useRecoilState(showisExistADGrp);
    const [, setMembers] = useRecoilState(selectedMembersList);
    const [form, setForm] = useRecoilState(memberForm);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [notFound, setNotFound] = React.useState(false);
    const [, setcostEstimate] = useRecoilState(costEstimateState);

    const adGroupsApi = useMutation((payload) => axios.post(`${endPoints?.myIdm?.ldapGroupApi}`, payload));
    // const adGroupsApi = useMutation((payload) =>
    //     axios.get(
    //         payload?.groupName === 'xxx'
    //             ? `${endPoints?.myIdm?.ldapGroupApiEmpty}?environment=${payload?.environment}&groupName=${payload?.groupName}`
    //             : `${endPoints?.myIdm?.ldapGroupApi}?environment=${payload?.environment}&groupName=${payload?.groupName}`
    //     )
    // );
    const adGroupDetailsApi = useMutation((adgrp) => axios.get(`${endPoints.myIdm.adGroupDetailsApi}/${adgrp}`));
    const debouncedInputValue = useDebounce(inputValue, 500);

    const setAdGroup = (adGroup) => {
        setOrder({
            ...order,
            adGroup,
            isNewADGroup: order?.isNewADGroup === 'no' ? order?.isNewADGroup : null,
            applicationName: null,
            appShortName: null,
            adGroupType: null,
            adGroupTypeDetails: null,
            groupOwner: null,
            groupOwnerDetails: null,
            estimatedCost: resetCostEstimateDetails,
            adGroupMembers: [],
            nonSafeDetailsGpid: [],
            members: [],
        });
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
        setMembers([]);
        setcostEstimate(resetCostEstimateDetails);
    };
    const handleOnSelectChange = (selected) => {
        setIsExistADGrp(false);
        setNotFound(false);
        setAdGroup(selected.value);
    };
    React.useEffect(() => {
        if (order?.isNewADGroup !== 'yes' && !order?.adGroupType && order?.adGroup) adGroupDetailsApi?.mutate(order?.adGroup);
    }, [order?.isNewADGroup, order?.adGroupType, order?.adGroup]);

    React.useEffect(() => {
        if (adGroupsApi?.isSuccess && adGroupsApi.data?.data && debouncedInputValue !== '') {
            const adList = adGroupsApi.data?.data?.map((adGroup) => ({
                label: adGroup,
                value: adGroup,
            }));
            setIsExistADGrp(false);
            setNotFound(false);
            setOptions(adList);
            // setOrder({ ...order, isNewADGroup: null });
        } else if (adGroupsApi?.data?.isSuccess === false && adGroupsApi?.data?.data === null && debouncedInputValue !== '') {
            setOrder({ ...order, isNewADGroup: null, crossDomainUser: null, isUniversalADGroup: false, members: null, adGroupMembers: null });
            setMembers([]);
            setForm({
                ...form,
                selectedMember: null,
                member: null,
            });
            setNotFound(true);
            setIsExistADGrp(true);
        }
    }, [adGroupsApi?.isSuccess?.toString()]);
    React.useEffect(() => {
        if (adGroupDetailsApi?.data?.data) {
            setOrder({
                ...order,
                adGroupType: adGroupDetailsApi?.data?.data?.[0]?.adgroupType,
                adGroupTypeDetails: null,
                groupOwner: null,
                groupOwnerDetails: null,
                estimatedCost: resetCostEstimateDetails,
            });
        }
    }, [adGroupDetailsApi?.isSuccess]);

    useEffect(() => {
        if (debouncedInputValue) {
            setOptions([]);
            const payload = {
                environment: order?.environment,
                groupName: debouncedInputValue,
            };
            adGroupsApi.mutate(payload);
        } else {
            setOptions([]);
        }
    }, [debouncedInputValue]);

    React.useEffect(() => {
        if (!order?.adGroup && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.adGroup, order?.isTouched]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    key="ldapGroup"
                    label="LDAP Group *"
                    value={order?.adGroup || null}
                    options={options}
                    disabled={!order.businessApplication}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    isLoading={adGroupsApi.isLoading}
                    noOptionsText="Type to search"
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    fullWidth
                    onInputChange={(event, newInputValue) => {
                        // setIsTouched(false);
                        setInputValue(newInputValue);
                    }}
                    isRequired={isTouched}
                    sx={{ mt: 0, mb: 0 }}
                    customValidate={notFound ? 'validate' : ''}
                    data-testid="adgrp-auto"
                    onKeyUp={(event) => {
                        if (event.key === 'Backspace' || event.key === 'Delete') {
                            setOrder({ ...order, isNewADGroup: null, adGroupMembers: null, members: null });
                            setForm({
                                ...form,
                                selectedMember: null,
                                member: null,
                            });
                            setMembers([]);
                        }
                    }}
                    onBlur={() => {
                        if (options?.length === 0) {
                            setOrder({ ...order, adGroup: null, isNewADGroup: null });
                            setNotFound(false);
                        }
                    }}
                />
                <InputInfo title="Type characters for suggestions." />
            </Box>
        </>
    );
};

export default React.memo(LDAPGroup);
