import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Select, { CssTextField } from 'pages/reference-implementation/components/select';
import { RIStyles } from 'pages/reference-implementation/Style';

const ReadAccessGroupOwner = (props) => {
    const { isRequired, handleOnSelect, readAccessGroupOwnerValue, errors, secondaryApprover, disabledField } = props;
    const [searched, setSearched] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const adGroupOwnerApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.adGroupManager}?name=${ccInputValue}`));
    const adGroupOwnerPostApi = useMutation((payload) => axios.get(`${endPoints.riServices.COMMON.adGroupManager}?name=${payload}`));

    const resetOptions = () => {
        setOptions([]);
        handleOnSelect({
            groupOwner: null,
            groupOwnerDetails: null,
        });
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                adGroupOwnerApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };

    React.useEffect(() => {
        if (adGroupOwnerPostApi?.data?.data) {
            const combinedValue = adGroupOwnerPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [adGroupOwnerPostApi?.data?.data]);

    React.useEffect(() => {
        if (adGroupOwnerApi?.data?.data) {
            const combinedValue = adGroupOwnerApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [adGroupOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            handleOnSelect({
                groupOwner: value?.label,
                groupOwnerDetails: value,
            });
        }
    }, [value]);

    const isExistsInOptions = (appownerValue) => options.find((option) => appownerValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && (readAccessGroupOwnerValue?.groupOwnerDetails || secondaryApprover?.value?.groupOwner)) {
            handleOnSelect({ groupOwner: options?.[0].label, groupOwnerDetails: options?.[0] });
        }
        if (options && options?.length > 1 && readAccessGroupOwnerValue?.groupOwnerDetails && !readAccessGroupOwnerValue?.groupOwner) {
            const adGroupObj = isExistsInOptions(readAccessGroupOwnerValue?.groupOwnerDetails?.name);
            handleOnSelect({ groupOwner: adGroupObj?.label, groupOwnerDetails: adGroupObj });
        }
    }, [options]);

    React.useEffect(() => {
        /* if (
            (secondaryApprover?.value?.groupOwner && !readAccessGroupOwnerValue?.groupOwnerDetails) ||
            readAccessGroupOwnerValue?.groupOwnerDetails?.name !== secondaryApprover?.value?.groupOwnerDetails?.name
        ) {
            adGroupOwnerPostApi.mutate(secondaryApprover?.value?.groupOwnerDetails?.name);
        } */
        if (!readAccessGroupOwnerValue?.groupOwner && readAccessGroupOwnerValue?.groupOwnerDetails) {
            adGroupOwnerPostApi.mutate(readAccessGroupOwnerValue?.groupOwnerDetails?.gpid);
        }
    }, [readAccessGroupOwnerValue, secondaryApprover]);

    const validateInput = () => {
        if (isRequired || errors?.showError) {
            return 'validate';
        }
        return '';
    };
    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };
    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    key="Read Access Group Owner *"
                    size="small"
                    noOptionsText={getNoOptionText()}
                    loading={adGroupOwnerApi?.isLoading}
                    value={readAccessGroupOwnerValue?.groupOwner || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                    }}
                    disabled={disabledField}
                    fullWidth
                    className={validateInput()}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a ccccccnew value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    isRequired={readAccessGroupOwnerValue?.groupOwner !== '' && readAccessGroupOwnerValue?.groupOwner !== null ? isRequired : false}
                    //    filterOptions={filterOptions}

                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    data-testid="readAccessGroupOwner"
                    disableClearable
                    id="free-solo-with-text-demo"
                    // sx={{
                    //     width: '100%',
                    //     '& .MuiSvgIcon-root': {
                    //         marginRight: '-10%',
                    //     },
                    // }}
                    options={options}
                    isOptionEqualToValue={(option, value1) => option?.label === value1}
                    renderOption={(renderprops, option) => (
                        <li {...renderprops} key={option.gpid}>
                            {option.label}
                        </li>
                    )}
                    onBlur={() => {
                        if (!value || !readAccessGroupOwnerValue?.groupOwner) {
                            setSearched(false);
                            setOptions([]);
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(true);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                        }
                    }}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Read Access Service Account Owner*"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <SearchIcon
                                            sx={RIStyles?.form?.searchIcon}
                                            onClick={() => {
                                                setSearched(true);
                                                handleSearch();
                                            }}
                                            data-testid="search-btn"
                                        />
                                    </>
                                ),
                            }}
                            fullWidth
                        />
                    )}
                />
            </Box>
        </>
    );
};
ReadAccessGroupOwner.propTypes = {
    isRequired: PropTypes.bool,
    handleOnSelect: PropTypes.func,
    errors: PropTypes.any,
    readAccessGroupOwnerValue: PropTypes.any,
    secondaryApprover: PropTypes.any,
    disabledField: PropTypes.any,
};

ReadAccessGroupOwner.defaultProps = {
    isRequired: false,
    handleOnSelect: () => {},
    errors: {},
    readAccessGroupOwnerValue: null,
    secondaryApprover: null,
    disabledField: false,
};
export default React.memo(ReadAccessGroupOwner);
