import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import ProvisioningStyles from 'pages/provisioning/public-load-balancer/style';
import {
    environmentDetails,
    isMandatoryTagsFullfilled,
    orderId,
    sspSubmitOrder,
    toggleSspModels,
    isLoadBalancerUrlValid,
    isValidPort,
    isValidISANumber,
    isValidScanNumber,
    isValidOwners,
} from 'pages/provisioning/store/public-load-balancer';
import { resetEnvironmentDetails, resetOrder, resetToggleSspModal, formOrderObjFromOrderDetails } from './utils';

const ProvisioningHeader = React.lazy(() => import('pages/provisioning/public-load-balancer/ProvisioningHeader'));
const SspForm = React.lazy(() => import('pages/provisioning/public-load-balancer/SspForm'));
const OrderSuccessDialog = React.lazy(() => import('pages/provisioning/public-load-balancer/OrderSuccessDialog'));
const ShowCostDetails = React.lazy(() => import('pages/provisioning/public-load-balancer/ShowCostDetails'));

const PublicLoadBalancerProvisioning = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetValidOwners] = useRecoilState(isValidOwners);
    const resetLoadBalancerUrlValid = useResetRecoilState(isLoadBalancerUrlValid);
    const resetFrontendPortValid = useResetRecoilState(isValidPort);
    const resetValidISANumber = useResetRecoilState(isValidISANumber);
    const resetValidScanNumber = useResetRecoilState(isValidScanNumber);

    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();

    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);

        return data;
    }
    async function checkCatalog() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}`);
        const costs = await getCosts();
        if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }

    const reset = () => {
        setOrder({ ...order, ...resetOrder });
        resetOrderId('');
        resetLoadBalancerUrlValid();
        resetFrontendPortValid();
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetValidISANumber();
        resetValidScanNumber();
        resetValidOwners({
            isvalidAppOwner: true,
            isvalidCertificateOwner1: true,
            isvalidCertificateOwner2: true,
        });
    };

    React.useEffect(() => {
        if (catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <Helmet>
                <title>Public Load Balancer URL Onboarding</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                }
            >
                {isLoading && (
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <Box sx={ProvisioningStyles.provisioningBox}>
                        <ProvisioningHeader />
                        <Box sx={ProvisioningStyles.sspform_wrapper} data-testid="index-form">
                            <SspForm />
                        </Box>
                        <br />
                        <OrderSuccessDialog />
                        <ShowCostDetails />
                    </Box>
                )}
            </Suspense>
        </>
    );
};

export default PublicLoadBalancerProvisioning;
