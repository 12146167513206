import { AD_GROUP, LDAP_GROUP } from 'pages/myidm/utils/constant';

export const resetCostEstimateDetails = {
    details: [],
    totalOneTimeCost: {
        item: 'Total One Time',
        frequency: 'One Time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'yearly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'The License cost for safe in myPAM per user per year is $350 and cost is not estimated in CMP for existing user with safe details.',
};
export const resetOrder = {
    adGroup: null,
    businessApplication: null,
    businessApplicationDetails: null,
    environment: null,
    envShortName: null,
    sector: null,
    isNewADGroup: null,
    AppShortName: null,
    applicationName: null,
    adGroupType: null,
    adGroupTypeDetails: null,
    groupOwner: null,
    groupOwnerDetails: null,
    appService: null,
    members: null,
    mypamsafeDetails: [],
    nonSafeDetailsGpid: [],
    estimatedCost: resetCostEstimateDetails,
    isTouched: false,
};

export const getSelectMemberBtnStatus = ({ order }) => {
    if (!order?.groupType) {
        return { isValid: false, message: 'Group Type is required' };
    }
    // Check if it's not a new AD group and ADGroup is not selected
    if ((order?.isNewADGroup === 'no' || order?.isNewADGroup === null) && !order?.adGroup && order?.groupType === AD_GROUP) {
        return { isValid: false, message: 'AD Group must be selected for existing groups.' };
    }
    if ((order?.isNewADGroup === 'no' || order?.isNewADGroup === null) && !order?.adGroup && order?.groupType === LDAP_GROUP) {
        return { isValid: false, message: 'LDAP Group must be selected for existing groups.' };
    }

    // If it's a new AD group, ensure all relevant fields are filled for AD group
    if (order?.isNewADGroup === 'yes' && order?.groupType === AD_GROUP) {
        if (!order?.appShortName) return { isValid: false, message: 'App short name is required for new AD groups.' };
        if (!order?.groupOwner) return { isValid: false, message: 'Group owner is required for new AD groups.' };
        if (!order?.adGroupType) return { isValid: false, message: 'AD Group type is required for new AD groups.' };
        if (!order?.applicationName) return { isValid: false, message: 'Application name is required for new AD groups.' };
        if (!order?.appService) return { isValid: false, message: 'Application service is required for new AD groups.' };
    }

    // If it's a new LDAP group, ensure relevant fields are filled for LDAP group
    if (order?.isNewADGroup === 'yes' && order?.groupType === LDAP_GROUP) {
        if (!order?.projectName || !/^[A-Za-z0-9]{2,10}$/.test(order?.projectName)) {
            return { isValid: false, message: 'Project name is required for new LDAP groups.' };
        }
        if (!order?.groupOwner) return { isValid: false, message: 'Group owner is required for new LDAP groups.' };
    }

    // Check for general fields that must be filled for any group type
    if (!order?.businessApplication) return { isValid: false, message: 'Business application is required.' };
    if (!order?.environment) return { isValid: false, message: 'Environment is required.' };
    if (!order?.sector) return { isValid: false, message: 'Sector is required.' };

    // If all checks pass, return true
    return { isValid: true, message: 'All fields are valid.' };
};

export const isReadyToGetCost = ({ order, appShortNameValid, appShortNameExist, applicationNameValid }) => {
    // Check for existing AD group fields
    if (order?.isNewADGroup === 'no') {
        if (!order?.adGroup || !order?.adGroupType) {
            return { isValid: false, message: 'AdGroup or AdGroupType is required for existing AD group' };
        }
    }

    // Check for new AD group fields
    if (order?.isNewADGroup === 'yes') {
        const missingFields = [];
        if (!order?.appShortName) missingFields.push('appShortName');
        if (!order?.groupOwner) missingFields.push('groupOwner');
        if (!order?.adGroupType) missingFields.push('adGroupType');
        if (!order?.applicationName) missingFields.push('applicationName');
        if (!appShortNameValid || appShortNameExist) missingFields.push('Invalid or existing appShortName');
        if (!applicationNameValid || !order?.appService) missingFields.push('Invalid application name or missing appService');

        if (missingFields.length > 0) {
            return { isValid: false, message: `Missing required fields for new AD group: ${missingFields.join(', ')}` };
        }
    }

    // Check common fields (applicable to both new and existing AD groups)
    if (!order?.businessApplication) {
        return { isValid: false, message: 'Business application is required' };
    }
    if (!order?.environment) {
        return { isValid: false, message: 'Environment is required' };
    }
    if (!order?.sector) {
        return { isValid: false, message: 'Sector is required' };
    }
    if (!order?.members?.length > 0) {
        return { isValid: false, message: 'At least one member is required' };
    }

    // Check for Privileged AD group specific field
    if (order?.adGroupType === 'Privileged' && !order?.nonSafeDetailsGpid?.length > 0) {
        return { isValid: false, message: 'nonSafeDetailsGpid is required for Privileged AdGroupType' };
    }

    // If all checks pass
    return { isValid: true, message: 'Order is ready to get cost' };
};

export const isReadyToSubmit = ({ order, appShortNameValid, appShortNameExist, applicationNameValid }) => {
    if (!order?.groupType) {
        return { isValid: false, message: 'Group Type is required' };
    }
    // Check if it's not a new AD group and ADGroup is not selected
    if ((order?.isNewADGroup === 'no' || order?.isNewADGroup === null) && !order?.adGroup && order?.groupType === AD_GROUP) {
        return { isValid: false, message: 'AD Group must be selected for existing groups.' };
    }
    if ((order?.isNewADGroup === 'no' || order?.isNewADGroup === null) && !order?.adGroup && order?.groupType === LDAP_GROUP) {
        return { isValid: false, message: 'LDAP Group must be selected for existing groups.' };
    }

    // If it's a new AD group, ensure all relevant fields are filled for AD group
    if (order?.isNewADGroup === 'yes' && order?.groupType === AD_GROUP) {
        if (!order?.appShortName) return { isValid: false, message: 'App short name is required for new AD groups.' };
        if (!appShortNameValid || appShortNameExist) return { isValid: false, message: 'Invalid or existing appShortName.' };
        if (!order?.groupOwner) return { isValid: false, message: 'Group owner is required for new AD groups.' };
        if (!order?.adGroupType) return { isValid: false, message: 'AD Group type is required for new AD groups.' };
        if (!order?.applicationName) return { isValid: false, message: 'Application name is required for new AD groups.' };
        if (!applicationNameValid || !order?.appService) return { isValid: false, message: 'Application service is required for new AD groups.' };
    }

    // If it's a new LDAP group, ensure relevant fields are filled for LDAP group
    if (order?.isNewADGroup === 'yes' && order?.groupType === LDAP_GROUP) {
        if (!order?.projectName || !/^[A-Za-z0-9]{2,10}$/.test(order?.projectName)) {
            return { isValid: false, message: 'Project name is required for new LDAP groups.' };
        }
        if (!order?.groupOwner) return { isValid: false, message: 'Group owner is required for new LDAP groups.' };
    }

    // Check for general fields that must be filled for any group type
    if (!order?.businessApplication) return { isValid: false, message: 'Business application is required.' };
    if (!order?.environment) return { isValid: false, message: 'Environment is required.' };
    if (!order?.sector) return { isValid: false, message: 'Sector is required.' };

    // If all checks pass, return true
    return { isValid: true, message: 'All fields are valid.' };
};
