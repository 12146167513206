import { convertToTitleCase } from 'utils';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import toast from 'react-hot-toast';
import { Box } from '@mui/material';
import React from 'react';

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const getInstanceDetails = (orderData) => {
        if (orderData) {
            return orderData.map((item) => {
                if (item && item?.catalogConfig) {
                    const itemCC = item?.catalogConfig;
                    return {
                        privateEndpointType: itemCC?.privateEndpointType === 'enterprise' ? 'Enterprise PE' : 'Isolated PE',
                        ...(itemCC?.sourceResource && {
                            sourceResource: {
                                resourceType: itemCC?.sourceResource?.resourceType,
                                resourceTypeDisplayName: itemCC?.sourceResource?.resourceTypeDisplayName,
                                resourceName: itemCC?.sourceResource?.resourceName,
                                resourceId: itemCC?.sourceResource?.resourceId,
                                subscriptionId: itemCC?.sourceResource?.subscription,
                                subscription: itemCC?.sourceResource?.subscriptionName,
                                resourceGroup: itemCC?.sourceResource?.resourceGroup,
                                location: itemCC?.sourceResource?.location,
                            },
                        }),
                        ...(itemCC?.targetResource && {
                            targetResource: {
                                resourceType: itemCC?.targetResource?.resourceType,
                                resourceTypeDisplayName: itemCC?.targetResource?.resourceTypeDisplayName,
                                resourceName: itemCC?.targetResource?.resourceName,
                                resourceId: itemCC?.targetResource?.resourceId,
                                subscriptionId: itemCC?.targetResource?.subscription,
                                subscription: itemCC?.targetResource?.subscriptionName,
                                resourceGroup: itemCC?.targetResource?.resourceGroup,
                                location: itemCC?.targetResource?.location,
                                resourceSubType: itemCC?.targetResource?.resourceSubType,
                                environment: itemCC?.targetResource?.environment,
                            },
                        }),
                    };
                }
                return [];
            }); // return the result array
        }
        return []; // return an empty array if orderData is not provided
    };
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const order1 = data?.catalogServices?.[0];
    const catalogConfig = order1?.catalogConfig;
    const entityDetails = data?.entityDetails || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            sector: entityDetails?.sector,
        },
        sector: catalogConfig?.sector || null,
        appOwner: data?.manager?.id,
        appOwnerDetails: null,
        instanceDetails: getInstanceDetails(data?.catalogServices),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include data processed (inbound/outbound), there will be additional charges applicable $0.01 per GB based on data usage.',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    appOwner: null,
    appOwnerDetails: null,
    // initCost: null,
    instanceDetails: [
        {
            privateEndpointType: null,
            sourceResource: null,
            targetResource: null,
            isClone: false,
        },
    ],
    estimatedCost: resetCostEstimateDetails,
    showPreview: null,
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetForQuickConfig = {
    environment: null,
    appTier: 'Azure Private Endpoint',
    appTierCode: 'PE',
    region: null,
    regionDisplayName: null,
    hCode: null,
    hCodeDetails: null,
    endpointType: null,
    endpointTypeDetails: null,
    resourceType: null,
    resourceTypeDetails: null,
    resource: null,
    resourceDetails: null,
    targetSubResource: null,
    targetSubResourceDetails: null,
    subscription: null,
    subscriptionId: null,
    databricksSubscription: null,
    databricksSubscriptionId: null,
    databricks: null,
    envDetails: null,
    tags: [],
    estimatedCost: resetCostEstimateDetails,
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetEnvironmentDetails = {
    Approval_Manager: '-',
    Project: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    instanceDetailsModal: { open: false, index: null, type: null },
    isTagOpen: false,
    isDiskOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
    isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
};

export const dtClassAllowResouces = ['Microsoft.DataFactory/factories', 'Microsoft.Synapse/workspaces'];
export const findDuplicateIndexesInArray = (array) => {
    const uniqueElements = new Map();
    const duplicates = new Map();
    const newArray = array?.map((item) => {
        const newItem = { ...item };
        delete newItem.isClone;
        return newItem;
    });
    newArray?.forEach((item, index) => {
        const jsonString = JSON.stringify(item);
        if (uniqueElements.has(jsonString)) {
            if (duplicates.has(jsonString)) {
                duplicates.get(jsonString).push(index);
            } else {
                duplicates.set(jsonString, [uniqueElements.get(jsonString), index]);
            }
        } else {
            uniqueElements.set(jsonString, index);
        }
    });

    return duplicates; // Return the Map of duplicate items and their indexes
};
export const combineIndexesOfDuplicates = (duplicates) => {
    const combined = [];

    duplicates.forEach((indexes) => {
        combined.push(...indexes);
    });

    return combined;
};
export const getCombinedDuplicateIndexes = (array) => {
    const duplicates = findDuplicateIndexesInArray(array);
    return combineIndexesOfDuplicates(duplicates);
};
export const isResourceValid = (rowData, type) => {
    if (type === 'sourceResource') {
        const sourceResource = rowData?.sourceResource;
        return Boolean(
            sourceResource?.resourceType &&
                sourceResource?.resourceName &&
                sourceResource?.subscription &&
                sourceResource?.resourceGroup &&
                sourceResource?.location &&
                sourceResource?.environment
        );
    }
    if (type === 'targetResource') {
        const targetResource = rowData?.targetResource;
        return Boolean(
            targetResource?.resourceType &&
                targetResource?.resourceName &&
                targetResource?.subscription &&
                targetResource?.resourceGroup &&
                targetResource?.location &&
                targetResource?.resourceSubType &&
                targetResource?.environment
        );
    }
    return false;
};
export const isInstanceValid = (rowData) => {
    let isSourceResValid = true;
    if (rowData?.privateEndpointType === 'Isolated PE') {
        isSourceResValid = Boolean(isResourceValid(rowData, 'sourceResource'));
    }
    const isTargetResValid = Boolean(isResourceValid(rowData, 'targetResource'));
    return Boolean(rowData?.privateEndpointType && isSourceResValid && isTargetResValid);
};
export const validateInstanceDetails = (instanceDetails) => {
    const duplicates = getCombinedDuplicateIndexes(instanceDetails);
    if (duplicates.length > 0) {
        return { isValid: false, message: 'Data is invalid due to duplicates.', data: { duplicates } };
    }

    const requiredSourceFields = ['resourceType', 'resourceName', 'subscription', 'resourceGroup'];
    const requiredTargetFields = ['resourceType', 'resourceName', 'subscription', 'resourceGroup', 'resourceSubType', 'environment'];

    let validationMessage = { isValid: true, message: 'Data is valid.', data: { duplicates: [] } };

    instanceDetails.forEach((item, index) => {
        if (validationMessage.isValid) {
            if (item?.isClone === true) {
                validationMessage = {
                    isValid: false,
                    message: `Cloned instance should required to modify. ${index}.`,
                    data: { index },
                };
                return;
            }
            if (!item?.privateEndpointType || item?.privateEndpointType === '') {
                validationMessage = {
                    isValid: false,
                    message: `privateEndpointType is required and cannot be null or empty at index ${index}.`,
                    data: { index },
                };
                return;
            }
            if (item?.privateEndpointType === 'Isolated PE') {
                if (!item?.sourceResource || !item?.targetResource) {
                    validationMessage = {
                        isValid: false,
                        message: `Isolated PE type requires both sourceResource and targetResource. Found issue at index ${index}.`,
                        data: { index },
                    };
                    return;
                }
                requiredSourceFields.forEach((field) => {
                    if (validationMessage.isValid && (!(field in item?.sourceResource) || item?.sourceResource[field] == null)) {
                        validationMessage = {
                            isValid: false,
                            message: `Missing or null field '${field}' in sourceResource for Isolated PE at index ${index}.`,
                            data: { index },
                        };
                    }
                });
                requiredTargetFields.forEach((field) => {
                    if (validationMessage.isValid && (!(field in item?.targetResource) || item?.targetResource[field] == null)) {
                        validationMessage = {
                            isValid: false,
                            message: `Missing or null field '${field}' in targetResource for Isolated PE at index ${index}.`,
                            data: { index },
                        };
                    }
                });
            } else if (item?.privateEndpointType === 'Enterprise PE') {
                requiredTargetFields.forEach((field) => {
                    if (validationMessage.isValid && (!item?.targetResource || !(field in item?.targetResource) || item?.targetResource[field] == null)) {
                        validationMessage = {
                            isValid: false,
                            message: `Missing or null field '${field}' in targetResource for Enterprise PE at index ${index}.`,
                            data: { index },
                        };
                    }
                });
            }
        }
    });

    return validationMessage;
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};
export const isReadyToSubmit = ({ order }) => {
    const validation = order?.instanceDetails ? validateInstanceDetails(order.instanceDetails) : { isValid: false };
    return Boolean(order?.appProfile && order?.sector && order?.appOwner && validation.isValid);
};
export const displayValue = (value) => value || '-';
export const formatMessage = (message) => {
    let formattedMessage;

    if (message.includes('{')) {
        // Extract the prefix and the JSON string from the message
        const prefix = message.substring(0, message.indexOf('{')).trim();
        const jsonString = message.substring(message.indexOf('{'));

        // Parse the JSON string into an object
        const jsonObject = JSON.parse(jsonString);

        // Define the order of the keys
        const keyOrder = ['privateEndpointName', 'resourceGroupName', 'subscriptionName'];

        // Sort the entries of the object according to the key order
        const sortedEntries = Object.entries(jsonObject).sort((a, b) => keyOrder.indexOf(a[0]) - keyOrder.indexOf(b[0]));

        // Format the message
        formattedMessage = prefix + sortedEntries.map(([key, value]) => `<br />${convertToTitleCase(key)}: ${value}`).join('');
    } else {
        // If the message doesn't contain a JSON string, use the message as it is
        formattedMessage = message;
    }

    return formattedMessage;
};

export const customToast = (messages = [], type = 'error') => {
    const color = type === 'error' ? '#ff003e' : '#735c0f';
    const Icon = type === 'error' ? CancelIcon : WarningIcon;
    toast.dismiss();
    toast.custom(
        (t) => (
            <Box
                display="flex"
                px={2}
                py={2}
                gap={1}
                sx={{
                    background: '#fff',
                    borderRadius: 2,
                    alignItems: 'center',
                    maxWidth: '80%',
                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
                }}
            >
                <Icon sx={{ color }} />
                <div>
                    {messages?.length === 1 ? (
                        // eslint-disable-next-line react/no-danger
                        <div style={{ paddingLeft: '20px', paddingRight: '20px' }} dangerouslySetInnerHTML={{ __html: messages?.[0] }} />
                    ) : (
                        messages?.map((item, i) => (
                            <ul key={i} style={{ listStylePosition: 'outside', paddingInlineStart: '10px', marginBlockStart: '1px', marginBlockEnd: '1px' }}>
                                <li>{item}</li>
                            </ul>
                        ))
                    )}
                </div>
                <Box sx={{ color: '#0047BA', cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)}>
                    Close
                </Box>
            </Box>
        ),
        {
            duration: 50000,
        }
    );
};
