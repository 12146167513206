import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button, Alert } from '@mui/material';
import toast from 'react-hot-toast';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import FormCard from 'pages/reference-implementation/components/form-card';
import { FaTrashAlt } from 'react-icons/fa';
import Btn from 'components/button';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { useParams } from 'react-router-dom';
import { getEndpointForCatalogCode } from 'pages/reference-implementation/utils';
import ConfirmDialog from 'pages/reference-implementation/provisioning/RI003O/components/Form/ConfirmDialog';
import { RIStyles } from 'pages/reference-implementation/Style';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import ConsumerGroupList from './components/ConsumerGroupList';
import TopicName from './components/TopicName';
import ConsumerGroup from './components/ConsumerGroup';
import Retention from './components/Retention';
import Partitions from './components/Partitions';
import BusinessUnit from './components/BusinessUnit';
import Program from './components/Program';
import WriteAccessRadioBtn from './components/WriteAccessRadioBtn';
import WriteAccessGroupOwner from './components/WriteAccessGroupOwner';
import WriteAccessUser from './components/WriteAccessUser';
import ReadAccessUser from './components/ReadAccessUser';
import ReadAccessRadioBtn from './components/ReadAccessRadioBtn';
import ReadAccessGroupOwner from './components/ReadAccessGroupOwner';
import ConsumerGroupRadioBtn from './components/ConsumerGroupRadioBtn';
import TransactionIdRadioBtn from './components/TransactionIdRadioBtn';
import TransactionIDPrefix from './components/TransactionIDPrefix';
import TransactionalPrincipalId from './components/TransactionalPrincipalId';
import LdapGroup from './components/LdapGroup';
import { kafkaFieldValidation, validEmptyForm, handleCloneStatus } from '../../../utils/kafkaValidation';
import { getKafkaValidPayload } from '../../../utils/getPayload';

const KAFKA = (props) => {
    const { resource, handleDrawerAction, order, secondApprover, handleSecondApprover } = props;
    const { catalogCode } = useParams();
    const riEndpoints = getEndpointForCatalogCode(catalogCode);
    const validateKafkaApi = useMutation((payload) => axios.post(riEndpoints?.Jwt?.validateKafkaApi, payload));

    const serviceAccountNoApi = useMutation((payload) => axios.post(`${riEndpoints?.Jwt?.serviceAccountNoValidation}`, payload));

    const intialKafkaTopic = {
        topicName: '',
        partitions: null,
        isConsumerGroup: 'No',
        consumerGroups: [{ value: '' }],
        isWriteAccess: null,
        isReadAccess: null,
        readAccessGroupOwner: null,
        writeAccessGroupOwner: null,
        disableWriteServiceAccOwner: null,
        disableReadServiceAccOwner: null,
        writeAccessUser: null,
        writeAccessUserValid: 'notValidated',
        readAccessUserValid: 'notValidated',
        readAccessUser: null,
        isTransactionalId: 'No',
        transactionalPrincipalId: null,
        transactionprefixtext: null,
        retention: { retention: '', retentionUnit: 'In Days', retentionDetails: null },
    };
    const [formData, setFormData] = useState({
        businessUnit: resource?.configureData?.businessUnit || '',
        ldap: resource?.configureData?.ldap || '',
        program: resource?.configureData?.program || '',
        kafkatopics: resource?.configureData?.kafkatopics?.length > 0 ? resource?.configureData?.kafkatopics : [{ ...intialKafkaTopic }],
    });
    const [errors, setErrors] = useState({});
    const [fillingFormErrors, setFormFillingErros] = useState({
        businessUnit: {},
        ldap: {},
        program: {},
        kafkatopics: [
            {
                topicName: '',
                partitions: '',
                isConsumerGroup: '',
                consumerGroups: '',
                writeAccessUser: '',
                readAccessGroupOwner: null,
                writeAccessGroupOwner: null,
                readAccessUser: '',
                isTransactionalId: '',
                transactionprefixtext: '',
                transactionalPrincipalId: '',
                retention: '',
                isReadAccess: null,
                isWriteAccess: null,
            },
        ],
    });
    const [touched, setTouched] = useState({});
    const [showConsumerModal, setShowCosumerModal] = React.useState(false);
    const [selectedConsumerGrps, setSelectedConsumerGrps] = React.useState([]);
    const [selectedtopicIndex, setSelectedtopicIndex] = React.useState('');
    const [showTopicDeleteModal, setShowTopicDeleteModal] = React.useState({ showModal: false, index: 0 });
    const [allconsumerGrpList, setAllConsumerGroupList] = React.useState([]);
    const [allTopics, setallTopics] = React.useState([]);
    const [kafkaSecondApprover, setKafkaSecondApprover] = React.useState({
        selectedFieldFrom: { module: null, subModule: null },
        value: { groupOwner: null, groupOwnerDetails: null },
    });
    const [topicActiveIndex, setTopicActiveIndex] = React.useState({ topicIdx: null, topicField: null, fieldStatus: null });
    const [approverChanged, setApproverChanged] = React.useState(false);

    const validateForm = () => {
        const emptyForm = validEmptyForm(formData, fillingFormErrors);
        setErrors({ ...emptyForm });
        const checkValidObj = () => {
            const intailCheck = emptyForm?.ldap === '' && emptyForm?.businessUnit === '' && emptyForm?.program === '';
            const kafkaCheck = emptyForm?.kafkatopics?.every((topic) => Object.keys(topic)?.length === 0);
            return intailCheck && kafkaCheck;
        };

        return { isValid: checkValidObj(), errors: emptyForm };
    };

    const validateServiceAccountExist = (type) => {
        serviceAccountNoApi.mutate({
            accountType: 'Kafka',
            applicationShortName: order?.appShortName,
            environment: order?.environment,
            accountPermissions: type === 'isReadAccess' ? 'read' : 'write',
        });
    };

    React.useEffect(() => {
        if (topicActiveIndex?.topicIdx !== null) {
            let tempFormData = { ...formData };
            const tempTopics = [...tempFormData?.kafkatopics];
            const tempKafkaTopic = { ...tempTopics[topicActiveIndex?.topicIdx] };
            const modifyKey = topicActiveIndex?.topicField === 'isWriteAccess' ? 'disableWriteServiceAccOwner' : 'disableReadServiceAccOwner';

            if (serviceAccountNoApi?.data?.data?.serviceAccountExist === false) {
                // setDisableGroupOwner(false);
                tempKafkaTopic[modifyKey] = false;
            } else if (serviceAccountNoApi?.data?.data?.serviceAccountExist === true) {
                // setDisableGroupOwner(true);
                tempKafkaTopic[modifyKey] = true;

                // toast.error('Service Account already exists. Please use different Application Short Name');
            }
            tempTopics[topicActiveIndex?.topicIdx] = tempKafkaTopic;
            tempFormData = { ...tempFormData, kafkatopics: tempTopics };
            setFormData(tempFormData);
        }
    }, [topicActiveIndex, serviceAccountNoApi?.data?.data]);

    const handleOnSelectChange = (key, value, index, writeAccessStatus = { valid: null }, readAccessStatus = { valid: null }) => {
        toast.dismiss();
        if (key === 'businessUnit' || key === 'ldap' || key === 'program') {
            setFormData({ ...formData, [key]: value });
            setTouched({ ...touched, [key]: true });
        } else if (
            [
                'topicName',
                'partitions',
                'isConsumerGroup',
                'writeAccessUser',
                'readAccessUser',
                'isTransactionalId',
                'transactionalPrincipalId',
                'retention',
                'transactionprefixtext',
                'isWriteAccess',
                'isReadAccess',
                'writeAccessGroupOwner',
                'readAccessGroupOwner',
            ].includes(key)
        ) {
            let tempFormData = { ...formData };
            const tempTopics = [...tempFormData?.kafkatopics];
            let tempKafkaTopic = { ...tempFormData?.kafkatopics?.[index] };
            tempKafkaTopic[key] = value;
            const touchedDatatopics = touched?.kafkatopics || [];
            let touchedTopic = { ...touchedDatatopics[index] };

            if (key !== 'retention') {
                if (value?.length > 1) {
                    touchedTopic[key] = true;
                } else {
                    touchedTopic[key] = false;
                }
            } else if (key === 'retention' && (tempKafkaTopic?.retention || tempKafkaTopic?.retentionDetails)) {
                touchedTopic[key] = true;
            }

            if (key === 'writeAccessGroupOwner' || key === 'readAccessGroupOwner') {
                if (!secondApprover?.value?.groupOwner && value?.groupOwner) {
                    setKafkaSecondApprover({
                        selectedFieldFrom: { module: 'Kafka', subModule: `Topic-${index + 1}` },
                        value: { groupOwner: value?.groupOwner, groupOwnerDetails: value?.groupOwnerDetails },
                    });
                    setApproverChanged(true);
                } else if (secondApprover?.value?.groupOwner && value?.groupOwnerDetails?.name !== secondApprover?.value?.groupOwnerDetails?.name) {
                    setKafkaSecondApprover({
                        selectedFieldFrom: { module: 'Kafka', subModule: `Topic-${index + 1}` },
                        value: { groupOwner: value?.groupOwner, groupOwnerDetails: value?.groupOwnerDetails },
                    });
                    setApproverChanged(true);
                }
            }
            if (key === 'isConsumerGroup') {
                tempKafkaTopic = { ...tempKafkaTopic, isReadAccess: null, readAccessUser: '' };
            }
            if (key === 'isConsumerGroup' && tempKafkaTopic[key] === 'Yes') {
                tempKafkaTopic = { ...tempKafkaTopic, readAccessUser: '', consumerGroups: [{ value: '' }] };
            }

            if (key === 'isTransactionalId' && tempKafkaTopic[key] === 'Yes') {
                tempKafkaTopic = { ...tempKafkaTopic, transactionprefixtext: '', transactionalPrincipalId: '' };
                touchedTopic = { ...touchedTopic, transactionprefixtext: false, transactionalPrincipalId: false };
            }
            if (key === 'isReadAccess') {
                tempKafkaTopic = { ...tempKafkaTopic, readAccessGroupOwner: null, readAccessUser: null };

                if (value === 'No') {
                    validateServiceAccountExist(key);
                    setTopicActiveIndex({ topicIdx: index, topicField: key, fieldStatus: null });
                }
            }

            if (key === 'isWriteAccess') {
                tempKafkaTopic = { ...tempKafkaTopic, writeAccessGroupOwner: null, writeAccessUser: null };
                touchedTopic = {
                    ...touchedTopic,
                    ...(tempKafkaTopic[key] === 'No' && { writeAccessGroupOwner: false }),
                    ...(tempKafkaTopic[key] === 'Yes' && { writeAccessUser: false }),
                };
                tempKafkaTopic = { ...tempKafkaTopic, writeAccessUser: '' };
                if (value === 'No') {
                    setTopicActiveIndex({ topicIdx: index, topicField: key, fieldStatus: null });

                    validateServiceAccountExist();
                    // setTopicActiveIndex({ topicIdx: index, topicField: key });
                }
            }
            if (key === 'writeAccessUser' && value !== null && value !== '') {
                setTopicActiveIndex({ topicIdx: index, topicField: key, fieldStatus: writeAccessStatus?.valid });
                tempKafkaTopic = { ...tempKafkaTopic, writeAccessUserValid: writeAccessStatus?.valid ? 'validated' : false };
            }
            if (key === 'readAccessUser' && value !== null && value !== '') {
                setTopicActiveIndex({ topicIdx: index, topicField: key, fieldStatus: readAccessStatus?.valid });
                tempKafkaTopic = { ...tempKafkaTopic, readAccessUserValid: readAccessStatus?.valid ? 'validated' : false };
            }
            touchedDatatopics[index] = touchedTopic;

            tempTopics[index] = tempKafkaTopic;
            tempFormData = { ...tempFormData, kafkatopics: tempTopics };
            setFormData((prevdata) => ({
                ...prevdata,
                kafkatopics: tempTopics,
            }));
            setTouched((prevdata) => ({
                ...prevdata,
                kafkatopics: touchedDatatopics,
            }));
        }

        const obj = kafkaFieldValidation(key, value, index, fillingFormErrors, formData, writeAccessStatus, readAccessStatus, allTopics);
        setFormFillingErros({ ...obj });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        const kafkatopicLegth = formData?.kafkatopics?.length;
        const kafkatopicObj = {
            topicName: true,
            program: true,
            partitions: true,
            isConsumerGroup: true,
            consumerGroups: true,
            writeAccessUser: true,
            readAccessUser: true,
            isTransactionalId: true,
            transactionalPrincipalId: true,
            transactionprefixtext: true,
            writeAccessGroupOwner: true,
            readAccessGroupOwner: true,
            retention: true,
            isReadAccess: true,
            isWriteAccess: true,
        };

        const emptyObjArray = Array.from({ length: kafkatopicLegth }, () => kafkatopicObj);

        setTouched({
            businessUnit: true,
            ldap: true,
            program: true,
            kafkatopics: [...emptyObjArray],
        });

        const checkFormValid = validateForm();

        if (checkFormValid?.isValid === true && validateKafkaApi?.data?.isSuccess) {
            if (
                (!secondApprover?.value?.groupOwnerDetails?.name && kafkaSecondApprover?.value?.groupOwnerDetails?.name) ||
                (kafkaSecondApprover?.value?.groupOwnerDetails?.name &&
                    secondApprover?.value?.groupOwnerDetails?.name !== kafkaSecondApprover?.value?.groupOwnerDetails?.name)
            ) {
                handleSecondApprover(kafkaSecondApprover);
                toast.custom(
                    <Box
                        sx={{
                            display: 'flex',
                            background: '#FFFFFF',
                            color: (theme) => theme.palette.black,
                            minWidth: '400px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            padding: (theme) => theme.spacing(1),
                        }}
                    >
                        <ReportProblemIcon sx={{ color: '#F99C24', margin: (theme) => theme.spacing(1) }} />
                        {kafkaSecondApprover?.value?.groupOwnerDetails?.name} will be considered as Second Approver
                    </Box>,
                    { duration: 8000 }
                );
            }
        }

        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field, i) => {
        let res = false;
        if (['businessUnit', 'ldap', 'program']?.includes(field)) {
            res = touched[field] && !!errors[field];
        } else if (
            [
                'topicName',
                'partitions',
                'isConsumerGroup',
                'writeAccessUser',
                'readAccessUser',
                'isTransactionalId',
                'transactionalPrincipalId',
                'retention',
                'transactionprefixtext',
                'writeAccessGroupOwner',
                'readAccessGroupOwner',
                'isReadAccess',
                'isWriteAccess',
            ]?.includes(field)
        ) {
            res =
                touched?.kafkatopics?.[i]?.[field] &&
                (!!errors?.kafkatopics?.[i]?.[field]?.showError === true || errors?.kafkatopics?.[i]?.[field]?.type === 'required');
        } else if (field === 'consumerGroups') {
            res = touched?.kafkatopics?.[i]?.[field] && !!errors?.kafkatopics?.[i]?.[field];
        }

        return res;
    };

    const saveButton = () => (
        <Button
            className="custom-btn"
            variant="contained"
            color="cmpPrimary"
            onClick={() => handleSubmit()}
            data-testid="kafka-save-btn"
            sx={{ height: '38px', minWidth: '100px' }}
            disabled={validateKafkaApi?.isLoading || validateKafkaApi?.isError === true}
        >
            Save
        </Button>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        // const touchedEveryField = touched?.kafkatopics?.every(
        //     (topic) => Object.keys(topic).length > 0 && Object.keys(topic)?.every((key) => topic[key] === true)
        // );

        const isValidForm = validateForm();
        if (formData?.kafkatopics?.length > 0) {
            const topics = formData?.kafkatopics?.map((topic) => topic?.topicName);
            setallTopics(topics);
        }

        if (Object.keys(touched).length > 0 && !serviceAccountNoApi?.isLoading) {
            if (isValidForm?.isValid) {
                const payload = getKafkaValidPayload(formData, order);
                validateKafkaApi?.mutate(payload);
            }
        }
    }, [formData, touched, serviceAccountNoApi?.isLoading]);

    const handleConsumerModal = (value, index) => {
        const selectedConsumergrp = formData?.kafkatopics[index]?.consumerGroups;
        setSelectedConsumerGrps(selectedConsumergrp);
        setSelectedtopicIndex(index);
        setShowCosumerModal(true);
    };
    const handlesaveConsumerGrps = (groups) => {
        const groupList = groups?.map((grp) => grp?.value);
        setAllConsumerGroupList(new Set([...allconsumerGrpList, ...groupList]));
        let tempformData = { ...formData };
        const tempKafkaTopic = tempformData?.kafkatopics;
        let tempGrpsData = tempKafkaTopic[selectedtopicIndex]?.consumerGroups;
        tempGrpsData = groups;
        tempKafkaTopic[selectedtopicIndex] = { ...tempKafkaTopic[selectedtopicIndex], consumerGroups: tempGrpsData };
        tempformData = { ...tempformData, kafkatopics: tempKafkaTopic };
        setFormData({ ...tempformData });
        setShowCosumerModal(false);
        setSelectedConsumerGrps([]);
    };

    const handleGroupsCancel = (groups) => {
        let tempformData = { ...formData };
        const tempKafkaTopic = tempformData?.kafkatopics;
        let tempGrpsData = tempKafkaTopic[selectedtopicIndex]?.consumerGroups;
        tempGrpsData = groups;
        tempKafkaTopic[selectedtopicIndex] = { ...tempKafkaTopic[selectedtopicIndex], consumerGroups: tempGrpsData };
        tempformData = { ...tempformData, kafkatopics: tempKafkaTopic };
        setFormData({ ...tempformData });
        setSelectedConsumerGrps([]);
        setShowCosumerModal(false);
    };

    const handleAddTopic = (cloneIdx) => {
        const tempFormData = { ...formData };
        const temptopics = formData?.kafkatopics;
        let selectedTopic = formData?.kafkatopics?.[cloneIdx];
        selectedTopic = {
            ...selectedTopic,
            topicName: null,
            consumerGroups: [{ value: '' }],
        };
        // const topictoAdd = [...temptopics.slice(0, cloneIdx + 1), { ...selectedTopic }, ...temptopics.splice(cloneIdx + 1)];
        temptopics.push(selectedTopic);

        const kafkatopicObj = {
            topicName: '',
            partitions: '',
            isConsumerGroup: '',
            consumerGroups: '',
            writeAccessUser: '',
            readAccessGroupOwner: null,
            writeAccessGroupOwner: null,
            writeAccessUserValid: 'notValidated',
            readAccessUserValid: 'notValidated',
            readAccessUser: '',
            isTransactionalId: '',
            transactionprefixtext: '',
            transactionalPrincipalId: '',
            retention: '',
            isReadAccess: null,
            isWriteAccess: null,
        };

        let tempFormDataError = { ...fillingFormErrors };
        const tempErrortopics = fillingFormErrors?.kafkatopics;
        tempErrortopics.push(kafkatopicObj);
        tempFormDataError = { ...tempFormDataError, kafkatopics: tempErrortopics };
        setFormFillingErros(tempFormDataError);

        const updatedFormData = {
            ...tempFormData,
            kafkatopics: temptopics,
        };

        setFormData({
            ...updatedFormData,
        });
    };
    const handleRemoveTopic = (topicRemoveIdx) => {
        const formdataKafkaTopics = [...formData?.kafkatopics];
        const updatedKafkaTopics = formdataKafkaTopics?.filter((_, idx) => idx !== topicRemoveIdx);
        setFormData({ ...formData, kafkatopics: updatedKafkaTopics });
    };

    const handleAction = (type) => {
        if (type === 'yes') {
            handleRemoveTopic(showTopicDeleteModal?.index);
        }
        setShowTopicDeleteModal({ showModal: false, index: 0 });
    };

    const getSecondaryApprover = () => {
        let res = null;
        if (kafkaSecondApprover?.value?.groupOwnerDetails?.name === null && secondApprover?.value?.groupOwnerDetails?.name) {
            res = secondApprover;
        }
        if (kafkaSecondApprover?.value?.groupOwnerDetails?.name !== secondApprover?.value?.groupOwnerDetails?.name && !approverChanged) {
            res = secondApprover;
        }
        if (kafkaSecondApprover?.value?.groupOwnerDetails?.name !== secondApprover?.value?.groupOwnerDetails?.name && approverChanged) {
            res = kafkaSecondApprover;
        }
        if (
            kafkaSecondApprover?.value?.groupOwnerDetails?.name === null &&
            secondApprover?.value?.groupOwnerDetails?.name === null &&
            (resource?.configureData?.kafkatopics?.[0]?.readAccessGroupOwner || resource?.configureData?.kafkatopics?.[0]?.writeAccessGroupOwner)
        ) {
            res =
                resource?.configureData?.kafkatopics?.[0]?.readAccessGroupOwner?.groupOwnerDetails?.name ??
                resource?.configureData?.kafkatopics?.[0]?.writeAccessGroupOwner?.groupOwnerDetails?.name;
        }
        return res;
    };

    const getCloneStatus = (data, tIdx) => {
        const validtoClone = handleCloneStatus(data, fillingFormErrors, tIdx);
        return !validateKafkaApi?.isSuccess || validtoClone?.isValid;
    };

    return (
        <Box className="ri-layout">
            {!showConsumerModal && (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <BusinessUnit
                            handleOnSelect={(value) => handleOnSelectChange('businessUnit', value, 0)}
                            businessUnitvalue={formData.businessUnit}
                            isRequired={shouldShowError('businessUnit')}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <LdapGroup
                            handleOnSelect={(value) => handleOnSelectChange('ldap', value, 0)}
                            ldapGroupvalue={formData.ldap}
                            isRequired={shouldShowError('ldap')}
                            errors={fillingFormErrors}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Program
                            programValue={formData.program}
                            handleOnSelect={(value) => handleOnSelectChange('program', value, 0)}
                            order={order}
                            formData={formData}
                            setFormData={setFormData}
                            isRequired={shouldShowError('program')}
                            errors={fillingFormErrors}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {formData?.kafkatopics?.map((kafkatopic, index) => (
                            <FormCard title={`Topic - ${index + 1}`}>
                                <Grid container spacing={1.5}>
                                    <Grid item xs={6}>
                                        <TopicName
                                            handleOnSelect={(value) => handleOnSelectChange('topicName', value, index)}
                                            topicNamevalue={kafkatopic?.topicName}
                                            isRequired={shouldShowError('topicName', index)}
                                            errors={fillingFormErrors?.kafkatopics?.[index]?.topicName || errors?.kafkatopics?.[index]?.topicName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Partitions
                                            handleOnSelect={(value) => handleOnSelectChange('partitions', value, index)}
                                            partitionvalue={kafkatopic?.partitions}
                                            isRequired={shouldShowError('partitions', index)}
                                            errors={fillingFormErrors?.kafkatopics?.[index]?.partitions}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ConsumerGroupRadioBtn
                                            handleOnSelect={(value) => handleOnSelectChange('isConsumerGroup', value, index)}
                                            consumerGroupValue={kafkatopic?.isConsumerGroup}
                                        />
                                    </Grid>
                                    {kafkatopic?.isConsumerGroup === 'Yes' && (
                                        <Grid item xs={6}>
                                            <ConsumerGroup
                                                handleOnSelect={(value) => handleConsumerModal(value, index)}
                                                consumerGroupsvalue={kafkatopic?.consumerGroups}
                                                isRequired={shouldShowError('consumerGroups', index)}
                                                errors={fillingFormErrors?.kafkatopics?.[index]}
                                                showConsumerGrpModal={showConsumerModal}
                                                editButton={kafkatopic?.consumerGroups?.every((con) => con?.value !== '')}
                                                showBadge={kafkatopic?.consumerGroups?.every((con) => con?.value === '')}
                                            />
                                        </Grid>
                                    )}
                                    {serviceAccountNoApi?.data?.data.serviceAccountExist === true &&
                                        kafkatopic?.disableWriteServiceAccOwner &&
                                        kafkatopic?.isWriteAccess === 'No' && (
                                            <Grid item xs={12}>
                                                <Alert severity="error" sx={{ mb: 1, ml: 1, mt: 1.5 }}>
                                                    Service Account already exists. Please use different Application Short Name
                                                </Alert>{' '}
                                            </Grid>
                                        )}

                                    <Grid item xs={6}>
                                        <WriteAccessRadioBtn
                                            handleOnSelect={(value) => handleOnSelectChange('isWriteAccess', value, index)}
                                            isWriteAccessValue={kafkatopic?.isWriteAccess}
                                            isRequired={shouldShowError('isWriteAccess', index)}
                                            errors={fillingFormErrors?.kafkatopics?.[index]?.isWriteAccess}
                                        />
                                    </Grid>
                                    {kafkatopic?.isWriteAccess === 'Yes' && (
                                        <Grid item xs={6} sx={{ mt: 1 }}>
                                            <WriteAccessUser
                                                handleOnSelect={(value, filedStatus) => handleOnSelectChange('writeAccessUser', value, index, filedStatus)}
                                                writeAccessUserValue={kafkatopic?.writeAccessUser}
                                                isRequired={shouldShowError('writeAccessUser', index)}
                                                errors={fillingFormErrors?.kafkatopics?.[index]?.writeAccessUser}
                                                writeAccessUserValid={kafkatopic?.writeAccessUserValid}
                                                order={order}
                                            />
                                        </Grid>
                                    )}
                                    {kafkatopic?.isWriteAccess === 'No' && (
                                        <Grid item xs={6} sx={{ mt: 1 }}>
                                            <WriteAccessGroupOwner
                                                isRequired={shouldShowError('writeAccessGroupOwner', index)}
                                                handleOnSelect={(value) => handleOnSelectChange('writeAccessGroupOwner', value, index)}
                                                errors={fillingFormErrors?.kafkatopics?.[index]?.writeAccessGroupOwner}
                                                writeAccessGroupOwnerValue={kafkatopic?.writeAccessGroupOwner}
                                                secondaryApprover={getSecondaryApprover()}
                                                order={order}
                                                approverChanged={approverChanged}
                                                disabledField={kafkatopic?.disableWriteServiceAccOwner}
                                            />
                                        </Grid>
                                    )}

                                    {serviceAccountNoApi?.data?.data.serviceAccountExist === true &&
                                        kafkatopic?.disableReadServiceAccOwner &&
                                        kafkatopic?.isReadAccess === 'No' && (
                                            <Grid item xs={12}>
                                                <Alert severity="error" sx={{ mb: 1, ml: 1, mt: 1.5 }}>
                                                    Service Account already exists. Please use different Application Short Name
                                                </Alert>{' '}
                                            </Grid>
                                        )}
                                    {kafkatopic?.isConsumerGroup === 'Yes' && (
                                        <Grid item xs={6}>
                                            <ReadAccessRadioBtn
                                                handleOnSelect={(value) => handleOnSelectChange('isReadAccess', value, index)}
                                                isReadAccessValue={kafkatopic?.isReadAccess}
                                                isRequired={shouldShowError('isReadAccess', index)}
                                                errors={fillingFormErrors?.kafkatopics?.[index]?.isReadAccess}
                                            />
                                        </Grid>
                                    )}
                                    {kafkatopic?.isConsumerGroup === 'Yes' && kafkatopic?.isReadAccess === 'Yes' && (
                                        <Grid item xs={6} sx={{ mt: 1 }}>
                                            <ReadAccessUser
                                                handleOnSelect={(value, filedStatus) =>
                                                    handleOnSelectChange('readAccessUser', value, index, { valid: null }, filedStatus)
                                                }
                                                readAccessUserValue={kafkatopic?.readAccessUser}
                                                isRequired={shouldShowError('readAccessUser', index)}
                                                errors={fillingFormErrors?.kafkatopics?.[index]?.readAccessUser}
                                                order={order}
                                                readAccessUserValid={kafkatopic?.readAccessUserValid}
                                            />
                                        </Grid>
                                    )}
                                    {kafkatopic?.isConsumerGroup === 'Yes' && kafkatopic?.isReadAccess === 'No' && (
                                        <Grid item xs={6} sx={{ mt: 1 }}>
                                            <ReadAccessGroupOwner
                                                isRequired={shouldShowError('readAccessGroupOwner', index)}
                                                handleOnSelect={(value) => handleOnSelectChange('readAccessGroupOwner', value, index)}
                                                errors={fillingFormErrors?.kafkatopics?.[index]?.readAccessGroupOwner}
                                                readAccessGroupOwnerValue={kafkatopic?.readAccessGroupOwner}
                                                // readAccessADGroupOwnerValueDetails={kafkatopic?.readAccessGroupOwnerDetails}
                                                order={order}
                                                secondaryApprover={getSecondaryApprover()}
                                                disabledField={kafkatopic?.disableReadServiceAccOwner}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={6}>
                                        <TransactionIdRadioBtn
                                            handleOnSelect={(value) => handleOnSelectChange('isTransactionalId', value, index)}
                                            isTransactionalIdValue={kafkatopic?.isTransactionalId}
                                            isRequired={shouldShowError('isTransactionalId', index)}
                                            errors={fillingFormErrors?.kafkatopics?.[index]}
                                        />
                                    </Grid>

                                    {kafkatopic?.isTransactionalId === 'Yes' && (
                                        <>
                                            <Grid item xs={6}>
                                                <TransactionIDPrefix
                                                    sx={{ mt: 1 }}
                                                    handleOnSelect={(value) => handleOnSelectChange('transactionprefixtext', value, index)}
                                                    transactionprefixtextValue={kafkatopic?.transactionprefixtext}
                                                    isRequired={shouldShowError('transactionprefixtext', index)}
                                                    errors={fillingFormErrors?.kafkatopics?.[index]?.transactionprefixtext}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TransactionalPrincipalId
                                                    sx={{ mt: 1 }}
                                                    handleOnSelect={(value) => handleOnSelectChange('transactionalPrincipalId', value, index)}
                                                    TransactionalPrincipalIdvalue={kafkatopic?.transactionalPrincipalId}
                                                    isRequired={shouldShowError('transactionalPrincipalId', index)}
                                                    errors={fillingFormErrors?.kafkatopics?.[index]?.transactionalPrincipalId}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item xs={12}>
                                        <Retention
                                            handleOnSelect={(value) => handleOnSelectChange('retention', value, index)}
                                            retentionvalue={kafkatopic?.retention}
                                            isRequired={shouldShowError('retention', index)}
                                            errors={fillingFormErrors?.kafkatopics?.[index]?.retention}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', float: 'right' }}>
                                            {formData?.kafkatopics?.length < 5 && (
                                                <Btn
                                                    onClick={() => handleAddTopic(index)}
                                                    data-testid="clone-topic-btn"
                                                    variant="contained"
                                                    color="cmpPrimary"
                                                    sx={{ minWidth: '42px', width: 'auto', mx: 1 }}
                                                    className="custom-btn"
                                                    disabled={getCloneStatus(kafkatopic, index)}
                                                >
                                                    Clone Topic
                                                </Btn>
                                            )}
                                            {index !== 0 && formData?.kafkatopics?.length > 1 && (
                                                <Box sx={ProvisioningStyles.consumerGroupModal.deleteWrapper}>
                                                    <FaTrashAlt
                                                        style={ProvisioningStyles.consumerGroupModal.delete}
                                                        onClick={() => setShowTopicDeleteModal({ showModal: true, index })}
                                                        className="w-7 h-7 text-red-base cursor-pointer"
                                                        data-testid="delete-topic-btn"
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </FormCard>
                        ))}
                    </Grid>
                </Grid>
            )}
            {showConsumerModal && (
                <ConsumerGroupList
                    consumerGroups={selectedConsumerGrps}
                    handleSave={handlesaveConsumerGrps}
                    allconsumerGrpList={allconsumerGrpList}
                    errors={errors}
                    handleGroupsCancel={handleGroupsCancel}
                />
            )}
            {!showConsumerModal && (
                <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                    {saveButton()}
                    {cancelButton()}
                </Box>
            )}

            {showTopicDeleteModal && (
                <ConfirmDialog
                    isModalOpen={showTopicDeleteModal?.showModal}
                    title="Are you sure you want to remove Topic?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                    isCompact
                />
            )}
        </Box>
    );
};

KAFKA.propTypes = {
    resource: PropTypes.any,
    handleDrawerAction: PropTypes.func.isRequired,
    order: PropTypes.any,
    secondApprover: PropTypes.any,
    handleSecondApprover: PropTypes?.func,
};

KAFKA.defaultProps = {
    resource: null,
    order: {},
    secondApprover: null,
    handleSecondApprover: () => {},
};

export default KAFKA;
