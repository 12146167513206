export const getInternalAppGatewaySummary = (item) => {
    return {
        'Application Service': item.catalogConfig?.applicationService,
        'Application Name': item?.catalogConfig?.applicationName,
        'Application Owner ID': item?.catalogConfig?.applicationOwnerId,
        Region: item?.catalogConfig?.region,
        Environment: item?.catalogConfig?.environment,
        Program: item?.catalogConfig?.program || '-',
        'Budget Code': item?.catalogConfig?.budgetCode,
        'AD Group': item?.catalogConfig?.adGroup,
        'Application Support Mail ID': item?.catalogConfig?.appSupportMailId,
        'Application URL': item?.catalogConfig?.applicationUrl,
        'SSL Encryption': item?.catalogConfig?.sslEncryption === true ? 'Yes' : 'No',
        SupportBackendCertificate: item?.catalogConfig?.supportBackendCertificate,
        'Back End Health Probe Path': item?.catalogConfig?.backendHealthProbePath,
        'Backend FQDN': item?.catalogConfig?.backendFqdn?.map((fqdn) => fqdn).join(' , '),
        ...(item?.catalogConfig?.certificateOwnerOne && {
            'Certificate Owner 1': item?.catalogConfig?.certificateOwnerOne,
        }),
        ...(item?.catalogConfig?.certificateOwnerTwo && {
            'Certificate Owner 2': item?.catalogConfig?.certificateOwnerTwo,
        }),
        'Data Classification': item?.catalogConfig?.dataClassification,
        'CSA Request Number': item?.catalogConfig?.csaRequestNumber,
    };
};
export default {};
