import React from 'react';
import { Table, TableBody } from '@mui/material';
import PropTypes from 'prop-types';
import summaryStyle, { BoxWrapper, MembersStyledTableCell, MembersStyledTableRow } from 'components/order-summary-details/style';

const MembersRequested = ({ membersDetails }) => {
    return (
        <BoxWrapper>
            <Table sx={summaryStyle.financetable} aria-label="simple table">
                <TableBody>
                    {membersDetails?.map((row, idx) => (
                        <MembersStyledTableRow key={`finacetable${idx}`}>
                            <MembersStyledTableCell scope="row" width="15%">
                                {row?.gpid}
                            </MembersStyledTableCell>
                            <MembersStyledTableCell scope="row" width={`${row?.email ? '25%' : '45%'}`}>
                                {row?.name}
                            </MembersStyledTableCell>
                            {!row?.safeName && (
                                <MembersStyledTableCell scope="row" width="35%" sx={{ wordBreak: 'break-all' }}>
                                    {row?.email === 'null' ? '' : row?.email}
                                </MembersStyledTableCell>
                            )}
                            {row?.requestNumber && (
                                <MembersStyledTableCell scope="row" width="25%">
                                    {row?.requestNumber === 'null' ? '' : row?.requestNumber}
                                </MembersStyledTableCell>
                            )}
                            {row?.safeName && (
                                <MembersStyledTableCell scope="row" width="25%">
                                    {row?.requestNumber === 'null' ? '' : row?.safeName}
                                </MembersStyledTableCell>
                            )}
                        </MembersStyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </BoxWrapper>
    );
};

MembersRequested.propTypes = {
    membersDetails: PropTypes.array,
};

MembersRequested.defaultProps = {
    membersDetails: [],
};
export default React.memo(MembersRequested);
