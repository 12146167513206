import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getLoggedUserGPID, getLoggedUserFullName } from 'utils';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { LoggedUserEmailId } from '../utils/index';
import AccessToken from '../components/accessToken/accessToken';
import { ProgressIcon } from '../style';

const EditExisitingProject = () => {
    const { getAccessToken } = AccessToken();
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { projectDetails } = location.state || {};

    useEffect(() => {
        if (projectDetails) {
            // Pre-fetch project name and description
            setProjectName(projectDetails[0]);
            setProjectDescription(projectDetails[1]);
        }
    }, [projectDetails]);

    // eslint-disable-next-line consistent-return
    const handleSaveProject = async () => {
        const editProject = {
            project_name: projectName,
            project_desc: projectDescription,
            project_updatedby_gpid: getLoggedUserGPID(),
            project_updatedby_user_details: {
                name: getLoggedUserFullName(),
                mail_address: LoggedUserEmailId(),
            },
        };
        setLoading(true);
        try {
            const response = await axios.put(endPoints.editExistingProject, editProject, {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response && response.data.status === 'updated') {
                toast.success('Project updated successfully!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    history.push('/dlops-projects');
                }, 2000);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            return err;
        }
    };

    const handleCancel = () => {
        history.push('/dlops-projects');
        setProjectName('');
        setProjectDescription('');
        setError('');
    };
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                <Typography variant="body2" sx={ProgressIcon.circularProgressColor} style={{ marginLeft: '10px' }}>
                    Loading
                </Typography>
            </Box>
        );
    }
    return (
        <Box
            sx={{
                top: '164px',
                left: '27px',
                width: '1500px',
                height: '400px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '4px',
                opacity: 1,
                marginTop: '20px',
                padding: '16px',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '100px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <TextField
                    variant="filled"
                    value={projectName}
                    onChange={(e) => {
                        setProjectName(e.target.value);
                        if (error) setError('');
                    }}
                    label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>PROJECT NAME</Typography>
                            <Typography sx={{ color: '#FF0000', marginLeft: '2px' }}>*</Typography>
                        </Box>
                    }
                    sx={{
                        marginBottom: '20px',
                        '& .MuiFilledInput-root': {
                            backgroundColor: '#F2F2F2',
                        },
                        '& .MuiInputLabel-root': {
                            color: '#000000',
                            fontSize: '15px',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#888888',
                        },
                        width: '762px',
                        height: '55px',
                        fontSize: '16px',
                        fontWeight: 'medium',
                    }}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                {error && <Typography sx={{ color: '#FF0000', fontSize: '14px', marginBottom: '10px' }}>{error}</Typography>}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginBottom: '20px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography sx={{ fontSize: '14px', fontWeight: 'medium', color: '#000000' }}>Project Description</Typography>
                        <Typography
                            sx={{
                                marginLeft: '10px',
                                color: '#1378D4',
                                fontSize: '13px',
                                alignSelf: 'flex-start',
                            }}
                        >
                            Optional
                        </Typography>
                    </Box>
                    <TextField
                        variant="filled"
                        fullWidth
                        multiline
                        rows={4}
                        value={projectDescription}
                        onChange={(e) => {
                            setProjectDescription(e.target.value);
                            if (error) setError('');
                        }}
                        sx={{
                            marginTop: '8px',
                            '& .MuiFilledInput-root': {
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#000000',
                                fontSize: '12px',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#888888',
                            },
                            width: '762px',
                            border: '1px solid #1378D4',
                            borderRadius: '4px',
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Button variant="contained" sx={{ backgroundColor: '#1378D4', marginTop: '50px' }} onClick={handleSaveProject}>
                        Update
                    </Button>
                    <Button variant="outlined" sx={{ marginTop: '50px' }} onClick={handleCancel}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default EditExisitingProject;
