import React from 'react';
import { FormControl, FormControlLabel, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';
import riStyle, { CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';

const IsExternalRadio = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const handleOnSelectChange = (isExternal) => {
        setOrder({
            ...order,
            isExternal,
            appUrl: '',
            appSupportMailId: '',
            certificateOwnerOne: null,
            certificateOwnerOneDetails: null,
            certificateOwnerTwo: null,
            certificateOwnerTwoDetails: null,
        });
    };
    return (
        <>
            <Typography sx={riStyle?.form?.accessMgmt?.label}>
                Would you like an environment for web application development or microservice development? <span style={{ color: 'red' }}>*</span>
            </Typography>
            <FormControl data-testid="is-external-radio-box">
                <CustomRadioGroup
                    aria-label="IsExternalRadio"
                    name="isExternalRadioBtnGroup"
                    row
                    value={order?.isExternal || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Yes" control={<CustomRadio />} label="Web Application" />
                    <FormControlLabel
                        value="No"
                        sx={{
                            marginLeft: '60px',
                        }}
                        control={<CustomRadio />}
                        label="Microservice"
                    />
                </CustomRadioGroup>
            </FormControl>
        </>
    );
};

export default IsExternalRadio;
