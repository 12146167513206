import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Typography, Grid, Box } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Btn from 'components/button';
import { cartStyle } from 'pages/cart/style';
import { deleteCartItemId, deleteModalPopup, deleteByApplication, toggleCostModals, catalogDetails } from 'pages/cart/store';
import { linuxVM, sqlPaaS, windowsVM, oelVM, oracleIaaS, sqlIaaS, oelOracle, storageBlog, aksNS, titleCase, alb } from 'utils';
import {
    keyvaultCatalogDefinitionId,
    kafkaCatalogDefinitionId,
    eventhubDefinitionId,
    pECatalogDefinitionId,
    linuxSLESCatalogDefinitionId,
    eipStackCatalogDefinitionId,
    adfCatalogDefinitionId,
    couchbaseDbDefinitionId,
    linuxOELCatalogDefinitionId,
    SlesPgtCatalogDefinitionId,
    SlesPgtDBCatalogDefinitionId,
} from 'utils/catalogDefinitionId';
import { getOrderSummary } from 'utils/OrderSummary/getOrderSummary';

const AppAccordion = ({ data, defaultExpanded }) => {
    const history = useHistory();
    const [, setIsDeleteModal] = useRecoilState(deleteModalPopup);
    const [, setDeleteCartItemId] = useRecoilState(deleteCartItemId);
    const [, setDeleteCartByApplication] = useRecoilState(deleteByApplication);
    const [expandAccordian, setExpandccordian] = React.useState(false);
    const [modal, setModal] = useRecoilState(toggleCostModals);
    const [, setCatalogDetails] = useRecoilState(catalogDetails);
    const cartItemApi = useMutation((cartItemId) => axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`));

    const handleRedirection = ({ catalogServiceCode, cartItemId }) => {
        if (windowsVM.includes(catalogServiceCode)) {
            history.push(`/vm-provisioning/Windows/WindowsServer/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (linuxVM.includes(catalogServiceCode) && !oelVM.includes(catalogServiceCode) && !linuxSLESCatalogDefinitionId.includes(catalogServiceCode)) {
            history.push(`/vm-provisioning/Linux/RHEL/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (linuxOELCatalogDefinitionId.includes(catalogServiceCode)) {
            history.push(`/vm-provisioning/Linux/OEL/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if ([...linuxSLESCatalogDefinitionId, ...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId].includes(catalogServiceCode)) {
            history.push(`/vm-provisioning/Linux/SLES/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (sqlPaaS.includes(catalogServiceCode)) {
            history.push(`/sql-paas-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (oracleIaaS.includes(catalogServiceCode) && !oelOracle.includes(catalogServiceCode)) {
            history.push(`/oracle-provisioning/Linux/RHEL/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (oelOracle.includes(catalogServiceCode)) {
            history.push(`/oracle-provisioning/Linux/OEL/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (sqlIaaS.includes(catalogServiceCode)) {
            history.push(`/iaas-sql-provisioning/Windows/WindowsServer/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (storageBlog.includes(catalogServiceCode)) {
            history.push(`/azure-storage-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (aksNS.includes(catalogServiceCode)) {
            history.push(`/aks-nms-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (keyvaultCatalogDefinitionId.includes(catalogServiceCode)) {
            history.push(`/azure-key-vault/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (alb.includes(catalogServiceCode)) {
            history.push(`/azure-load-balancer/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (kafkaCatalogDefinitionId.includes(catalogServiceCode)) {
            history.push(`/kafka-topic-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (eventhubDefinitionId.includes(catalogServiceCode)) {
            history.push(`/event-hub-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (pECatalogDefinitionId.includes(catalogServiceCode)) {
            history.push(`/private-endpoint-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (eipStackCatalogDefinitionId.includes(catalogServiceCode)) {
            history.push(`/app-microservices-stack/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (adfCatalogDefinitionId.includes(catalogServiceCode)) {
            history.push(`/azure-data-factory-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
        if (couchbaseDbDefinitionId.includes(catalogServiceCode)) {
            history.push(`/couchbase-provisioning/${catalogServiceCode}/edit/${cartItemId}`);
        }
    };
    React.useEffect(() => {
        if (cartItemApi?.isSuccess) {
            if (!eipStackCatalogDefinitionId?.includes(cartItemApi?.data?.data?.itemDetail?.catalogService?.catalogServiceCode)) {
                setCatalogDetails(
                    getOrderSummary({
                        orders: { ...cartItemApi?.data?.data?.itemDetail, catalogServices: [{ ...cartItemApi?.data?.data?.itemDetail?.catalogService }] },
                    })?.[0]
                );
            } else {
                setCatalogDetails({ stackCatalogs: cartItemApi?.data?.data?.itemDetail });
            }
            setModal({ ...modal, isCatalogDetails: { id: cartItemApi?.data?.data?.cartId, isDetailsOpen: true, isDetailsLoading: false } });
        }
    }, [cartItemApi?.isSuccess]);

    const handleDelete = async ({ cartItemId }) => {
        await setDeleteCartItemId(cartItemId);
        await setIsDeleteModal(true);
    };
    const handleAppdetailsModal = (cartid) => {
        setModal({ ...modal, isCatalogDetails: { id: cartid, isDetailsOpen: true, isDetailsLoading: true } });
        cartItemApi.mutate(cartid);
    };
    return (
        <>
            <Accordion defaultExpanded={defaultExpanded} disableGutters sx={cartStyle?.container?.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={cartStyle.appAccordian.expandIcon} />} sx={cartStyle.appAccordian.containersummary}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={cartStyle.appAccordian.stack}>
                        <Typography sx={cartStyle.appAccordian.application}>{titleCase(data?.application)}</Typography>

                        <Btn
                            sx={cartStyle.appAccordian.remove}
                            onClick={(e) => {
                                e.stopPropagation();
                                setDeleteCartByApplication(data?.businessCi);
                                setIsDeleteModal(true);
                            }}
                        >
                            Remove All
                        </Btn>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={cartStyle.appAccordian.containerAccDetails}>
                    <Accordion expanded>
                        <AccordionSummary sx={cartStyle.appAccordian.tableAccorSummary} className="detailssummary">
                            <Grid container sx={cartStyle.appAccordian.gridcontainer} spacing={1.5}>
                                <Grid item md={2.3} lg={2.8} sx={cartStyle.appAccordian.tableheader}>
                                    Catalog Type
                                </Grid>
                                <Grid item sx={cartStyle.appAccordian.tableheader} md={1.5} lg={1.8}>
                                    Environment
                                </Grid>
                                <Grid item md={0.5} lg={0.8} sx={cartStyle.appAccordian.tableheader}>
                                    Qty
                                </Grid>
                                <Grid item md={1.9} lg={1.9} sx={cartStyle.appAccordian.tableheader}>
                                    Recurring Cost
                                </Grid>
                                <Grid item md={1.8} lg={1.8} sx={cartStyle.appAccordian.tableheader}>
                                    One Time Cost
                                </Grid>
                                <Grid item md={1.5} lg={1.7} sx={cartStyle.appAccordian.thCostCenter}>
                                    Cost Center
                                </Grid>
                                <Grid item md={1.2} lg={1.2} sx={cartStyle.appAccordian.tableheader}>
                                    Actions
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: 0 }}>
                            {data?.items?.map((order, index) => (
                                <Accordion sx={{ padding: 0 }} expanded={expandAccordian} key={`accordion-${index}`}>
                                    <AccordionSummary
                                        sx={{ padding: 0 }}
                                        onClick={() => {
                                            setExpandccordian(false);
                                        }}
                                    >
                                        <Grid container sx={{ paddingLeft: { md: 1.5, lg: 3.5, xl: 3.6 } }} spacing={0.5}>
                                            <Grid
                                                item
                                                md={2.4}
                                                lg={2.8}
                                                xl={2.8}
                                                sx={cartStyle.appAccordian.catalogType}
                                                onClick={() => handleAppdetailsModal(order?.itemId)}
                                                data-testid={`details-modal-${order?.itemId}`}
                                            >
                                                {order.catalogType}
                                            </Grid>
                                            <Grid item sx={cartStyle.appAccordian.tableBodyVal} md={1.7} lg={1.7} xl={1.7}>
                                                {order.environment}
                                            </Grid>
                                            <Grid item md={0.8} lg={0.8} xl={0.9} sx={cartStyle.appAccordian.tableBodyVal}>
                                                {order.quantity}
                                            </Grid>
                                            <Grid item md={2} lg={1.9} xl={1.9} sx={cartStyle.appAccordian.tableBodyVal}>
                                                {`$${order.estimatedCosts?.totalRecurringCost?.estCost?.toFixed(2)}`}
                                            </Grid>
                                            <Grid item md={2.1} lg={1.8} xl={1.8} sx={cartStyle.appAccordian.tableBodyVal}>
                                                {`$${order.estimatedCosts?.totalOneTimeCost?.estCost?.toFixed(2)}`}
                                            </Grid>
                                            <Grid item md={1.5} lg={1.5} xl={1.5} sx={cartStyle.appAccordian.tableBodyVal}>
                                                {order.costCenter}
                                            </Grid>
                                            <Grid item md={1.5} lg={1.4} xl={1.3} sx={cartStyle.appAccordian.tableBodyVal}>
                                                <Stack direction="row" spacing={0.5} sx={cartStyle.appAccordian.actionsWrapper}>
                                                    <Box
                                                        sx={cartStyle.container.table.iconbtn}
                                                        onClick={() =>
                                                            handleRedirection({ catalogServiceCode: order?.catalogServiceCode, cartItemId: order?.itemId })
                                                        }
                                                        data-testid={`edit-${order?.itemId}`}
                                                    >
                                                        <FaEdit style={cartStyle.container.table.editbtn} />
                                                    </Box>
                                                    <Box
                                                        sx={cartStyle.container.table.deleteBtn}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDelete({ cartItemId: order?.itemId });
                                                        }}
                                                        data-testid={`delete-${order?.itemId}`}
                                                    >
                                                        <FaTrashAlt style={cartStyle.container.table.rejectBtn} />
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

AppAccordion.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]).isRequired,
    defaultExpanded: PropTypes.bool,
};

AppAccordion.defaultProps = {
    defaultExpanded: false,
};

export default React.memo(AppAccordion);
