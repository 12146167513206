import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack, Box, Grid, Button, Typography } from '@mui/material';
import Btn from 'components/button';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import { CssTextField } from 'components/select';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoImg from 'components/info-img';
import { orderPayload, validationStatus, validVSForm, updateValidationError } from 'pages/provisioning/virtual-service/store';
import { validateCustomDomain } from 'pages/provisioning/virtual-service/utils';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import useDebounce from 'pages/provisioning/virtual-service/hooks/use-debounce';

export const VirtualServicesDetails = ({ callCostApi }) => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [maxLimitReachedError, setMaxLimitReachedError] = useState(false);
    const [validCustomDomainName, setValidCustomDomainName] = useState('');
    const [validCustomDomainNameValue, setValidCustomDomainNameValue] = useState('');
    const [validationErrorStatus] = useRecoilState(validationStatus);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [vsUpdateIndex, setVSUpdateIndex] = useState(0);
    const [, setValidationError] = useRecoilState(updateValidationError);

    const certificateValidatationApi = useMutation((payload) => axios.post(`${endPoints.virtualService.certificateValidation}`, payload));
    const debouncedCustomDomainValue = useDebounce(validCustomDomainName, 500);
    const customDomainDebouncedValue = useDebounce(validCustomDomainNameValue, 500);

    useEffect(() => {
        if (order?.aksVirtualServiceUpdateCustomDomain?.length < 10) {
            setMaxLimitReachedError(false);
        }
    }, [order?.aksVirtualServiceUpdateCustomDomain]);

    useEffect(() => {
        if (certificateValidatationApi?.isSuccess) {
            if (certificateValidatationApi?.data?.data?.customDomain?.[0]?.certificateExist) {
                setOrder((prevOrder) => ({
                    ...prevOrder,
                    aksVirtualServiceUpdateCustomDomain: prevOrder?.aksVirtualServiceUpdateCustomDomain.map((item, index) => {
                        if (index === vsUpdateIndex) {
                            return {
                                ...item,
                                certificateExists: true,
                            };
                        }
                        return item;
                    }),
                }));
            } else {
                setOrder((prevOrder) => ({
                    ...prevOrder,
                    aksVirtualServiceUpdateCustomDomain: prevOrder?.aksVirtualServiceUpdateCustomDomain.map((item, index) => {
                        if (index === vsUpdateIndex) {
                            return {
                                ...item,
                                certificateExists: false,
                            };
                        }
                        return item;
                    }),
                }));
            }
        }
    }, [certificateValidatationApi?.isSuccess]);

    useEffect(() => {
        if (debouncedCustomDomainValue) {
            const payload = {
                customDomain: [
                    {
                        name: debouncedCustomDomainValue,
                    },
                ],
            };
            certificateValidatationApi?.mutate(payload);
        }
    }, [debouncedCustomDomainValue]);

    useEffect(() => {
        const customNameWithError = order?.aksVirtualServiceUpdateCustomDomain?.some((item) => item?.customNameErrorMsg && item?.customNameErrorMsg !== '');

        if (customDomainDebouncedValue || customNameWithError) {
            callCostApi(order?.aksVirtualServiceUpdateCustomDomain);
        }
    }, [customDomainDebouncedValue]);

    const handleAddVS = () => {
        if (order?.aksVirtualServiceUpdateCustomDomain?.length >= 10) {
            setMaxLimitReachedError(true);
        } else {
            setOrder((prevOrder) => ({
                ...prevOrder,
                aksVirtualServiceUpdateCustomDomain: order?.aksVirtualServiceUpdateCustomDomain && [
                    ...order?.aksVirtualServiceUpdateCustomDomain,
                    { aksIngressUrl: '', name: '', certificateExists: true },
                ],
            }));
        }
    };
    const handleRemoveVS = (actionIndex) => {
        const tempCustomDomainArray = [...order?.aksVirtualServiceUpdateCustomDomain];
        tempCustomDomainArray.splice(actionIndex, 1);
        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualServiceUpdateCustomDomain: tempCustomDomainArray,
        }));
        callCostApi(tempCustomDomainArray);
    };
    const wrapToolTipContentInBox = (title) => {
        return (
            <>
                <Box className={ProvisioningStyles.infoStyle.wrapper}>
                    <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                        <Grid container spacing={1} padding="5px">
                            <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                    <span style={ProvisioningStyles.infoStyle.headingStyle}>Example : &nbsp;</span>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        );
    };

    const checkCustomNameIsValid = (customNameToCheck) => {
        const { isValid, message } = validateCustomDomain(customNameToCheck, order?.environment);
        let errorMsg = '';
        if (customNameToCheck && customNameToCheck !== null && customNameToCheck !== '' && customNameToCheck !== undefined && !isValid) {
            errorMsg = message;
            setValidForm({ ...validForm, isInvalidCustomDomainName: true });
        } else if (customNameToCheck === null || customNameToCheck === '' || customNameToCheck === undefined) {
            errorMsg = message;
            setValidForm({ ...validForm, isInvalidCustomDomainName: true });
        } else if (customNameToCheck && isValid) {
            errorMsg = '';
            setValidForm({ ...validForm, isInvalidCustomDomainName: false });
        }

        return errorMsg;
    };
    const checkDuplicateCustomDomain = (customNameToCheck) => {
        const customDomainNameList = order?.aksVirtualServiceUpdateCustomDomain?.map((item) => item?.name);
        const duplicateCustomDomainName = customDomainNameList?.filter((item, index) => customDomainNameList?.indexOf(item) !== index);

        if (customNameToCheck) {
            return duplicateCustomDomainName?.indexOf(customNameToCheck) > -1;
        }
        return false;
    };

    const checkDuplicateAksUrl = (aksUrlToCheck) => {
        const aksUrlList = order?.aksVirtualServiceUpdateCustomDomain?.map((item) => item?.aksIngressUrl);
        const duplicateAksUrl = aksUrlList?.filter((item, index) => aksUrlList?.indexOf(item) !== index);

        if (aksUrlToCheck) {
            return duplicateAksUrl?.indexOf(aksUrlToCheck) > -1;
        }
        return false;
    };

    const checkAksIngressUrlIsValid = (urlToCheck) => {
        let errorMsg = '';
        const regex = new RegExp(`^[a-zA-Z0-9-._]{1,}[.]${order?.istioGatewayUrl}$`);
        setValidForm({ ...validForm, isInvalidIngressUrl: false });

        if (!regex.test(urlToCheck)) {
            errorMsg = `It should not start with http:// or https:// and should end with .${order?.istioGatewayUrl}`;
            setValidForm({ ...validForm, isInvalidIngressUrl: true });
        }

        return errorMsg;
    };

    const validateInput = (fieldName, fieldIndex) => {
        if (order?.isTouched && !order?.aksVirtualServiceUpdateCustomDomain[fieldIndex][fieldName]) {
            return true;
        }
        return false;
    };

    const handleValueChange = (actionIndex, fieldName, fieldValue) => {
        setVSUpdateIndex(actionIndex);
        let customNameErrorMsg = '';
        let aksUrlErrorMsg = '';
        if (fieldName === 'aksIngressUrl') {
            aksUrlErrorMsg = checkAksIngressUrlIsValid(fieldValue);
        }
        if (fieldName === 'name') {
            customNameErrorMsg = checkCustomNameIsValid(fieldValue);
        }

        const tempVSDetailsArray = [...order?.aksVirtualServiceUpdateCustomDomain];
        tempVSDetailsArray[actionIndex] = {
            ...tempVSDetailsArray[actionIndex],
            [fieldName]: fieldValue,
            ...(fieldName === 'aksIngressUrl' ? { aksUrlErrorMsg } : {}),
            ...(fieldName === 'name' ? { customNameErrorMsg } : {}),
        };

        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualServiceUpdateCustomDomain: tempVSDetailsArray,
        }));

        if (fieldName === 'name' && customNameErrorMsg === '') {
            setValidCustomDomainName(fieldValue);
        }

        if (fieldName === 'name' && (customNameErrorMsg === '' || fieldValue.length < 1)) {
            setValidCustomDomainNameValue(fieldValue);
        }
    };

    const getVSValidationStatus = (fieldName, fieldValue) => {
        const failedCustomDomainNames = [];
        if (validationErrorStatus?.length > 0) {
            validationErrorStatus?.filter((error) => {
                return error.customDomain?.map((item) => {
                    const errorMsgArr = item.msg.filter((str) =>
                        str
                            .toLowerCase()
                            .replace(/\s/g, '')
                            .includes(fieldName === 'name' ? 'customdomainname' : 'aksingressurl')
                    );
                    if (item[fieldName] === fieldValue && errorMsgArr.length > 0) {
                        const errorItem = {
                            ...item,
                            msg: errorMsgArr,
                        };
                        failedCustomDomainNames.push(errorItem);
                    }
                    return null;
                });
            });
            return failedCustomDomainNames;
        }
        return false;
    };

    const getErrorMessage = (fieldName, fieldValue, helperText) => {
        const validationError = getVSValidationStatus(fieldName, fieldValue);

        if (!helperText && validationError?.length > 0) {
            return (
                <>
                    {validationError?.map((error, errorIndex) => (
                        <React.Fragment key={`error-${errorIndex}`}>
                            {error?.msg?.map((msg, index) => (
                                <p key={index}>{msg}</p>
                            ))}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return '';
    };

    React.useEffect(() => {
        if (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && validationErrorStatus?.length > 0) {
            const errorLogs = [];
            const resultArray = order?.aksVirtualServiceUpdateCustomDomain?.flatMap((z) => [z.name, z.aksIngressUrl]);

            validationErrorStatus?.forEach((error) => {
                if (error?.customDomain?.length > 0) {
                    error?.customDomain?.forEach((domain) => {
                        const errorMsg = domain?.msg?.map((str) => str?.toLowerCase()?.replace(/\s/g, '')).toString();
                        if (resultArray.includes(domain?.name) && errorMsg.includes('customdomainname')) {
                            if (errorMsg.includes('customdomainname')) {
                                errorLogs.push(true);
                            }
                        }
                        if (resultArray.includes(domain?.aksIngressUrl) && errorMsg.includes('aksingressurl')) {
                            errorLogs.push(true);
                        }
                    });
                }
            });
            setValidationError(errorLogs.some((value) => value === true));
        }
    }, [validationErrorStatus, order?.aksVirtualServiceUpdateCustomDomain]);

    return (
        <>
            <Stack direction="column" spacing={2}>
                {order?.aksVirtualServiceUpdateCustomDomain?.map((vsData, vsIndex) => {
                    return (
                        <Box
                            display="flex"
                            gap={2}
                            sx={ProvisioningStyles?.vsDetails?.boxWrapper}
                            key={`vs-detail-${vsIndex}`}
                            data-testid={`vs-detail-${vsIndex}`}
                        >
                            <Grid
                                item
                                md={order?.aksVirtualServiceUpdateCustomDomain?.length > 1 ? 5.5 : 6}
                                gap={1}
                                sm={10}
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <CssTextField
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    label="AKS Ingress URL"
                                    placeholder="Type your AKS URL"
                                    onChange={(e) => handleValueChange(vsIndex, 'aksIngressUrl', e?.target?.value)}
                                    value={vsData?.aksIngressUrl ?? ''}
                                    error={
                                        (vsData?.aksUrlErrorMsg && vsData?.aksUrlErrorMsg !== '') ||
                                        getErrorMessage('aksIngressUrl', vsData?.aksIngressUrl, vsData?.aksUrlErrorMsg) ||
                                        checkDuplicateAksUrl(vsData?.aksIngressUrl)
                                    }
                                    helperText={
                                        vsData?.aksUrlErrorMsg ||
                                        getErrorMessage('aksIngressUrl', vsData?.aksIngressUrl, vsData?.aksUrlErrorMsg) ||
                                        (checkDuplicateAksUrl(vsData?.aksIngressUrl) && 'AKS URL already exists, please use unique one.')
                                    }
                                    className={
                                        (vsData?.aksUrlErrorMsg && vsData?.aksUrlErrorMsg !== '') ||
                                        getErrorMessage('aksIngressUrl', vsData?.aksIngressUrl, vsData?.aksUrlErrorMsg) ||
                                        checkDuplicateAksUrl(vsData?.aksIngressUrl) ||
                                        validateInput('aksIngressUrl', vsIndex)
                                            ? 'validate'
                                            : ''
                                    }
                                    data-testid={`vs-detail-${vsIndex}-aksUrl`}
                                />
                                <InfoImg
                                    title={wrapToolTipContentInBox(`pep-cmp-cmp-certa-dev-cv-webdeploy1-dev.${order?.istioGatewayUrl}`)}
                                    className="tooltip-info-icon"
                                />
                            </Grid>
                            <Grid
                                item
                                md={order?.aksVirtualServiceUpdateCustomDomain?.length > 1 ? 5.5 : 6}
                                sm={10}
                                xs={12}
                                gap={1}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <CssTextField
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    placeholder="Type your custom domain host name"
                                    label="Custom Domain Host Name"
                                    onChange={(e) => handleValueChange(vsIndex, 'name', e?.target?.value)}
                                    value={vsData?.name ?? ''}
                                    error={
                                        (vsData?.customNameErrorMsg && vsData?.customNameErrorMsg !== '') ||
                                        getErrorMessage('name', vsData?.name, vsData?.customNameErrorMsg) ||
                                        checkDuplicateCustomDomain(vsData?.name)
                                    }
                                    helperText={
                                        vsData?.customNameErrorMsg ||
                                        getErrorMessage('name', vsData?.name, vsData?.customNameErrorMsg) ||
                                        (checkDuplicateCustomDomain(vsData?.name) && 'Custom domain name already exists, please use unique one.')
                                    }
                                    className={
                                        (vsData?.customNameErrorMsg && vsData?.customNameErrorMsg !== '') ||
                                        getErrorMessage('name', vsData?.name, vsData?.customNameErrorMsg) ||
                                        checkDuplicateCustomDomain(vsData?.name) ||
                                        validateInput('name', vsIndex)
                                            ? 'validate'
                                            : ''
                                    }
                                    data-testid={`vs-detail-${vsIndex}-name`}
                                />
                                <InfoImg title={wrapToolTipContentInBox('cmp.mypepsico.com')} className="tooltip-info-icon" />
                            </Grid>
                            {order?.aksVirtualServiceUpdateCustomDomain?.length > 1 && (
                                <Grid item md={1} sm={1} xs={1} sx={{ marginTop: '2px' }}>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleRemoveVS(vsIndex)}
                                        disabled={false}
                                        sx={ProvisioningStyles?.project?.vsIcon}
                                        data-testid={`vs-details-remove-item-${vsIndex}-btn`}
                                    >
                                        <DeleteIcon sx={{ fontSize: '25px' }} />
                                    </Button>
                                </Grid>
                            )}
                        </Box>
                    );
                })}
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <div>
                    {maxLimitReachedError && (
                        <Typography data-testid="vs-details-max-limit-reached-error-txt" variant="subtitle2" sx={{ fontSize: '14x' }} color="#FF0000">
                            You have reached max limit, you can add upto 10 Virtual Services.
                        </Typography>
                    )}
                </div>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Btn variant="contained" onClick={() => handleAddVS()} disabled={false} data-testid="vs-details-add-btn" justifyContent="flex-end">
                        Add More
                    </Btn>
                </Stack>
            </Stack>
        </>
    );
};

VirtualServicesDetails.propTypes = {
    callCostApi: PropTypes.func.isRequired,
};

export default React.memo(VirtualServicesDetails);
