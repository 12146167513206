import React from 'react';
import { CircularProgress } from '@mui/material';
import ProvisioningStyles from 'pages/myidm/style';
import { OrderPayload, toggleSspModels, selectedMembersList } from 'pages/myidm/store';
import { getSelectMemberBtnStatus } from 'pages/myidm/utils';
import Btn from 'components/button';
import { useRecoilState, useRecoilValue } from 'recoil';

const SelectMemberBtn = () => {
    const [isLoading, setLoading] = React.useState(false);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [selectedMemList, setSelectedMemList] = useRecoilState(selectedMembersList);
    const order = useRecoilValue(OrderPayload);
    const handleOpenMemlistModal = () => {
        if (order?.members?.length !== selectedMemList?.length) {
            setSelectedMemList(order?.members);
        }
        setLoading(true);
        setModal({ ...modal, isMemberlist: true });

        setLoading(false);
    };
    const isValid = getSelectMemberBtnStatus({ order })?.isValid;
    return (
        <Btn
            color="cmpPrimary"
            data-testid="select-mem-btn"
            disabled={isLoading || !isValid}
            variant="contained"
            sx={ProvisioningStyles?.selectMemBtn}
            onClick={() => {
                handleOpenMemlistModal();
            }}
        >
            {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Select Member'}
        </Btn>
    );
};
export default SelectMemberBtn;
