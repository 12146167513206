export const RI004 = {
    Jwt: {
        // provisioning
        previewOrder: 'api/reference-implementation/web-application/1.0/internal/order/ri004/preview',
        submitOrder: 'api/reference-implementation/web-application/1.0/internal/order/ri004/submit',
        reSubmit: 'api/reference-implementation/web-application/1.0/internal/order/ri004/resubmit',
        submitApproval: 'api/reference-implementation/web-application/1.0/internal/order/submit-approval',
        costCalculation: 'ri-cost-calculation.json', // 'api/reference-implementation/web-application/1.0/internal/cost-calculation',
        internalPreviewOrder: 'api/reference-implementation/web-application/1.0/internal/preview/order',
        downloadTF: 'api/reference-implementation/web-application/1.0/internal/order',
    },
};
export default RI004;
