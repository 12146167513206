import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'kafka-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'kafka-quickConfigDetails',
    default: undefined,
});

export const toggleSspModels = atom({
    key: 'kafka-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isConsumerGroupOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'kafka-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'kafka-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'kafka-orderId',
    default: '',
});
export const validFormState = atom({
    key: 'kafka-validFormState',
    default: {
        isValidLdapGroup: false,
    },
});
export const sspSubmitOrder = atom({
    key: 'kafka-sspOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        environment: null,
        appTier: null,
        appTierCode: null,
        sector: null,
        sectorDetails: null,
        region: null,
        regionDisplayName: null,
        department: null,
        appSupportMailId: null,
        departmentDetails: null,
        hCode: null,
        hCodeDetails: null,
        tags: [],
        estimatedCost: '0/month',
        topic: '',
        partitions: null,
        retention: null,
        retentionUnit: 'In Days',
        retentionDetails: null,
        costCenter: null,
        consumerGroupList: [{ value: '' }],
        catalogSize: null,
        businessUnit: null,
        project: '',
        businessUnitDetails: null,
        readIDX: '',
        writeIDX: '',
        transactionalId: 'no',
        consumerGroupRadio: 'no',
        transactionPrincipalId: '',
        transactionPrefixText: '',
        catalogCode: null,
        existingLDAPGroup: null,
        members: [],
        ldap: null,
        groupOwner: null,
        groupOwnerDetails: null,
        isReadAccessUser: null,
        readAccessGroupOwner: null,
        readAccessGroupOwnerDetails: null,
        isWriteAccessUser: null,
        writeAccessGroupOwner: null,
        writeAccessGroupOwnerDetails: null,
        ldapgroupOwner: null,
        ldapgroupOwnerDetails: null,
        isTouched: false,
        placeNewOrder: new Date(),
        catalogSizeDetails: null,
        validationStatus: 'clearValidation',
    },
});

export const isValidappSupportMailId = atom({
    key: 'kafka-isValidappSupportMailId',
    default: false,
});

export const environmentDetails = atom({
    key: 'kafka-environmentDetails',
    default: {
        Approval_Manager: '---',
        Project: '---',
    },
});

export const costEstimateState = atom({
    key: 'kafka-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'kafka-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
export const isValidTopic = atom({
    key: 'kafka-isValidTopic',
    default: false,
});
export const isValidProject = atom({
    key: 'kafka-isValidProject',
    default: false,
});
export const isValidReadIdxAccount = atom({
    key: 'kafka-isValidReadIdxAccount',
    default: false,
});
export const isValidWriteIdxAccount = atom({
    key: 'kafka-isValidWriteIdxAccount',
    default: false,
});
export const isValidLdapGroup = atom({
    key: 'kafka-isValidLdapGroup',
    default: false,
});
export const isValidTransactionPrincipalId = atom({
    key: 'kafka-isValidTransactionPrincipalId',
    default: false,
});
export const isValidTransactionPrefixText = atom({
    key: 'kafka-isValidTransactionPrefixText',
    default: false,
});

export const selectedMembersList = atom({
    key: 'kafka-selectedMembersList',
    default: [],
});
export const toggleConfirmModel = atom({
    key: 'kafka-toggleConfirmModel',
    default: {
        isOpen: false,
        type: 'delete',
        message: '',
        action: false,
        removeAll: false,
        removeAllAction: false,
    },
});

export const disableGroupOwnerFields = atom({
    key: 'kafka-disableGroupOwnerFields',
    default: {
        writeAccessGroupOwner: false,
        readAccessGroupOwner: false,
    },
});

export const validGroupOwnerFields = atom({
    key: 'kafka-validGroupOwnerFields',
    default: {
        writeAccessGroupOwner: false,
        readAccessGroupOwner: false,
        ldapGroupOwner: false,
    },
});

export const SecondaryApprover = atom({
    key: 'Kafka-secondaryApprover',
    default: { selectedFieldFrom: { module: null, subModule: null }, value: { groupOwner: null, groupOwnerDetails: null } },
});
export const SecondaryApproverBanner = atom({
    key: 'Kafka-SecondaryApproverBanner',
    default: { status: false, message: null },
});
