// import { RICatalogCode001, RICatalogCode001A } from 'pages/reference-implementation/utils/RICatalogCode';

export const isADGroupFormValid = (order) => {
    let errorCount = 0;
    let isValid = false;
    if (order?.adGroupList?.length) {
        order?.adGroupList?.forEach((item) => {
            if (item && (item?.adGroupValue === '' || item?.adGroupValue === null)) {
                errorCount += 1;
            }
        });
    }
    isValid = errorCount === 0;
    return isValid;
};

export const isADGroupDuplicate = (order) => {
    const adGroupArray = order?.adGroupList?.map((item) => item?.adGroupValue);
    return new Set(adGroupArray).size !== adGroupArray.length;
};
export const isNonProd = (order) => ['production']?.includes(order?.environment?.toLowerCase());
export const isReadyToSubmit = ({
    order,
    projectIdValid,
    businessUnitValid,
    budgetCodeValid,
    appNameValid,
    appShortNameVaild,
    appShortNameExist,
    nonPrivilegedADGroupValid,
    privilegedADGroupValid,
    uploadedFiles,
}) => {
    const validPrivilegedADGroup = () => {
        let errorCount = 0;
        order?.privilegedADGroupList?.forEach((z) => {
            if (z?.contributorValue === null || z?.contributorValue === '') {
                errorCount += 1;
            }
        });
        if (order?.privilegedADGroup === 'Yes' && (!privilegedADGroupValid || errorCount > 0)) {
            return true;
        }
        return false;
    };

    const validNonPrivilegedADGroup = () => {
        let errorCount = 0;
        order?.nonPrivilegedADGroupList?.forEach((z) => {
            if (z?.readerValue === null || z?.readerValue === '') {
                errorCount += 1;
            }
        });
        if (order?.nonPrivilegedADGroup === 'Yes' && (!nonPrivilegedADGroupValid || errorCount > 0)) {
            return true;
        }
        return false;
    };

    const validUploadFiles = () => {
        if (
            [
                /* RICatalogCode001, RICatalogCode001A */
            ]?.includes(order?.catalogCode)
        )
            return uploadedFiles?.length === 0;
        return false;
    };

    return (
        (order?.projectId && !projectIdValid) ||
        !order?.businessUnit ||
        !businessUnitValid ||
        !order?.sector ||
        (order?.sector?.toLowerCase() === 'cgf' && !order?.division) ||
        !order?.budgetCode ||
        !budgetCodeValid ||
        !order?.environment ||
        !order?.dataClassification ||
        !order?.appService ||
        !order?.applicationName ||
        !appNameValid ||
        !order?.appShortName ||
        !appShortNameVaild ||
        appShortNameExist ||
        !order?.businessContact ||
        !order?.businessContactDetails ||
        (order?.environment?.toLowerCase() !== 'production' && validNonPrivilegedADGroup()) ||
        validPrivilegedADGroup() ||
        (order?.environment?.toLowerCase() !== 'production' && (!order?.nonPrivilegedADGroup || !order?.privilegedADGroup)) ||
        (order?.environment?.toLowerCase() === 'production' && !order?.privilegedADGroup) ||
        (order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'No' &&
            (!order?.nonPrivilegedADGroupOwner || !order?.nonPrivilegedADGroupOwnerDetails)) ||
        (order?.environment?.toLowerCase() !== 'production' &&
            order?.privilegedADGroup === 'No' &&
            (!order?.privilegedADGroupOwner || !order?.privilegedADGroupOwnerDetails)) ||
        (order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' &&
            (!order?.privilegedADGroupOwner || !order?.privilegedADGroupOwnerDetails)) ||
        validUploadFiles()
    );
};

export const isDuplicate = (adGroupArray, adGroup) => {
    const duplicateReaderADGroups = adGroupArray?.filter((item, index) => adGroupArray.indexOf(item) !== index);
    if (adGroup) {
        return duplicateReaderADGroups.indexOf(adGroup) > -1;
    }
    return false;
};

export const isADGroupError = (validationMessage = [], adGroup = null) => {
    let errorCount = 0;
    let adGroupError = false;
    if (Array.isArray(validationMessage)) {
        validationMessage?.forEach((item) => {
            if (item.includes(adGroup)) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
        });
    }
    adGroupError = errorCount !== 0;
    return adGroupError;
};
