import { isShowAppRegionField } from '.';
import { selectedEnv } from './constant';

export const checkValidFields = (order, appShortNameValid) => {
    if (!order?.appShortName || !appShortNameValid) {
        return {
            isValid: false,
            message: 'App Shortname is required.',
        };
    }

    if (!order?.sector) {
        return {
            isValid: false,
            message: 'Sector is required.',
        };
    }
    if (!order?.environment) {
        return {
            isValid: false,
            message: 'Environment is required.',
        };
    }
    if (['cgf'].includes(order?.sector?.toLowerCase()) && !order?.division) {
        return {
            isValid: false,
            message: 'Division is required for the CGF sector.',
        };
    }
    if (isShowAppRegionField({ order }) && !order?.region) {
        return {
            isValid: false,
            message: 'Region is required.',
        };
    }
    if (!order?.appOwnerDetails?.gpid) {
        return {
            isValid: false,
            message: 'App Owner is required.',
        };
    }
    if (!order?.budgetCode) {
        return {
            isValid: false,
            message: 'Budget Code is required.',
        };
    }
    return {
        isValid: true,
        message: '',
    };
};

export default checkValidFields;

export const isAccessManagementOwner = ({ order }) => {
    const isProduction = order?.environment?.toLowerCase() === 'production';
    const isNonProduction = !isProduction;

    if ((isProduction || isNonProduction) && order?.privilegedADGroup === 'No' && (order?.privilegedADGroupOwner || order?.privilegedADGroupOwnerDetails))
        return { isValid: true, message: 'Privileged AD Group owner details ' };

    if (isNonProduction && order?.nonPrivilegedADGroup === 'No' && (order?.nonPrivilegedADGroupOwner || order?.nonPrivilegedADGroupOwnerDetails))
        return { isValid: true, message: 'Non-Privileged AD Group owner details ' };

    return { isValid: false, message: 'Non-Privileged AD Group & Privileged AD Group does not exist ' };
};
export const checkSqlAccountAdGroupOwnerOld = (resources) => {
    let isValid = false;
    let message = '';

    const sqlComponents = resources?.filter((component) => component?.configurationComponentCode === 'AZSQLPAAS');

    if (sqlComponents.length > 0) {
        const hasNoAccount = sqlComponents?.some((component) => component?.configureData?.IsADSQLAccount === 'No');

        if (hasNoAccount) {
            isValid = true;
            message = 'AD group owner exist';
        } else {
            isValid = false;
            message = 'AD group owner not exist';
        }
    } else {
        isValid = false;
        message = 'No SQL account components found';
    }

    return { isValid, message };
};

export const checkKafkaAdGroupOwner = (resources) => {
    const kafkaResources = resources?.filter((resource) => resource?.configurationComponentCode === 'KAFKA');

    if (kafkaResources?.length === 0) {
        return { isValid: false, message: 'No Kafka resources found' };
    }

    const hasNoAccess = kafkaResources?.some((resource) =>
        resource?.configureData?.kafkatopics?.some((topic) => topic?.isWriteAccess === 'No' || topic?.isReadAccess === 'No')
    );

    const isValid = hasNoAccess;
    const message = hasNoAccess ? 'AD group owner exist' : 'AD group owner not exist';

    return { isValid, message };
};

export const checkSqlAccountAdGroupOwner = (resources) => {
    const sqlComponents = resources?.filter((component) => component?.configurationComponentCode === 'AZSQLPAAS');

    if (sqlComponents?.length === 0) {
        return { isValid: false, message: 'No SQL account components found' };
    }

    // Check if any SQL resource has IsADSQLAccount set to 'No'
    const hasNoAccount = sqlComponents?.some((component) => component?.configureData?.IsADSQLAccount === 'No');

    const isValid = hasNoAccount;
    const message = hasNoAccount ? 'AD group owner exist' : 'AD group owner not exist';

    return { isValid, message };
};

export const isSecondaryGrpOwner = ({ order }) => {
    const components = order?.resourceMgmt?.[selectedEnv]?.components;
    return isAccessManagementOwner({ order })?.isValid || checkSqlAccountAdGroupOwner(components)?.isValid || checkKafkaAdGroupOwner(components)?.isValid;
};
