import range from 'lodash/range';

export const retentionUnits = ['In Days', 'In Hours'];
export const getRetention = (millisec) => {
    try {
        let seconds = Math.floor(millisec / 1000); //   coverting millisec to seconds (Ex.: 5400000/1000 = 5400)
        let minutes = Math.floor(seconds / 60); //   coverting seconds to minutes (Ex.: 5400/60 = 90)
        let hours = Math.floor(minutes / 60); //    coverting minutes to hours (Ex.: 90/60 = 1.5 => 1 (due to Math.floor))
        seconds %= 60; // rounding up remaining seconds by module  (Ex.: 5400%60 = 0)
        minutes = seconds >= 30 ? minutes + 1 : minutes; //  if seconds greater than 30 adding 1 to minute (Ex.: 0>=30 (false) => 90)
        minutes %= 60; //  In order to get the exact minutes  (Ex.: 90%60 = 30)
        hours %= 24; // In order to get the exact hours  (Ex.: 1%24 = 1)
        return { hours, minutes };
    } catch (e) {
        return { hours: 0, minutes: 0 };
    }
};
const getTopicRetention = (retentionUnit, retentionDetails) => {
    let retentionValue = { retention: '', retentionUnit, retentionDetails };
    if (retentionUnit === retentionUnits[0]) {
        const getdays = retentionDetails?.millisec / (1000 * 60 * 60 * 24);
        retentionValue = { retention: getdays, retentionUnit, retentionDetails: { displayValue: getdays, millisec: retentionDetails?.millisec } };
    }
    if (retentionUnit === retentionUnits[1]) {
        const hourMin = getRetention(retentionDetails?.millisec);
        retentionValue = {
            retentionUnit,
            retention: `${hourMin?.hours}:${hourMin?.minutes}`,
            retentionDetails: { displayValue: `${hourMin?.hours}:${hourMin?.minutes}`, millisec: retentionDetails?.millisec },
        };
    }
    return retentionValue;
};

export const formOrderObjFromCart = (data = null) => {
    const { itemDetail } = data || {};
    const { entityDetails, catalogService } = itemDetail || {};
    const { catalogConfig, catalogServiceCode, catalogType, cloudProvider, cloudService } = catalogService || {};
    const {
        environmentDetails,
        tags,
        topicName,
        retentionUnit,
        retentionPeriod,
        partitionCount,
        consumerGroup,
        consumerId,
        producerId,
        isTransactional,
        transactionPrincipalId,
        transactionPrefixText,
        ldapGroup,
    } = catalogConfig || {};

    const retention = getTopicRetention(retentionUnit, {
        displayValue: null,
        millisec: retentionPeriod,
    });
    return {
        // Application Section
        appProfile: entityDetails?.name,
        appProfileDetails: entityDetails?.name ? { label: entityDetails.name, value: entityDetails.name } : null,
        sector: environmentDetails?.sector,
        environment: environmentDetails?.environment,
        region: environmentDetails?.region,
        regionDisplayName: environmentDetails?.region && { label: environmentDetails.region, value: environmentDetails.region },
        landingZoneDetails: environmentDetails?.landingZoneId && { id: environmentDetails.landingZoneId },
        hCode: environmentDetails?.costCenter,
        hCodeDetails: environmentDetails?.costCenter
            ? {
                  label: environmentDetails.costCenter,
                  value: environmentDetails.costCenter,
                  projectName: environmentDetails.projectName,
              }
            : null,
        entityDetails: entityDetails && {
            ...entityDetails,
            owner: { name: entityDetails.applicationManager, emailId: entityDetails.applicationManagerEmail, id: entityDetails.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails.project,
        },
        // Resource Details Section
        topic: topicName,
        partitions: partitionCount,
        project: catalogConfig?.project,
        appSupportMailId: catalogConfig?.appSupportMailId,
        cluster: catalogConfig?.cluster,
        clusterDetails: catalogConfig?.cluster && { label: catalogConfig.cluster, value: catalogConfig.cluster },
        department: environmentDetails?.department,
        departmentDetails: environmentDetails?.department && { label: environmentDetails.department, value: environmentDetails.department },
        businessUnit: environmentDetails?.businessUnit,
        businessUnitDetails: environmentDetails?.businessUnit && { label: environmentDetails.businessUnit, value: environmentDetails.businessUnit },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode,
        existingLDAPGroup: ldapGroup ? 'yes' : 'no',
        ...(ldapGroup && { ldap: ldapGroup }),
        isaDocument: environmentDetails?.isaDocument,
        // ldap owner - active group owner need to set here
        consumerGroupRadio: consumerGroup !== '' && consumerGroup !== null ? 'yes' : 'no',
        ...(consumerGroup && { consumerGroupList: consumerGroup.split(',').map((z) => ({ value: z })) }),
        isReadAccessUser: consumerId ? 'yes' : 'no',
        readIDX: consumerId || null,
        isWriteAccessUser: producerId ? 'yes' : 'no',
        writeIDX: producerId || null,
        transactionalId: isTransactional ? 'yes' : 'no',
        ...(isTransactional && { transactionPrincipalId, transactionPrefixText }),
        retentionUnit: retention?.retentionUnit,
        retention: retention?.retention,
        retentionDetails: retention?.retentionDetails,
        // Others
        tagsQc: { ...tags },
        catalogCode: catalogServiceCode,
        catalogType,
        cloudProvider,
        cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const { entityDetails, catalogServices } = data || {};

    const catalog = catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || {};
    const { catalogConfig, catalogServiceCode, catalogType, cloudProvider, cloudService, sector } = catalog || {};
    const {
        environmentDetails,
        tags,
        topicName,
        retentionUnit,
        retentionPeriod,
        partitionCount,
        ldapGroup,
        consumerGroup,
        consumerId,
        producerId,
        isTransactional,
        transactionPrincipalId,
        transactionPrefixText,
        project,
        appSupportMailId,
        cluster,
    } = catalogConfig || {};
    const getDetail = (value) => value && { label: value, value };
    const retention = getTopicRetention(retentionUnit, {
        displayValue: null,
        millisec: retentionPeriod,
    });
    return {
        // Application Section
        appProfile: entityDetails?.name,
        appProfileDetails: getDetail(entityDetails?.name),
        sector,
        environment: environmentDetails?.environment,
        region: environmentDetails?.region,
        regionDisplayName: getDetail(environmentDetails?.region),
        landingZoneDetails: environmentDetails?.landingZoneId && { id: environmentDetails.landingZoneId },
        hCode: environmentDetails?.costCenter,
        hCodeDetails: environmentDetails?.costCenter && {
            ...getDetail(environmentDetails.costCenter),
            projectName: environmentDetails.projectName,
        },
        entityDetails: entityDetails && {
            ...entityDetails,
            owner: {
                name: entityDetails.applicationManager,
                emailId: entityDetails.applicationManagerEmail,
                id: entityDetails.applicationManagerGpid,
            },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails.project,
            billingEntity: entityDetails.billingEntity,
            sector,
        },
        // Resource Details Section
        topic: topicName,
        partitions: partitionCount,
        project,
        appSupportMailId,
        cluster,
        clusterDetails: getDetail(cluster),
        department: environmentDetails?.department,
        departmentDetails: getDetail(environmentDetails?.department),
        businessUnit: environmentDetails?.businessUnit,
        businessUnitDetails: getDetail(environmentDetails?.businessUnit),
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode,
        existingLDAPGroup: ldapGroup ? 'yes' : 'no',
        ...(ldapGroup && { ldap: ldapGroup }),
        isaDocument: environmentDetails?.isaDocument,
        // ldap owner - active group owner need to set here
        consumerGroupRadio: consumerGroup !== '' && consumerGroup !== null ? 'yes' : 'no',
        consumerGroupList: consumerGroup?.split(',').map((value) => ({ value })),
        isReadAccessUser: consumerId ? 'yes' : 'no',
        readIDX: consumerId,
        isWriteAccessUser: producerId ? 'yes' : 'no',
        writeIDX: producerId,
        transactionalId: isTransactional ? 'yes' : 'no',
        ...(isTransactional && { transactionPrincipalId, transactionPrefixText }),
        retentionUnit: retention?.retentionUnit,
        retention: retention?.retention,
        retentionDetails: retention?.retentionDetails,
        // Others
        tagsQc: { ...tags },
        catalogCode: catalogServiceCode,
        catalogType,
        cloudProvider,
        cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const formOrderObjFromEiapObject = (data = null, cloudService = null) => {
    const currentComponent = data?.landingZoneDetails?.config?.components?.filter((component) => component?.service === cloudService);
    return {
        appProfile: data?.appProfile,
        appProfileDetails: data?.appProfileDetails,
        entityDetails: data?.entityDetails,
        billingEntity: data?.entityDetails?.billingEntity || null,
        hCode: data?.entityDetails?.financialDetails || null,
        ...(data?.entityDetails?.financialDetails && {
            hCodeDetails: { label: data?.entityDetails?.financialDetails, projectName: data?.entityDetails?.projectName },
        }),
        ...(!data?.entityDetails?.financialDetails && { hCodeDetails: null }),
        sector: data?.sector,
        environment: data?.environment,
        region: data?.region || null,
        landingZoneDetails: {
            id: currentComponent?.[0]?.landingzoneId || null,
        },
    };
};
export const resetAppProfile = {
    appProfile: null,
    appProfileDetails: null,
};
export const resetOrder = {
    entityDetails: null,
    sector: null,
    sectorDetails: null,
    environment: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    department: null,
    departmentDetails: null,
    cluster: null,
    appSupportMailId: null,
    clusterDetails: null,
    topic: '',
    consumerGroupList: [{ value: '' }],
    businessUnit: null,
    businessUnitDetails: null,
    project: '',
    readIDX: null,
    writeIDX: null,
    transactionalId: 'no',
    consumerGroupRadio: 'no',
    transactionPrincipalId: '',
    transactionPrefixText: '',
    partitions: null,
    retention: null,
    retentionUnit: 'In Days',
    retentionDetails: null,
    hCode: null,
    hCodeDetails: null,
    appTier: 'Kafka Topics',
    appTierCode: 'KT',
    envDetails: null,
    // catalogSize: null,
    // catalogSizeDetails: null,
    tags: [],
    estimatedCost: {},
    existingLDAPGroup: null,
    members: [],
    ldap: null,
    groupOwner: null,
    groupOwnerDetails: null,
    isReadAccessUser: null,
    readAccessGroupOwner: null,
    readAccessGroupOwnerDetails: null,
    isWriteAccessUser: null,
    writeAccessGroupOwner: null,
    writeAccessGroupOwnerDetails: null,
    ldapgroupOwner: null,
    ldapgroupOwnerDetails: null,
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetEnvironmentDetails = {
    Project: '-',
    Approval_Manager: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isConsumerGroupOpen: false,
    isSubmitOrderSuccess: false,
    isUpdatedToCart: false,
    isCostSplitOpen: false,
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const minuteOptions = [0, 30, 45].map((min) => ({
    label: min,
    value: min,
    data: {
        displayValue: min,
        millisec: 1000 * 60 * min, // converting minutes to millisec (Ex.: 60000 * value)
    },
}));

export const hourOptions = range(0, 24).map((hour) => ({
    label: hour,
    value: hour,
    data: {
        displayValue: hour,
        millisec: 1000 * 60 * 60 * hour, // converting hours to millisec (Ex.: 3600000 * value)
    },
}));

export const dayOptions = range(1, 15).map((day) => ({
    label: day,
    value: day,
    data: {
        displayValue: day,
        millisec: 1000 * 60 * 60 * 24 * day, // converting days to millisec (Ex.: 86400000 * value)
    },
}));

export const isSecondaryGrpOwner = ({ order }) => {
    if (order?.existingLDAPGroup === 'no' || order?.isWriteAccessUser === 'no' || order?.isReadAccessUser === 'no') {
        return { isValid: true, message: 'AdGroup owner exist' };
    }
    return { isValid: false, message: 'AdGroup owner not exist' };
};
