import { orderStatus } from 'utils/orderStatus';

export default {
    [orderStatus[0]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'hourglass',
        },
        {
            name: 'System Approval',
            status: 'wait',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'Manager Approval Pending': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'Manager Approval',
            status: 'hourglass',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'System Approval Provisioning': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'hourglass',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'Manager Approval Provisioning': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'Manager Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'hourglass',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    [orderStatus[2]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Cancelled',
            status: 'clear',
        },
    ],
    [orderStatus[3]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'Manager Approval',
            status: 'clear',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
    ],
    'System Approval Provisioning Completed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'done',
        },
        {
            name: orderStatus[4]?.name,
            status: 'hourglass',
        },
    ],
    'Manager Approval Provisioning Completed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'Manager Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'done',
        },
        {
            name: orderStatus[4]?.name,
            status: 'hourglass',
        },
    ],
    'System Approval Completed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'done',
        },
        {
            name: 'Completed',
            status: 'done',
        },
    ],
    'Manager Approval Completed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'Manager Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'done',
        },
        {
            name: 'Completed',
            status: 'done',
        },
    ],
    'System Validation Pending': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'hourglass',
        },
        {
            name: 'System Approval',
            status: 'wait',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'Manager Validation Pending': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'hourglass',
        },
        {
            name: 'Manager Approval',
            status: 'wait',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'System Provisioning Failed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'clear',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'Manager Provisioning Failed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'done',
        },
        {
            name: 'Manager Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'clear',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'System Validation Failed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'clear',
        },
        {
            name: 'System Approval',
            status: 'wait',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'Manager Validation Failed': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Validation',
            status: 'clear',
        },
        {
            name: 'Manager Approval',
            status: 'wait',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'Manager Returned': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Action Required',
            status: 'priority-high',
        },
        {
            name: 'Validation',
            status: 'wait',
        },
        {
            name: 'Manager Approval',
            status: 'wait',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: 'Completed',
            status: 'wait',
        },
    ],
};
