import React from 'react';
import { Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'pages/reference-implementation/components/select';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';
// import ProvisioningStyles from 'pages/provisioning/private-endpoint-v2/style';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

const filter = createFilterOptions();
const Application = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(order?.appProfileDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.appProfile || '');
    const [options, setOptions] = React.useState([]);

    const appGetApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.application}?applicationServiceName=${ccInputValue}`));

    const setAppService = (appProfile) => {
        setOrder({
            ...order,
            appProfile: appProfile?.label,
            appProfileDetails: appProfile,
            appUrl: null,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) {
                    appGetApi.mutate();
                }
            }, 1000),
        [ccInputValue]
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (appGetApi?.isSuccess) {
            if (appGetApi?.data?.data?.applications) {
                const combinedValue = appGetApi?.data?.data?.applications?.map((app) => ({
                    label: `${app?.serviceName}`,
                    value: `${app?.serviceName}`,
                }));
                setOptions(combinedValue);
            }
        }
    }, [appGetApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.cloudProvider && order?.cloudService && ccInputValue?.length > 2) {
            appGetApi.mutate();
        }
    }, [order?.cloudProvider, order?.cloudService]);

    React.useEffect(() => {
        if (value !== null && value?.label) {
            setAppService(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.appProfile && order?.isTouched) setIsTouched(true);
    }, [order?.appProfile, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="app-ci-autocomplete"
                key="applicationName"
                label={<InputLabel label="Application Service" isRequired />}
                placeholder="Type to search"
                value={order?.appProfile || null}
                onInputChange={(event, newInputValue) => {
                    setCCInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                onBlur={() => {
                    if (ccInputValue === '' || ccInputValue === null) {
                        resetOptions();
                        setOrder({
                            ...order,
                            appProfile: null,
                            appProfileDetails: null,
                            appUrl: null,
                        });
                    }
                }}
                onKeyUp={(event) => {
                    // if (event.key === 'Enter') {
                    //     setSearched(true);
                    //     handleSearch();
                    // }
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        // setSearched(false);
                        resetOptions();
                        setOrder({
                            ...order,
                            appProfile: null,
                            appProfileDetails: null,
                            appUrl: null,
                        });
                    }
                }}
                filterOptions={(optionValues, params) => filter(optionValues, params)}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props}>{option.label}</li>
                )}
                // sx={ProvisioningStyles.costCenter}
                freeSolo
                isRequired={isTouched}
                isLoading={appGetApi?.isLoading}
            />
        </Box>
    );
};

export default React.memo(Application);
