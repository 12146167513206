import React from 'react';
import { Grid, Stack } from '@mui/material';
import Form from 'pages/myidm/components/Form';
import FormCard from 'pages/myidm/components/form-card';

const IsADGroupExistForm = () => {
    return (
        <Grid item md={12} boxShadow={2} borderRadius={1.5}>
            <FormCard title="" gap={0} padding={2} margin={2}>
                <Grid item container spacing={2} sx={{ mt: 0, mb: 0 }}>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Form.IsADGroupCreate />
                        </Stack>
                    </Grid>
                </Grid>
            </FormCard>
        </Grid>
    );
};
export default IsADGroupExistForm;
