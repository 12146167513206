import React from 'react';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { Box } from '@mui/material';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { OrderPayload } from 'pages/sso-okta/store';
import HelpImg from 'pages/provisioning/_components/helpImg';

export const NameIdFormat = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const nameIdFormatApi = useMutation(() => axios.get(`${endPoints?.ssoOkta?.nameIdFormat}`));

    const setNameId = (nameIdFormat) => {
        setOrder({
            ...order,
            nameIdFormat,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (nameIdFormatValue) => options?.find((option) => nameIdFormatValue === option?.value);

    const handleonSelectChange = (selected) => setNameId(selected?.value);

    React.useEffect(() => {
        if (nameIdFormatApi?.isSuccess) {
            const fetchedOptions = nameIdFormatApi?.data?.data?.map((item) => ({
                label: item?.displayName,
                value: item?.displayName,
            }));
            setOptions(fetchedOptions);
        }
    }, [nameIdFormatApi?.isSuccess, nameIdFormatApi?.data]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.nameIdFormat) {
            setOrder({ ...order, nameIdFormat: options?.[0]?.value });
        }
        if (options && options?.length && order?.nameIdFormat) {
            const nameIdObj = isExistsInOptions(order?.nameIdFormat);
            setOrder({ ...order, nameIdFormat: nameIdObj?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        nameIdFormatApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.nameIdFormat && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.nameIdFormat, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="name-id-format-autocomplete"
                key="NameIdFormat"
                label="Name ID Format *"
                value={order?.nameIdFormat || null}
                options={options}
                handleOnSelect={(value) => handleonSelectChange(value)}
                disabled={nameIdFormatApi?.isLoading}
                isLoading={nameIdFormatApi?.isLoading}
                isRequired={isTouched}
            />
            <HelpImg title="Identifies the SAML processing rules and constraiants for the assertion's subject statement.Use the default value of 'Unspecified' unless the application explicitly requires a specific format." />
        </Box>
    );
};

export default React.memo(NameIdFormat);
