import React from 'react';
import { Box, TableContainer, Table, TableBody, CircularProgress, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { OrderPayload, isMembersApiLoading } from 'pages/myidm/store';
import { Styles, ADGroupStyledTableCell, StyledTableRow } from 'pages/myidm/style';
import FormCard from 'pages/myidm/components/form-card';

const LdapGroupMembers = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setMebersApiLoader] = useRecoilState(isMembersApiLoading);
    const adGroupMembersApi = useMutation((payload) => axios.post(`${endPoints?.myIdm?.ldapGroupMembersApi}`, payload));
    // const adGroupMembersApi = useMutation((payload) =>
    //     axios.get(`${endPoints?.myIdm?.ldapGroupMembersApi}?environment=${payload?.environment}&groupName=${payload?.groupName}`)
    // );
    // const adGrpUniversalApi = useMutation(() => axios.get(`${endPoints?.myIdm?.adGroupIsUniversalApi}/${order?.adGroup}`));

    React.useEffect(() => {
        if (adGroupMembersApi?.isSuccess) setMebersApiLoader(false);
        if (adGroupMembersApi?.isSuccess && adGroupMembersApi?.data?.data) {
            setOrder({ ...order, adGroupMembers: adGroupMembersApi?.data?.data });
        }
    }, [adGroupMembersApi?.isSuccess]);
    React.useEffect(() => {
        if (order?.adGroup) {
            setMebersApiLoader(true);
            const payload = {
                environment: order?.environment,
                groupName: order?.adGroup,
            };
            adGroupMembersApi?.mutate(payload);
        }
    }, [order?.adGroup]);
    return (
        <Box>
            <FormCard title="LDAP Group Members List" gap={0} padding={2} showDivider={false}>
                <Box data-testid="members-list" sx={{ width: '100%' }}>
                    {adGroupMembersApi.isLoading && (
                        <Box sx={Styles?.addMembersCard?.loader}>
                            <CircularProgress color="inherit" size={16} />
                            <Typography variant="body2" sx={{ color: '#000000', marginTop: 1 }}>
                                Loading...
                            </Typography>
                        </Box>
                    )}
                    {adGroupMembersApi?.isError && !adGroupMembersApi?.isLoading && <Box sx={Styles?.addMembersCard?.wrongMsg}>Something is Wrong</Box>}
                    {!adGroupMembersApi?.isError &&
                        !adGroupMembersApi?.isLoading &&
                        (adGroupMembersApi?.data?.data?.length === 0 || adGroupMembersApi?.data?.data === null || order?.adGroupMembers === null) && (
                            <Box sx={Styles?.addMembersCard?.nomembers}>No Members</Box>
                        )}
                    {!adGroupMembersApi.isLoading && !adGroupMembersApi.isError && adGroupMembersApi?.data?.data?.length > 0 && order?.adGroupMembers && (
                        <TableContainer sx={Styles?.AddMemberForm?.table.AdGrptableWrapper}>
                            <Table sx={{ ...Styles?.AddMemberForm?.table?.memListmain, minHeight: '50px', borderSpacing: 0 }} size="small" stickyHeader>
                                <TableBody>
                                    {!adGroupMembersApi?.isLoading && adGroupMembersApi?.data?.data?.length > 0 && order?.adGroupMembers?.length > 0 && (
                                        <>
                                            {order?.adGroupMembers?.map((member) => (
                                                <StyledTableRow className="animate-opacity" key={member?.gpid}>
                                                    <ADGroupStyledTableCell>{member?.gpid || ''}</ADGroupStyledTableCell>
                                                    <ADGroupStyledTableCell>{`${member.firstName} ${member?.lastName}` || '-'}</ADGroupStyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </FormCard>
        </Box>
    );
};

export default LdapGroupMembers;
