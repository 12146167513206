import React from 'react';
import { Box, TableContainer, Table, TableBody, Typography, Grid, TableHead } from '@mui/material';
import { useRecoilState } from 'recoil';
import { Styles, ProvisionStyledTableCell, ProvisionStyledTableRow, ProvisionActionStyledTableRow } from 'pages/myidm/style';
import { ConfirmModal, SubmitRequest, CancelRequest } from 'pages/myidm/components/index';
import { selectedMembersList, currentSelection, toggleConfirmModel, invalidMember, OrderPayload } from 'pages/myidm/store';
import FormCard from 'pages/myidm/components/form-card';

const MemberslList = () => {
    const [members, setMembers] = useRecoilState(selectedMembersList);
    const [modal, setModal] = useRecoilState(toggleConfirmModel);
    const [current, setCurrent] = useRecoilState(currentSelection);
    const [, setInvalid] = useRecoilState(invalidMember);
    const [order] = useRecoilState(OrderPayload);

    const handleDelete = () => {
        setMembers(() => members.filter((value) => value?.gpid !== current?.gpid));
        setCurrent(null);
        setModal({ ...modal, isOpen: false, message: '', action: false });
        if (members?.length === 1) setInvalid(true);
    };
    React.useEffect(() => {
        if (modal?.action && current?.gpid) {
            handleDelete();
        }
    }, [modal.action]);

    const membersCount = order?.members?.length;
    const getSafeName = (gpid) => {
        let safename = '';
        if (
            !order?.mypamsafeDetails?.map((member) => member?.safeOwner)?.includes(gpid) &&
            (order?.nonSafeDetailsGpid?.includes(gpid) || order?.nonSafeDetailsGpid?.length === 0)
        ) {
            safename = 'SAFE NOT AVAILABLE';
        } else {
            safename = order?.mypamsafeDetails?.filter((member) => member?.safeOwner === gpid)?.[0]?.safeName;
        }

        return safename;
    };
    return (
        <Grid item boxShadow={2} borderRadius={1.5} md={12}>
            <Box>
                <FormCard title="Add Members" gap={0} padding={2} margin={2}>
                    <Box data-testid="approval-list" sx={{ width: '100%', marginTop: 2 }}>
                        <TableContainer sx={Styles?.ProvisionAddMemberForm?.table.tableWrapper}>
                            <Table sx={{ ...Styles?.ProvisionAddMemberForm?.table?.main, height: 'auto' }} size="small" stickyHeader>
                                {membersCount > 0 && (
                                    <TableHead>
                                        <ProvisionActionStyledTableRow>
                                            <ProvisionStyledTableCell>GPID</ProvisionStyledTableCell>
                                            <ProvisionStyledTableCell>Full Name</ProvisionStyledTableCell>
                                            <ProvisionStyledTableCell>
                                                {order?.adGroupType !== 'Privileged' ? 'Email ID' : 'myPAM Safe'}
                                            </ProvisionStyledTableCell>
                                        </ProvisionActionStyledTableRow>
                                    </TableHead>
                                )}
                                <TableBody sx={{ marginTop: 4 }}>
                                    <ProvisionActionStyledTableRow>
                                        {(membersCount < 1 || membersCount === undefined) && (
                                            <ProvisionStyledTableCell>No members selected.</ProvisionStyledTableCell>
                                        )}
                                    </ProvisionActionStyledTableRow>
                                    {membersCount > 0 ? (
                                        <>
                                            {order?.members?.length > 0 &&
                                                order?.members?.map((member) => (
                                                    <ProvisionStyledTableRow className="animate-opacity" key={member?.gpid} data-testId="members-list-table">
                                                        <ProvisionStyledTableCell>{member?.gpid || ''}</ProvisionStyledTableCell>
                                                        <ProvisionStyledTableCell>{member?.name || '-'}</ProvisionStyledTableCell>
                                                        <ProvisionStyledTableCell>
                                                            {order?.adGroupType !== 'Privileged' ? member?.email : getSafeName(member?.gpid)}
                                                        </ProvisionStyledTableCell>
                                                    </ProvisionStyledTableRow>
                                                ))}
                                        </>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {(membersCount > 0 && order?.adGroupType !== 'Privileged' && order?.estimatedCost?.details?.length === 0) ||
                        (order?.adGroupType === 'Privileged' && order?.nonSafeDetailsGpid?.length === 0) ? (
                            <Box display="flex" id="test" alignItems="baseline" justifyContent="space-between">
                                <Typography>
                                    <b>{membersCount}</b> {membersCount < 2 ? 'Member' : 'Members'} Selected{' '}
                                </Typography>
                                <Box display="flex" flexDirection="row-reverse" gap={1} sx={{ mt: 2 }}>
                                    <Box sx={{ marginTop: '0px' }}>
                                        <CancelRequest />
                                    </Box>
                                    <SubmitRequest />
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </FormCard>
            </Box>
            <ConfirmModal />
        </Grid>
    );
};

export default MemberslList;
