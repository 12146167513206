import { atom } from 'recoil';

export const sspSubmitOrder = atom({
    key: 'igpw-sspOrder',
    default: {
        resourceGroup: null,
        subNet: null,
        virtualNetwork: null,
        appGatewayType: 'no',
        appProfile: null,
        region: null,
        appProfileDetails: null,
        appOwner: null,
        appOwnerDetails: null,
        hCode: null,
        hCodeDetails: null,
        entityDetails: null,
        environment: null,
        envShortName: null,
        regionDisplayname: null,
        appTier: null,
        appTierCode: null,
        applicationName: null,
        sector: null,
        appGWIPAddress: null,
        sectorDetails: null,
        dataClassification: null,
        subscription: null,
        applicationGwTier: 'Application Gateway Standard v2',
        estimatedCost: {
            details: [],
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'There is no cost for the Application Gateway New Instance Provisioning onboarding in the shared Application Gateway.',
        },
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'igpw-environmentDetails',
    default: {
        Subscription: '---',
        Resource_Group: '---',
    },
});

export const toggleSspModels = atom({
    key: 'igpw-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'igpw-isEnvDetailsLoading',
    default: false,
});
export const costEstimateState = atom({
    key: 'igpw-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'igpw-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const orderId = atom({
    key: 'igpw-orderId',
    default: '',
});

export const isValidappSupportMailId = atom({
    key: 'igpw-isValidappSupportMailId',
    default: false,
});

export const isValidAppGWPrivateIPAddress = atom({
    key: 'igpw-isValidAppGWPrivateIPAddress',
    default: false,
});

export const isAppShortNameValid = atom({
    key: 'igpw-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'igpw-isAppShortNameExist',
    default: false,
});
export const isAppNameValid = atom({
    key: 'igpw-isAppNameValid',
    default: true,
});
