import React from 'react';
import { Box, Stack, Grow } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FaTrashAlt } from 'react-icons/fa';
import { orderPayload, allowedHeaders, selectedVSService, currentSubModule } from 'pages/provisioning/virtual-service/store';
import { addAllowedHeader, removeAllowedHeader, updateAllowedHeader } from 'pages/provisioning/virtual-service/store/selectors';
import Styles from 'pages/provisioning/virtual-service/style';
import Select from 'components/select';
import Btn from 'components/button';
import ConfirmDialog from 'pages/provisioning/virtual-service/dialog-box/ConfirmDialog';
import InfoImg from 'components/info-img';

const AllowedHeaders = () => {
    const [isModalOpen, setModal] = React.useState(false);
    const [confirmModal, setConfirmModal] = React.useState(null);

    const [order] = useRecoilState(orderPayload);
    const [allowedHeadersOptions] = useRecoilState(allowedHeaders);
    const [selectedVS] = useRecoilState(selectedVSService);
    const [, setCurentSubModule] = useRecoilState(currentSubModule);
    const addHeader = useSetRecoilState(addAllowedHeader);
    const removeHeader = useSetRecoilState(removeAllowedHeader);
    const updateHeader = useSetRecoilState(updateAllowedHeader);

    const handleValueChange = (value, index) => {
        updateHeader({ value, index, selectedVS });
    };

    const handleAction = (type) => {
        if (type === 'yes') {
            removeHeader({ confirmModal, selectedVS });
        }
        setConfirmModal(null);
        setModal(false);
    };

    React.useEffect(() => {
        setCurentSubModule('allowedHeaders');
        return () => {
            setCurentSubModule(null);
        };
    }, []);

    return (
        <>
            {order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders?.map((header, index) => (
                <React.Fragment key={`allowed-header-grow-${index}`}>
                    <Grow
                        in={!!order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders?.length}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders?.length ? { timeout: 500 } : {})}
                    >
                        <Stack direction="row" spacing={2} sx={{ mb: 2 }} key={index.toString()} alignItems="center">
                            <Box display="flex" alignItems="center" gap={1} width="100%">
                                <Select
                                    key="sector"
                                    label="Header"
                                    value={header?.allowedHeader || null}
                                    options={allowedHeadersOptions.filter(
                                        (option) =>
                                            !order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders?.some(
                                                (headerValue) => headerValue?.allowedHeader === option
                                            )
                                    )}
                                    isRequired={header?.isAllowedHeaderTouched}
                                    handleOnSelect={(value) => handleValueChange(value, index)}
                                    data-testid={`allowed-header-autocomplete-${index}`}
                                />
                                <InfoImg title="An allowed header in a virtual service specifies which HTTP headers are permitted for requests to the service." />
                            </Box>
                            {order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders.length > 1 && (
                                <Box className="flex w-full ">
                                    <Box className="trash-primary">
                                        {order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders.length > 1 ? (
                                            <FaTrashAlt
                                                data-testid={`remove-allowed-header-${index}`}
                                                style={Styles.vsType.delete}
                                                onClick={() => {
                                                    setConfirmModal({ header, index });
                                                    setModal(true);
                                                }}
                                                className="cursor-pointer"
                                            />
                                        ) : null}
                                    </Box>
                                </Box>
                            )}
                        </Stack>
                    </Grow>
                    {index === order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders.length - 1 &&
                    order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedHeaders?.length < 10 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', pt: 1 }}>
                            <Btn
                                variant="contained"
                                color="cmpPrimary"
                                sx={{ minWidth: '42px', width: 'auto', height: '32px', marginTop: '-10px' }}
                                disableFocusRipple
                                disableRipple
                                onClick={() => {
                                    addHeader({ count: 1, selectedVS });
                                }}
                                disabled={false}
                                data-testid="add-another-header"
                            >
                                Add Another Header
                            </Btn>
                        </Box>
                    ) : (
                        <span className="w-7 h-7" />
                    )}
                </React.Fragment>
            ))}
            {isModalOpen && (
                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    title="Are you sure you want to remove Allowed Header?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                />
            )}
        </>
    );
};

export default React.memo(AllowedHeaders);
