import { styled } from '@mui/material/styles';
import { Box, TextField, TableRow, Button } from '@mui/material';

const orderStyle = {
    container: {
        display: 'flex',
        // background: 'linear-gradient(#3395d7, #205f89)',
        backgroundColor: (theme) => theme.palette.white,
        color: (theme) => theme.palette.white,
        width: '100%',
        height: '100vh',
        paddingTop: (theme) => theme.spacing(0),
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        width: (theme) => theme.spacing(0.3125),
        color: '#535e76',
    },
    orderList: {
        orderTitle: {
            color: (theme) => theme.palette.black.dark,
            fontFamily: 'Stolzl',
            fontSize: '30px',
            paddingLeft: (theme) => theme.spacing(3.75),
        },
        orderHeaders: {
            display: 'flex',
            backgroundColor: '#FFFFFF',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: (theme) => theme.spacing(2),
            height: (theme) => theme.spacing(11.375),
            borderBottom: '1px solid #E0E0E0',
        },
        filterContainer: {
            marginLeft: (theme) => theme.spacing(1),
        },
        orderListContent: {
            fontSize: 13.5,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            cursor: 'pointer',
            flex: '1 1 0%',
            scrollbarWidth: 'thin',
            backgroundColor: '#F4F4F4',
        },
        orderFooter: {
            fontSize: 13.5,
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: (theme) => theme.spacing(1, 1.5, 1, 0),
        },
        wrapper: {
            backgroundColor: (theme) => theme.palette.white,
            borderBottom: '1px solid #E4E4E4',
            display: 'flex',
            alignItems: 'center',
            height: (theme) => theme.spacing(10.25),
        },
        infinatescroll: { paddingTop: '20px', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '16px' },
    },
    orderRIProvisionDetails: {
        wrapper: { display: 'flex', scroll: 'auto', flexWrap: 'wrap' },
        previewDetailsTextWrap: { paddingX: 3 },
        previewDetailsText: { fontSize: '21px', color: '#021D49', fontWeight: '600', fontFamily: 'Roboto' },
        previewOrderText: { fontSize: '16px', color: '#000000', fontWeight: 'normal', fontFamily: 'Roboto', opacity: 1 },
        card: {
            boxShadow: '0px 0px 2px #0000003E',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            height: '500px',
            marginTop: 2,
            padding: '15px 15px 15px 25px',
        },
        generalInfo: { fontSize: '18px', color: '#1378D4', fontWeight: '600', fontFamily: 'Roboto', letterSpacing: '-0.14px ' },
        generalWrapper: { display: 'flex', flexDirection: 'column', marginTop: '-5px' },
        devider: { color: '#707070', marginTop: 1, marginBottom: 1 },
        generalKey: {
            fontSize: '16px',
            color: '#000000',
            fontWeight: '600',
            fontFamily: 'Roboto',
            letterSpacing: '0px ',
        },
        generalValue: {
            fontSize: '14px',
            color: '#000000',
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            letterSpacing: '0px ',
        },
        resourceWrapper: { paddingY: 2 },
        editBtn: { border: ' 1px solid #707070', borderRadius: '4px', background: '#FFFFFF 0% 0% no-repeat padding-box' },
        resourceItemWrapper: { marginTop: 2.5 },
        reourceCard: {
            boxShadow: '0px 0px 2px #0000003E',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: 1,
            padding: '15px 20px 10px 20px',
            marginBottom: 1.5,
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
        summaryMembersCard: {
            membersCard: {
                borderRadius: '4px',
                boxShadow: 'none',
                padding: '25px 20px 10px 25px',
                marginBottom: 1.5,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
            },
            projectOnboardingMembersCard: {
                borderRadius: '4px',
                boxShadow: 'none',
                padding: '25px 0 10px 25px',
                marginBottom: 1.5,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
            },
            memberType: {
                fontSize: '14px',
                fontWeight: '600',
                fontFamily: 'Roboto',
                letterSpacing: '-0.14px ',
                paddingBottom: 1.5,
            },
        },

        resourceType: {
            fontSize: '18px',
            color: '#1378D4',
            fontWeight: '600',
            fontFamily: 'Roboto',
            letterSpacing: '-0.14px ',
            paddingBottom: 1.5,
        },
        resourceProperty: {
            minHeight: '50px',
            paddingTop: '8px',
            borderTop: '1.5px solid #E8E8E8',
            // marginTop: '10px'
        },
        day2Property: {
            background: '#ffffff !important',
            minHeight: '50px',
            paddingTop: '16px',
            borderTop: '1.5px solid #E8E8E8', // marginTop: '10px'
        },
        AppGateWayProperty: {
            background: '#ffffff !important',
            height: '50px',
            paddingTop: '16px',
            // borderTop: '1.5px solid #E8E8E8', // marginTop: '10px'
        },
        roleAssgnWrapper: {
            paddingTop: 1.5,
            borderTop: '1.5px solid #E8E8E8',
            // marginTop: '10px',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            // paddingBottom: 2,
        },
        roleAssignMent: {
            textAlign: 'left',
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: 'Roboto',
            letterSpacing: '-0.13px',
            marginRight: 2,
        },
        role: {
            color: '#1378D4',
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            paddingTop: 1,
        },
        roleVal: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: "'Roboto', Roboto",
            fontWeight: 'normal',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        roleAssignMentVal: { display: 'flex', flexWrap: 'wrap', flexDirection: 'column' },
    },
    orderRIProvision: {
        griditem: { color: '#003182' },
        gridContainer: { marginBottom: '20px !important' },
        connectionTitle: { fontSize: '16px', fontFamily: 'Stolzl', color: '#40B8E5', fontWeight: '500', marginLeft: 1, MarginTop: 1, MarginBottom: 1 },
        assignTable: {
            width: '100%',
            // border: '1px solid #E9E9E9',
            borderCollapse: 'collapse',
            color: '#000000',
            fontFamily: 'Roboto',
            fontSize: '14px',
            textAlign: 'center',
        },
        resourceTypekey: {
            textJustify: 'auto',
            padding: '12px 16px 12px 16px',
            textAlign: 'center',
            width: '25%',
        },
        resourceTypeValue: {
            border: '1px solid #1e1e1e',
            textJustify: ' auto',
            wordBreak: 'break-word',
            fontWeight: 'bold',
            padding: '12px 16px 12px 16px',
            textAlign: 'left',
            Height: '30px',
        },
        resourcePropKey: {
            textJustify: 'auto',
            wordBreak: 'break-word',
            padding: '12px 16px 12px 16px',
            textAlign: 'center',
            width: '25%',
        },
        resourcePropValuetd: {
            border: '1px solid #1e1e1e',
            textJustify: 'auto',
            wordBreak: 'break-word',
            padding: '12px 16px 12px 16px',
            textAlign: 'left',
            Height: '30px',
            width: '75%',
        },
        resourcePropValueWrapper: {
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        resourcePropValue: {
            width: 370,
            color: '#000000',
            fontSize: '14.5px',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'normal',
            textAlign: 'left',
        },
        tagkey: {
            textJustify: ' auto',
            wordBreak: 'break-word',
            padding: '12px 16px 12px 16px',
            textAlign: 'center',
            width: '25%',
        },
        accesskey: {
            textJustify: ' auto',
            wordBreak: 'break-word',
            padding: '12px 16px 12px 16px',
            textAlign: 'center',
            width: '25%',
        },
        accesstd: {
            border: '1px solid #1e1e1e',
            textJustify: ' auto',
            wordBreak: 'break-word',
            textAlign: 'center',
            width: '75%',
        },
        accessTable: {
            width: '100%',
            borderCollapse: 'collapse',
            color: '#000000',
            fontFamily: 'Roboto',
            fontSize: '14px',
            textAlign: 'center',
        },
        accessRolekey: {
            borderTop: '0px solid #1e1e1e',
            borderLeft: '0px solid #1e1e1e',
            borderRight: '1px solid #1e1e1e',
            textJustify: ' auto',
            wordBreak: 'break-word',
            padding: '12px 16px 12px 16px',
            textAlign: 'left',
            width: '25%',
            background: '#ffffff',
            fontWeight: 'bolder',
        },
        accessRoleValue: {
            textJustify: ' auto',
            wordBreak: 'break-word',
            padding: '12px 16px 12px 16px',
            textAlign: 'left',
            width: '75%',
        },

        resourceTypeWrapper: {
            height: '50px',
            borderTopLeftRadius: (theme) => theme.spacing(3),
            borderTopRightRadius: (theme) => theme.spacing(3),
            paddingTop: '14px',
        },
        resourceType: {
            paddingLeft: 3,
            fontSize: '16px',
            fontFamily: 'Roboto',
            color: '#ffffff',
            fontWeight: 'bold',
        },
        cardContent: { display: 'flex', flexDirection: 'column', padding: 2 },
        resourcePropWrapper: {
            borderBottomLeftRadius: (theme) => theme.spacing(4),
            borderBottomRightRadius: (theme) => theme.spacing(4),
            flex: '1 0 auto',
        },
        resourcePropBox: { flex: '1 0 auto', paddingLeft: '7px', paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0 },
        roleAssignWrapper: {
            borderBottomLeftRadius: (theme) => theme.spacing(4),
            borderBottomRightRadius: (theme) => theme.spacing(4),
            padding: '8px 8px 8px 15px',
        },
        adGroupTitle: {
            color: '#000000',
            fontSize: '14.5px',
            fontFamily: 'Roboto',
            fontWeight: 'bold',
            marginBottom: 0.5,
        },
        assignWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 0.5,
            paddingTop: 1,
        },
        assigneVal: {
            color: '#000000',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 500,
            lineHeight: '25px',
        },
        cardWrapper: { display: 'flex', width: { md: '100%' }, paddingTop: 1, flexDirection: 'column' },
        cardStyle: {
            background: '#E5ECF8',
            border: '0px solid #E5ECF8',
            borderRadius: (theme) => theme.spacing(3),
            padding: 0,
            width: '95%',
            // minWidth: 300,
        },
    },
    orderSummary: {
        warningIconColor: { color: '#d02424' },
        warningMsg: { fontSize: 18, fontWeight: 500 },
        noBtn: {
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        submitOrder: { textTransform: 'none' },
        cancelOrder: { textTransform: 'none' },
        provisionBtn: {
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing('2px'),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            textTransform: 'none',
            '&:hover': {
                borderWidth: (theme) => theme.spacing('2px'),
                borderColor: '#00A0DD',
                color: '#00A0DD',
            },
        },
        provisionDangerBtn: {
            color: (theme) => theme.palette.red.main,
            borderWidth: (theme) => theme.spacing('2px'),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.red.main,
            textTransform: 'none',
            '&:hover': {
                borderWidth: (theme) => theme.spacing('2px'),
                borderColor: '#991525',
                color: '#991525',
                backgroundColor: 'transparent',
            },
        },
        textTransform: 'none',
        loader: {
            color: (theme) => theme.palette.black.main,
        },
        orderLogsBtn: {
            color: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.blue.main,
            },
        },
        provisionDetailsBtn: {
            color: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.blue.main,
            },
        },
        orderLabel: {
            // textShadow: '2px 2px 2px #FFFFFF',
            color: '#000000',
            fontFamily: 'Roboto',
            fontSize: '16px',
        },
        orderLabelRight: {
            display: 'flex',
            color: '#000000',
            fontFamily: 'Roboto',
            fontSize: '16px',
            marginLeft: '6px',
            fontWeight: 'medium',
        },
        orderStatusChip: {
            borderRadius: (theme) => theme.spacing(6),
            background: '#535e76',
        },
        table: {
            common: {
                minWidth: '650px',
                fontSize: '14px',
            },
            cell: {
                color: '#2b3a57',
            },
            row: {
                'td,th': {
                    color: '#003182',
                },
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            },
        },
        statusLabel: {
            label: {
                sx: {
                    fontSize: '14px',
                    color: (theme) => theme.palette.white,
                },
            },
        },
        statusName: {
            color: '#1E1E1E',
            fontFamily: 'Stolzl',
            fontSize: '15px',
            fontWeight: 'bold',
        },
        statusNameN: {
            color: '#A5A5A5',
            fontFamily: 'Stolzl',
            fontSize: '15px',
            fontWeight: 'bold',
        },
        dialog: {
            color: (theme) => theme.palette.red.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        reSubmitDialog: {
            color: (theme) => theme.palette.black.light,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        dialogActionContainer: {
            justifyContent: 'center',
            marginBottom: (theme) => theme.spacing(3.5),
        },
        wrapper: { px: 3, width: '60%', height: '100vh' },
        circularProg: { width: '100%', height: '100%' },
        business: {
            color: (theme) => theme.palette.black.dark,
            // textTransform:'capitalize',
            fontFamily: 'Stolzl',
            fontSize: '30px',
            textAlign: 'center',
            fontweight: 'bold',
            paddingTop: (theme) => theme.spacing(3),
        },
        shortorderDetails: {
            wrapper: { fontSize: 14, mt: 1.25 },
            intialwrapper: { marginLeft: 0 },
            intialdevider: { height: '16px', width: '12px', borderColor: '#000000', borderRightWidth: '2px' },
            secondDevider: { height: '16px', width: '12px', borderColor: '#000000', borderRightWidth: '2px' },
            thirddevider: { height: '16px', width: '12px', borderColor: '#000000', borderRightWidth: '2px' },
            svgg: { marginLeft: 10, cursor: 'pointer', fill: '#0047BA' },
            detailsText: { color: '#0047BA', fontFamily: 'Roboto', textAlign: 'center', fontSize: '16px', cursor: 'pointer' },
            secondContainer: { marginLeft: -4.2 },
            thirdContainer: { marginRight: 8 },
        },
        ordersummarytitle: { color: '#000000', textAlign: 'center', fontFamily: 'Stolzl', fontSize: '25px', paddingBottom: (theme) => theme.spacing(2) },
        actioncontainer: { display: 'flex', marginBottom: 4, flexWrap: 'wrap', justifyContent: 'center' },
        stack: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
    },
    managerComments: {
        info: {
            color: '#F99C24',
            backgroundColor: '#FEEBD3',
            width: '35px',
            height: '35px',
            borderRadius: '50%',
            padding: '6px',
            marginRight: '8px',
            marginTop: '2px',
            fontSize: '10px',
        },
    },
    orderLogs: {
        log: {
            accordionSummary: { backgroundColor: '#0047BA', border: '1px solid #E0E0E0' },
            wrapper: {
                background: '#FAFAFA',
                boxShadow: 'none',
                color: (theme) => theme.palette.white,
                borderRadius: '8px solid #E8E8E8 !important',
                borderColor: '#E0E0E0',
                marginBottom: 1,
            },
            content: {
                background: '#FAFAFA',
                margin: (theme) => theme.spacing(0.25),
                marginTop: 0,
                color: '#333',
                border: '1px solid #E0E0E0',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
            },
            title: {
                width: '93%',
                flexShrink: 0,
            },
            span: {
                color: (theme) => theme.palette.black.dark,
            },
            expandIcon: {
                color: '#fff',
                marginRight: 1,
            },
            refreshIcon: {
                background: '#0047BA',
                borderRadius: 1,
                color: '#fff',
                marginLeft: 'auto',
            },
        },
        subLogs: {
            accordionSummary: {
                borderBottom: '1px solid #ccc',
                color: '#2b3a57',
            },
            accordionDetails: {
                color: '#003182',
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'scroll',
            },
            expandIcon: {
                marginRight: 1,
                color: '#0047BA',
            },
            refreshIcon: {
                background: '#FFFFFF',
                borderRadius: 1,
                color: '#0047BA',
                marginLeft: 'auto',
            },
        },
        triage: {
            paper: {
                background: (theme) => theme.palette.gray.lighter,
                border: '1px solid',
                borderColor: (theme) => theme.palette.gray.lowMedium,
                position: 'relative',
                padding: 1,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    zIndex: 10,
                    border: '0',
                    borderTop: '10px solid transparent',
                    borderBottom: '10px solid transparent',
                    marginTop: '-10px',
                    top: 25,
                    borderRight: '10px solid #cccccc',
                    left: -10,
                },
            },
            timelineleft: {
                width: '21%',
                flex: 'auto',
                color: '#000000',
                padding: (theme) => theme.spacing(0, 3, 0, 0),
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontweight: 'bold',
            },
            timelinelefttext: {
                fontSize: '12px',
                fontFamily: 'Roboto',
                color: (theme) => theme.palette.black.dark,
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
            },
            svginfo: {
                transform: 'translateY(15px)',
                position: 'absolute',
                height: (theme) => theme.spacing(3),
                backgroundColor: 'white',
            },
            svgdanger: {
                transform: 'translateY(15px)',
                position: 'absolute',
                backgroundColor: 'white',
                fill: '#DB1E36',
            },
            timelinecontent: {
                width: '75%',
                flex: 'auto',
                padding: (theme) => theme.spacing(0, 0, 2, 3),
            },
            pre: {
                overflow: 'auto',
                margin: 0,
                whiteSpace: 'pre-wrap',
            },
            labelValue: {
                padding: 0.25,
            },
            span: {
                color: (theme) => theme.palette.black.dark,
            },
            externalIcon: {
                cursor: 'pointer',
                background: (theme) => theme.palette.blue[90],
                borderRadius: (theme) => theme.spacing(0.75),
                fontSize: (theme) => theme.spacing(2),
            },
        },
    },
    orderFilter: {
        container: {
            height: '38px',
            width: '100px',
            border: '2px solid #0047BA',
            color: '#0047BA',
            '&:hover': {
                border: '2px solid #00A0DD',
                color: '#00A0DD',
                backgroundColor: 'transparent !important',
            },
        },
        wrapper: {
            background: '#FFFFFF',
            width: 660,
            padding: 2,
            position: 'relative',
            top: 11,
            border: '1px solid #E8E8E8',
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 300,
            color: '#FFFFFF',
            zIndex: 1,
        },
        filterIcon: { color: (theme) => theme.palette.blue.main },
        closeIcon: { cursor: 'pointer', '&:hover': { color: '#3381FF' } },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
        textField: {
            width: '100%',
            '& label': {
                marginTop: '-5px',
            },
            '& label.Mui-focused': {
                color: (theme) => theme.palette.blue.main,
            },
            '& label.MuiFormLabel-filled': {
                color: (theme) => theme.palette.blue.main,
                padding: (theme) => theme.spacing(0.5),
            },
            '& .MuiOutlinedInput-input': {
                height: (theme) => theme.spacing(0.75),
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                    color: (theme) => theme.palette.blue.lightest,
                },
                '&:hover fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                },
                '&.Mui-focused fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                },
            },
        },
        seletedFieldlabel: {
            '& label.Mui-focused': {
                color: '#0047BA',
            },
            '& label.MuiInputLabel-shrink': {
                color: '#0047BA',
                padding: '0 2px',
            },
        },
    },
    managerList: {
        dialog: {
            container: {
                '& .MuiDialog-paper': {
                    width: '80%',
                    maxHeight: 435,
                    height: 400,
                    minHeight: 250,
                },
            },
            title: {
                padding: 0,
                background: '#003182',
                color: (theme) => theme.palette.white,
            },
        },
        listContainer: {
            display: 'flex',
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#003182',
        },
        textInput: {
            background: (theme) => theme.palette.white,
            color: '#003182',
        },
        searchBtn: {
            height: 46,
            backgroundImage: 'radial-gradient(#003182,#1976d2)',
            border: '1.2px solid black',
            borderRadius: '0',
        },
        searchIcon: {
            color: (theme) => theme.palette.white,
        },
        span: { color: (theme) => theme.palette.black.dark },
        listItemTxt: { padding: 2 },
    },
    orderCatalogServices: {
        container: { borderBottom: '1px solid #ccc', color: '#2b3a57' },
        children: { color: '#003182', paddingTop: 2 },
        accordian: {
            marginBottom: 1,
            borderRadius: 1.2,
            '& .MuiAccordion-root': {
                content: 'none',
            },
            '& .MuiPaper-root': {
                '&:before': {
                    content: 'none',
                },
            },
        },
    },
    orderCatalogServices1: {
        container: { border: '1px solid #E8E8E8', borderRadius: '5px', height: '64px', background: '#FFFFFF 0% 0% no-repeat padding-box', color: '#1E1E1E' },
        children: { color: '#003182' },
    },
    orderCatalog: {
        wrapper: {
            background: '#FAFAFA 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: '#ffffff',
            borderRadius: '0px 0px 5px 5px !important',
            marginBottom: 1,
            border: '1px solid #E8E8E8',
        },
        serviceId: { marginLeft: '6px', fontFamily: 'Roboto', fontSize: '14px', fontWeight: 'normal', color: '#FFFFFF', opacity: '0.5' },
        content: {
            background: '#FAFAFA',
            margin: (theme) => theme.spacing(0.25),
            marginTop: 0,
            color: '#333',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        expandIcon: {
            color: (theme) => theme.palette.white,
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Stolzl',
            fontSize: (theme) => theme.spacing('20px'),
            color: '#FFFFFF',
            fontWeight: 'normal',
        },
        quantity: { color: (theme) => theme.palette.white, marginRight: '8px' },
        accordianSummary: {
            background: '#0047BA 0% 0% no-repeat padding-box',
            borderRadius: 1,
        },
        editBtn: {
            textTransform: 'none',
            fontWeight: '300',
            color: '#ffffff',
            border: '2px solid #FFFFFF ',
            marginRight: (theme) => theme.spacing(0.5),
            marginLeft: (theme) => theme.spacing(0.5),
            '&:hover': {
                border: '2px solid #FFFFFF !important',
                color: '#ffffff ',
            },
        },
        height: '37px',
        titleText: {
            fontFamily: 'Stolzl',
            fontSize: (theme) => theme.spacing('16px'),
            color: '#FFFFFF',
            fontWeight: '300',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: { xs: '100px', sm: '150px', md: '250px', lg: '350px', xl: '450px' },
        },
        actionWrapper: { display: 'flex', marginLeft: 'auto', alignItems: 'center', justifyContent: 'flex-end' },
    },
    orderDetails: {
        table: {
            cell: {
                fontSize: 14,
                color: (theme) => theme.palette.gray.dark,
            },
        },
        box: {
            display: 'flex',
            alignItems: 'center',
        },
        span: {
            color: (theme) => theme.palette.black.dark,
        },
        note: {
            color: '#f54747',
            paddingLeft: 2,
            marginBottom: 1,
        },
        downloadContainer: {
            color: (theme) => theme.palette.blue.main,
            fontweight: 400,
            paddingLeft: 1,
            marginBottom: 1,
            cursor: 'pointer',
        },
        downloadTxt: {
            marginLeft: '10px',
        },
        idmTicketNumber: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        provisionHeader: { color: '#000000', fontSize: '15px', fontStyle: 'Stolzl', fontWeight: 'normal' },
        resourceHeader: { color: '#0047BA', fontSize: '15px', fontStyle: 'Stolzl', fontWeight: 'bold' },
        nodeHeader: { color: '#0047BA', fontSize: '15px', fontStyle: 'Stolzl', fontWeight: 'bold', marginLeft: '7px' },
    },
    orderIdSearch: {
        searchIcon: {
            color: '#0047BA',
            fontSize: '18px',
        },
    },
    triageLog: {
        paper: {
            background: (theme) => theme.palette.gray.lighter,
            border: '1px solid',
            borderColor: (theme) => theme.palette.gray.lowMedium,
            position: 'relative',
            padding: 1,
            '&::before': {
                content: '""',
                position: 'absolute',
                zIndex: 10,
                border: '0',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                marginTop: '-10px',
                top: 25,
                borderRight: '10px solid #cccccc',
                left: -10,
            },
        },
        timelineleft: {
            width: '21%',
            flex: 'auto',
            padding: (theme) => theme.spacing(0, 3, 0, 0),
        },
        timelinelefttext: {
            fontSize: '13px',
            color: (theme) => theme.palette.black.dark,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        svginfo: {
            transform: 'translateY(15px)',
            position: 'absolute',
            height: (theme) => theme.spacing(3),
            backgroundColor: 'white',
        },
        svgdanger: {
            transform: 'translateY(15px)',
            position: 'absolute',
            backgroundColor: 'white',
            fill: '#DB1E36',
        },
        timelinecontent: {
            width: '75%',
            flex: 'auto',
            padding: (theme) => theme.spacing(0, 0, 2, 3),
        },
        span: {
            color: (theme) => theme.palette.black.dark,
        },
        label: {
            padding: (theme) => theme.spacing(0.25),
        },
        pre: {
            overflow: 'auto',
            margin: 0,
        },
    },
    emptycontainer: { backgroundColor: '#FFFFFF' },
    emptymsg: { fontFamily: 'Stolzl', fontSize: '20px', color: '#000000', fontWeigtht: 'medium', mb: 2 },
    emptySuggestion: { fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'normal', color: '#000000', paddingBottom: 4 },
};

export const OrdersListContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    height: '100%',
    paddingLeft: theme.spacing(0),
}));

export const CssTextField = styled(TextField)(() => ({
    '&.MuiInputLabel-root': {
        color: '#1E1E1E',
    },
    '&.MuiFormControl-root label': {
        color: '#1E1E1E',
    },
    '& .MuiInputBase-input': {
        color: '#1E1E1E',
        borderRadius: '10px',
        background: 'transparent',
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },

    '& .MuiOutlinedInput-root': {
        borderRadius: '5px',
        '& fieldset': {
            color: '#AFB8CC',
        },
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E4E4E4',
        borderRadius: '18px',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E4E4E4',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #3381FF',
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:nth-last-child(1)': {
        backgroundColor: 'transparent',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: (theme) => theme.palette.white,
    '&:hover': {
        backgroundColor: '#00A0DD',
        color: (theme) => theme.palette.white,
    },
}));

export const ResetButton = styled(Button)(() => ({
    background: '#0047BA',
    color: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#0047BA',
        color: '#FFFFFF',
    },
}));

export default orderStyle;
