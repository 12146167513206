import React from 'react';
import { Grid } from '@mui/material';
import FormCard from 'pages/reference-implementation/components/form-card';
import { useRecoilValue } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003/store';
import Form from 'pages/reference-implementation/provisioning/RI003/components';
import Heading from 'pages/reference-implementation/components/Heading';
import { isShowAppRegionField } from 'pages/reference-implementation/provisioning/RI003/utils';

const ProjectSection = () => {
    const order = useRecoilValue(OrderPayload);
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Heading title="Project" />
                </Grid>
            </Grid>
            <FormCard>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Form.PreviousOrderID />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ProjectId />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BusinessUnit />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.Sector />
                    </Grid>
                    {order?.sector && ['cgf']?.includes(order?.sector?.toLowerCase()) && (
                        <Grid item xs={12} sm={6}>
                            <Form.Division />
                        </Grid>
                    )}
                    {isShowAppRegionField({ order }) && (
                        <Grid item xs={12} sm={6}>
                            <Form.ApplicationRegion />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <Form.BudgetCode />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BillingInfo />
                    </Grid>
                </Grid>
            </FormCard>
        </>
    );
};

export default ProjectSection;
