export const resetOrder = {
    applicationService: null,
    applicationOwner: null,
    applicationOwnerDetails: null,
    sector: null,
    division: null,
    environment: null,
    applicationRegion: null,
    applicationRegionDetails: null,
    department: null,
    program: null,
    aksCluster: null,
    istioGatewayUrl: null,
    aksNamespace: null,
    costCenter: null,
    costCenterDetails: null,
    aksVirtualServiceType: null,
    serviceName: null,
    virtualServiceName: null,
    hostNamePrefix: null,
    servicePort: null,
    contextPath: null,
    corsPolicy: 'No',
    certificateOwnerOne: null,
    certificateOwnerOneDetails: null,
    certificateOwnerTwo: null,
    certificateOwnerTwoDetails: null,
    aksVirtualServiceRequestType: null,
    aksVirtualServiceUpdateCustomDomain: [{ aksIngressUrl: '', name: '', certificateExists: true }],
    aksVirtualService: [
        {
            virtualServiceType: null,
            virtualServiceName: null,
            hostNamesPrefix: [{ hostNamePrefix: null, isHostNameTouched: false }],
            customDomainSelected: 'No',
            customDomain: [
                {
                    name: null,
                },
            ],
            certificateExists: true,
            corsPolicy: 'No',
            aksService: [
                {
                    serviceName: null,
                    servicePort: null,
                    contextPath: null,
                },
            ],
            aksCors: {
                allowedHeaders: [{ allowedHeader: null, isAllowedHeaderTouched: false }],
                allowedOrigins: [{ allowedOrigin: null, isAllowedOriginTouched: false, isInvalid: false }],
                exposeHeaders: [{ exposeHeader: null, isExposeHeaderTouched: false }],
            },

            isServiceNameInvalid: false,
            isVirtualServiceNameInvalid: false,
            isServicePortInvalid: false,
            isHostNamePrefixInvalid: false,
            isContextPathInvalid: false,
        },
    ],
    estimatedCost: {
        details: [],
        qty: null,
        totalOneTimeCost: {
            frequency: 'one time',
            estCost: null,
            currency: 'USD',
        },
        totalRecurringCost: {
            frequency: 'monthly',
            estCost: null,
            currency: 'USD',
        },
        notes: 'There is no cost for the AKS Virtual Service On-boarding from Cloud Marketplace.',
    },
};

export const resetToggleModal = {
    isSuccessOpen: false,
    action: '',
    orderId: null,
};

export const resetValidOwners = { isValidApplicationOwner: true, isValidCertificateOwnerOne: true, isValidCertificateOwnerTwo: true };

export const resetVSForm = {
    isInvalidAksNamespace: false,
    isInvalidServiceName: false,
    isInvalidVirtualServiceName: false,
    isInvalidHostName: false,
    isInvalidCustomDomainName: false,
    isInvalidServicePort: false,
    isInvalidContextPath: false,
    isInvalidHeaderName: false,
    isInvalidAllowedOrigin: false,
    isInvalidAllowedHeader: false,
    isNamespaceExists: true,
    isInvalidIngressUrl: false,
};

export const resetVSCommonFields = {
    serviceName: null,
    virtualServiceName: null,
    hostNamePrefix: null,
    servicePort: null,
    contextPath: null,
    corsPolicy: 'No',
    isConfigureTouched: false,
};

export const resetServiceDetailsValidation = [
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
    { isInvalid: false, isTouched: false, message: '' },
];

export const virtualServiceDefaultValues = {
    virtualServiceType: null,
    virtualServiceName: null,
    hostNamesPrefix: [{ hostNamePrefix: null, isHostNameTouched: false }],
    customDomainSelected: 'No',
    customDomain: [
        {
            name: null,
        },
    ],
    corsPolicy: 'No',
    aksService: [
        {
            serviceName: null,
            servicePort: null,
            contextPath: null,
        },
    ],
    aksCors: {
        allowedHeaders: [{ allowedHeader: null, isInvalid: false }],
        allowedOrigins: [{ allowedOrigin: null, isInvalid: false }],
        exposeHeaders: [{ exposeHeader: null, isInvalid: false }],
        allowedMethods: [],
    },

    isServiceNameInvalid: false,
    isVirtualServiceNameInvalid: false,
    isServicePortInvalid: false,
    isHostNamePrefixInvalid: false,
    isContextPathInvalid: false,
};

export const virtualServiceCustomDomainDefaultValues = [{ aksIngressUrl: '', name: '', certificateExists: true }];
