import { styled } from '@mui/material/styles';
import { TextField, RadioGroup, Radio } from '@mui/material';

const ProvisioningStyles = {
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    costCenter: { width: '100%' },
    helpMessage: { color: '#FF3939' },
    disposal: {
        infoIcon: { color: (theme) => theme.palette.gray.main },
        helpText: { color: '#FF3939' },
    },
    card: {
        paper: {
            padding: (theme) => theme.spacing(2),
            background: (theme) => theme.palette.gray.lighter,
            height: (theme) => theme.spacing('100%'),
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },

        box: { mb: (theme) => theme.spacing(0.375) },
        typography: { marginBottom: (theme) => theme.spacing(0.25) },
    },
    envContainer: {
        badge: { marginRight: (theme) => theme.spacing(2) },
        samBtn: {
            marginRight: (theme) => theme.spacing(2),
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            textTransform: 'none',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        applicationBtn: {
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            textTransform: 'none',
            borderColor: (theme) => theme.palette.cyan.main,
            color: (theme) => theme.palette.cyan[70],
        },
        outlinedBtn: {
            color: (theme) => theme.palette.blue.main,
            textTransform: 'none',
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        tagBtn: { textTransform: 'none' },
    },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    provisonBtn: {
        width: (theme) => theme.spacing(23.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    Dialog: {
        title: { fontFamily: 'Stolzl', fontSize: '25px', fontWeight: 'bold', color: '#000000', paddingTop: '15px', paddingBottom: '10px' },
        msgDiv: { fontFamily: 'Roboto', fontSize: '13px', fontWeight: 'normal', padding: '5px 11px' },
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '428px',
            height: '225px',
            paddingTop: '20px',
            paddingBottom: '5px',
        },
        IconColor: '#C02223',
        icon: { fill: '#C02223', fontSize: '60px' },
        AzIcon: '#d02424',
        backBtnBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2), paddingBottom: '5px' },
        backBtn: { paddingX: '65px', textTransform: 'none' },
        AgreeBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            paddingX: '65px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                border: '2px solid #00A0DD',
                background: 'transparent',
            },
        },
    },
    serverRefresh: {
        textField: {
            input: {
                '&::placeholder': {
                    textAlign: { md: 'left', lg: 'left' },
                    fontSize: { md: (theme) => theme.spacing(1.625), lg: (theme) => theme.spacing(1.75) },
                    color: '#707070',
                    opacity: (theme) => theme.spacing(1),
                },
            },
            '& .MuiOutlinedInput-root': {
                display: { md: 'flex' },
                flexDirection: { md: 'row', lg: 'row' },
            },
        },
        radioGroup: {
            display: 'flex',
            justifyContent: 'right',
            width: 'inherit',
            fontSize: { md: (theme) => theme.spacing(1.25) },
            mr: { md: (theme) => theme.spacing(-2.25) },
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNo: {
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2) },
        message: { fontSize: '30px', fontWeight: 500, fontFamily: 'Stolzl', color: (theme) => theme.palette.black.dark },
        description: { width: '500px', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        checkOutBtn: {
            width: '180px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },

    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
            mt: (theme) => theme.spacing(3.125),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        ImgSpan: { marginRight: '4px' },
        img: { width: 26 },
        catalogsApiLoadingSpan: {
            background: '#E0E0E0',
            width: 33,
            height: 33,
            marginRight: '4px',
            borderRadius: '50%',
        },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
        warningIcon: { fill: '#D78B95', height: (theme) => theme.spacing(3), width: (theme) => theme.spacing(3), mt: (theme) => theme.spacing('-3px') },
        errorAlert: {
            backgroundColor: '#AE172B',
            color: '#FFFFFF',
            display: 'flex ',
            alignItems: 'center ',
            justifyContent: 'center ',
            fontSize: 14,
            fontFamily: 'Roboto',
            '& .MuiAlert-action': {
                display: 'none',
            },
        },
    },
    provisioning_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    sspform_wrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    provisioningBox: {
        display: 'flex',
        flexDirection: 'column',
        // overflowY: 'scroll',
        width: (theme) => theme.spacing('100%'),
        height: (theme) => theme.spacing('100%'),
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    tags: {
        wrapper: { minWidth: (theme) => theme.spacing(75), width: (theme) => theme.spacing(100), display: 'flex', flexDirection: 'column', height: '100%' },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2) },
        box: { display: 'flex', flexDirection: 'column' },
        titlebox: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(2) },
        name: { fontWeight: 'bold', width: '31%' },
        colon: { width: (theme) => theme.spacing(0.25), mx: 1.5 },
        val: { width: (theme) => theme.spacing(50), fontWeight: 'bold', flexGrow: 1 },
        dataWrapper: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(1) },
        dataNameWrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',

            borderRadius: (theme) => theme.spacing(0.25),
            borderColor: 'gray.lowMedium',
            py: (theme) => theme.spacing(0.5),
            px: (theme) => theme.spacing(1.5),
        },
        dataColan: { width: (theme) => theme.spacing(0.75), mx: (theme) => theme.spacing(1) },
        dataValueBoxWrapper: { flexGrow: 1 },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(0.25) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        activeSelectField: {
            background: 'transparent',
            '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
                color: '#0047BA',
                padding: '0 2px',
            },
            '& label.Mui-disabled+div fieldset': {
                border: ' 1px solid #E9E9E9',
            },
            '& label.MuiFormLabel-filled+div fieldset': {
                border: ' 1px solid #0047BA',
            },
            '& label.Mui-focused+div fieldset': {
                border: ' 2px solid #0047BA !important',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0047BA',
            },
        },
    },

    orderSuccesss: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        nsgOrderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'none', mr: (theme) => theme.spacing(0.5) },
        newOrderBox: { textAlign: 'center', my: (theme) => theme.spacing(1) },
        newOrderBtn: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
        thankyouTypography: { textAlign: 'center' },
        backHome: {
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },

    securityScan: {
        wrapper: { display: 'flex', alignItems: 'center', flexDirection: 'row' },
        formControl: { height: '127.13px', justifyContent: 'center', marginLeft: 1 },
        formControlHeader: { color: '#616161', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal' },
        stack: { paddingLeft: '10px' },
        typography: { color: '#616161', fontFamily: 'Roboto', fontSize: '14px', fontWeight: 'normal' },
    },
    isaValidation: {
        wrapper: { display: 'flex', alignItems: 'center', flexDirection: 'column' },
        formControl: { height: '67.13px', justifyContent: 'Left', marginLeft: 0, flexDirection: 'row', alignItems: 'center' },
        formControlHeader: { color: '#616161', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', textAlign: 'left' },
        stack: { paddingLeft: '10px', paddingTop: '8px' },
        typography: { color: '#616161', fontFamily: 'Roboto', fontSize: '14px', fontWeight: 'normal' },
    },
};

export const ServerTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme?.palette?.blue?.main,
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: theme?.palette?.blue?.main,
        padding: theme?.spacing(0.2),
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: theme?.palette?.blue?.dark,
        background: theme?.palette?.white,
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme?.palette?.gray?.light,
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: theme?.palette?.gray?.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme?.palette?.gray?.light,
        },
    },
    input: {
        '&::placeholder': {
            [theme.breakpoints.only('md')]: {
                fontSize: theme.spacing(1.625),
                textAlign: 'left',
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: theme.spacing(1.75),
                textAlign: 'left',
            },
            color: '#707070',
            opacity: 1,
        },
    },
}));
export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
export default ProvisioningStyles;
