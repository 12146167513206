import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useAxios } from 'hooks';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { Box, Typography, CircularProgress } from '@mui/material';
import { endPoints } from 'api/endpoints';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { catalogs } from 'store';
import {
    sspSubmitOrder,
    toggleSqlPassSubmit,
    environmentDetails,
    isMandatoryTagsFullfilled,
    orderId,
    isSQLDbNameAlreadyExist,
    isSQLDbNameValid,
    isLogicalHostNameValid,
    isLogicalHostNameAlreadyExist,
    toggleSqlPassUpdate,
    toggleSspModels,
} from 'pages/provisioning/store/sql-paas';
import { sspSubmitOrder as eiapSspSubmitOrder } from 'pages/provisioning/store/eip-stack';
import SqlStyles from 'pages/provisioning/sql/style';
import { formOrderObjFromCart, formOrderObjFromEiapObject, formOrderObjFromOrderDetails, resetOrder, resetToggleSspModal } from 'pages/provisioning/sql/utils';
import { navigateToWithPageReload } from 'utils';
import { eiapSave } from 'utils/constant';

const ProvisionHeaderContainer = React.lazy(() => import('pages/provisioning/sql/ProvisionHeaderContainer'));
const AddedToCartConfirmModal = React.lazy(() => import('pages/provisioning/sql/AddedToCartConfirmModal'));
const UpdatedToCartConfirmModal = React.lazy(() => import('pages/provisioning/sql/UpdatedToCartConfirmModal'));
const QuickConfig = React.lazy(() => import('pages/provisioning/sql/Form/QuickConfig'));
const ApplicationDetails = React.lazy(() => import('pages/provisioning/sql/ApplicationDetails'));
const Tags = React.lazy(() => import('pages/provisioning/sql/Tags'));
const SqlForm = React.lazy(() => import('pages/provisioning/sql/SqlForm'));
const ShowCostDetails = React.lazy(() => import('pages/provisioning/sql/ShowCostDetails'));
const ZoneRedundancyModal = React.lazy(() => import('pages/provisioning/sql/ZoneRedundancyModal'));
const GeoReplicationModal = React.lazy(() => import('pages/provisioning/sql/GeoReplicationModal'));

const SqlPaasProvisioningPage = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [eiapOrder] = useRecoilState(eiapSspSubmitOrder);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const componentId = queryParams.get('componentId');
    const axios = useAxios();

    const resetEnvStore = useResetRecoilState(environmentDetails);
    const resetModalStore = useResetRecoilState(toggleSqlPassSubmit);
    const resetUpdatedToCartModal = useResetRecoilState(toggleSqlPassUpdate);
    const resetIsMandatoryTagsFullfilled = useResetRecoilState(isMandatoryTagsFullfilled);
    const resetOrderId = useResetRecoilState(orderId);
    const resetSQLDbAlreadyExist = useResetRecoilState(isSQLDbNameAlreadyExist);
    const resetSQLDbValid = useResetRecoilState(isSQLDbNameValid);
    const resetLogicalHostValid = useResetRecoilState(isLogicalHostNameValid);
    const resetLogicalHostAlreadyExist = useResetRecoilState(isLogicalHostNameAlreadyExist);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);

    const resetStore = () => {
        setOrder({ ...order, ...resetOrder });
        resetEnvStore();
        resetModalStore();
        resetUpdatedToCartModal();
        resetIsMandatoryTagsFullfilled();
        resetOrderId();
        resetSQLDbAlreadyExist();
        resetSQLDbValid();
        resetLogicalHostValid();
        resetLogicalHostAlreadyExist();
        resetToggleSspModels(resetToggleSspModal);
    };

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => resetStore();
        // eslint-disable-next-line
    }, []);
    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }
    async function checkCatalog() {
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        const costs = await getCosts();
        if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const orderObj = await formOrderObjFromCart(cartItem);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
        } else if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(orderDetails, catalogServiceId);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
        } else if (operation === eiapSave) {
            if (!eiapOrder?.appProfile || !eiapOrder?.landingZoneDetails || !eiapOrder?.sector || !eiapOrder?.environment || !eiapOrder?.region) {
                navigateToWithPageReload('/');
            }

            let orderObj;
            if (eiapOrder?.components?.[componentId]) {
                orderObj = await formOrderObjFromCart({ itemDetail: eiapOrder?.components?.[componentId] });
            } else {
                orderObj = await formOrderObjFromEiapObject(eiapOrder, data.cloudService);
            }
            setOrder((previous) => ({
                ...previous,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data.cloudService,
                validationStatus: 'clearValidation',
                ...orderObj,
                componentId,
                eiapState: eiapOrder,
                operation: eiapSave,
                isTouched: false,
                emptyCost: costs,
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (cmpCatalogs?.length && catalogCode) {
            setLoading(true);
            resetStore();
            checkCatalog();
        }
    }, [cmpCatalogs, catalogCode]);
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>SQL Paas Provisioning - CMP</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={SqlStyles.main.wrapper}>
                        <CircularProgress size={16} sx={SqlStyles.main.WhiteColor} />
                        <Typography variant="body2" sx={SqlStyles.main.WhiteColor}>
                            Loading
                        </Typography>
                    </Box>
                }
            >
                {isLoading && (
                    <Box sx={SqlStyles.main.wrapper}>
                        <CircularProgress size={16} sx={SqlStyles.main.WhiteColor} />
                        <Typography variant="body2" sx={SqlStyles.main.WhiteColor}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <Box sx={SqlStyles.main.mainBox}>
                        <ProvisionHeaderContainer />
                        <Box sx={SqlStyles.main.columnWrapper}>
                            <SqlForm />
                        </Box>
                        <ApplicationDetails />
                        <Tags />
                        <AddedToCartConfirmModal />
                        <UpdatedToCartConfirmModal />
                        <QuickConfig />
                        <ShowCostDetails />
                        <ZoneRedundancyModal />
                        <GeoReplicationModal />
                    </Box>
                )}
            </Suspense>
        </ErrorBoundary>
    );
};

export default SqlPaasProvisioningPage;
