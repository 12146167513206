import {
    aksCatalogDefinitionId,
    AlbCatalogDefinitionId,
    couchbaseDbDefinitionId,
    eipStackCatalogDefinitionId,
    eventhubDefinitionId,
    kafkaCatalogDefinitionId,
    keyvaultCatalogDefinitionId,
    linuxCatalogDefinitionId,
    linuxOELCatalogDefinitionId,
    linuxSLESCatalogDefinitionId,
    oelOracleCatalogDefinitionId,
    oracleIaaSDefinitionId,
    pECatalogDefinitionId,
    sqlIaaSCatalogDefinitionId,
    sqlPaaSCatalogDefinitionId,
    storageCatalogDefinitionId,
    windowsCatalogDefinitionId,
    RICatalogDefinitionId,
    Day2CatalogDefinitionId,
    SecurityToolCatalogDefinitionId,
    SsoOktaCatalogDefinitionId,
    ServiceAccountDefinitionId,
    ApplicationGateWayURLCatalogDefinitionID,
    InternalApplicationGateWayURLCatalogDefinitionID,
    ExternalPublicLoadBalancerURLCatalogDefinitionID,
    ProjectOnboardCatalogDefinitionId,
    VirtualServiceCatalogDefinitionId,
    SlesPgtCatalogDefinitionId,
    NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
    SlesPgtDBCatalogDefinitionId,
} from 'utils/catalogDefinitionId';
import { DateTime } from 'luxon';

export const orderTypeOptions = [
    { label: 'All Orders', value: 'All Orders' },
    { label: 'My Orders', value: 'My Orders' },
];
export const filterDefaultObj = () => ({
    orderType: 'All Orders',
    sectors: [],
    status: [],
    orderId: '',
    requestorId: null,
    applicationName: null,
    cloudProvider: null,
    catalogServiceCodes: [],
    fromDate: null,
    toDate: null,
});

export const orderPageInterval = {
    orderDetail: 10000,
    catalogLogs: 10000,
    itemLogs: 10000,
    preLogs: 10000,
};

export const generateOrderPageFilterObj = ({ appliedFilters, additionalPayload }) => {
    const payload = {
        ...appliedFilters,
        toDate: appliedFilters?.toDate ? DateTime.fromJSDate(appliedFilters?.toDate).endOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : null,
        fromDate: appliedFilters?.fromDate
            ? DateTime.fromJSDate(appliedFilters?.fromDate).startOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
            : null,
    };
    return {
        ...payload,
        ...additionalPayload,
    };
};

export const getRedirectionpath = ({ catalogServiceCode, cartItemId, catalogServiceId, isSQLServer }) => {
    let path = '';
    const editPath = `${catalogServiceCode}/edit/${cartItemId}/${catalogServiceId}`;
    if (windowsCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/vm-provisioning/Windows/WindowsServer/${catalogServiceCode}/edit/${cartItemId}/${catalogServiceId}`;
    }
    if (
        linuxCatalogDefinitionId.includes(catalogServiceCode) &&
        !linuxOELCatalogDefinitionId.includes(catalogServiceCode) &&
        !linuxSLESCatalogDefinitionId.includes(catalogServiceCode)
    ) {
        path = `/vm-provisioning/Linux/RHEL/${editPath}`;
    }
    if (linuxOELCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/vm-provisioning/Linux/OEL/${editPath}`;
    }
    if ([...linuxSLESCatalogDefinitionId, ...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId].includes(catalogServiceCode)) {
        path = `/vm-provisioning/Linux/SLES/${editPath}`;
    }
    if (sqlPaaSCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/sql-paas-provisioning/${editPath}`;
    }
    if (oracleIaaSDefinitionId.includes(catalogServiceCode) && !oelOracleCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/oracle-provisioning/Linux/RHEL/${editPath}`;
    }
    if (oelOracleCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/oracle-provisioning/Linux/OEL/${editPath}`;
    }
    if (sqlIaaSCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/iaas-sql-provisioning/Windows/WindowsServer/${editPath}`;
    }
    if (storageCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/azure-storage-provisioning/${editPath}`;
    }
    if (aksCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/aks-nms-provisioning/${editPath}`;
    }
    if (AlbCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/azure-load-balancer/${editPath}`;
    }
    if (keyvaultCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/azure-key-vault/${editPath}`;
    }
    if (kafkaCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/kafka-topic-provisioning/${editPath}`;
    }
    if (eventhubDefinitionId.includes(catalogServiceCode)) {
        path = `/event-hub-provisioning/${editPath}`;
    }
    if (pECatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/private-endpoint-provisioning/${editPath}`;
    }
    if (eipStackCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/app-microservices-stack/${editPath}`;
    }
    if (couchbaseDbDefinitionId.includes(catalogServiceCode)) {
        path = `/couchbase-provisioning/${editPath}`;
    }
    if (RICatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/reference-implementation-provisioning/${editPath}`;
    }
    if (Day2CatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/access-management-myidm/${editPath}`;
    }
    if (SecurityToolCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/security-tool/${editPath}`;
    }
    if (SsoOktaCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/sso-okta/${editPath}`;
    }
    if (ServiceAccountDefinitionId.includes(catalogServiceCode)) {
        if (isSQLServer) {
            path = `/service-account-ci/${editPath}`;
        } else {
            path = `/service-account-creation/${editPath}`;
        }
    }
    if (ApplicationGateWayURLCatalogDefinitionID?.includes(catalogServiceCode)) {
        path = `/azure-application-gateway-url-onboarding/${editPath}`;
    }
    if (InternalApplicationGateWayURLCatalogDefinitionID?.includes(catalogServiceCode)) {
        path = `/azure-internal-application-gateway-url-onboarding/${editPath}`;
    }
    if (ExternalPublicLoadBalancerURLCatalogDefinitionID?.includes(catalogServiceCode)) {
        path = `/azure-external-public-load-balancer-url-onboarding/${editPath}`;
    }
    if (ProjectOnboardCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/project-onboarding/${editPath}`;
    }
    if (VirtualServiceCatalogDefinitionId.includes(catalogServiceCode)) {
        path = `/aks-vs-provisioning/${editPath}`;
    }
    if (NewInternalApplicationGateWayProvisioningCatalogDefinitionID.includes(catalogServiceCode)) {
        path = `/azure-new-internal-application-gateway-provisioning/${editPath}`;
    }
    return path;
};

export const ignoreManualFetchStatus = ['cancelled', 'rejected', 'completed'];
