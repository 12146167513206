import React, { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { catalogs } from 'store';
import {
    costEstimateState,
    isLoadingCostApiState,
    environmentDetails,
    isMandatoryTagsFullfilled,
    orderId,
    quickConfigDetails,
    sspSubmitOrder,
    toggleSspModels,
} from 'pages/provisioning/private-endpoint-v2/store';
import ProvisioningStyles from 'pages/provisioning/private-endpoint-v2/style';
import StickyHeader from 'pages/provisioning/private-endpoint-v2/components/sticky-header';
import { useMutation } from 'react-query';
import { formOrderObjFromOrderDetails, resetEnvironmentDetails, resetOrder, resetToggleSspModal, resetCostEstimateDetails, isReadyToSubmit } from './utils';
import CartBtn from './components/SubmitBtn';

const SspForm = React.lazy(() => import('pages/provisioning/private-endpoint-v2/SspForm'));
const AddedToCartConfirmModal = React.lazy(() => import('pages/provisioning/private-endpoint-v2/components/modal/AddedToCartConfirmModal'));
// const UpdatedToCartConfirmModal = React.lazy(() => import('pages/provisioning/private-endpoint-v2/UpdatedToCartConfirmModal'));
const ShowCostDetails = React.lazy(() => import('pages/provisioning/private-endpoint-v2/components/ShowCostDetails'));
const SourceResourceModal = React.lazy(() => import('pages/provisioning/private-endpoint-v2/components/modal/SourceResourceModal'));
const PreviewDetails = React.lazy(() => import('pages/provisioning/private-endpoint-v2/components/Preview'));

const PrivateEndpointProvision = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [catalog, setCatalog] = useState(undefined);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setCostEstimate] = useRecoilState(costEstimateState);
    const [, setEnvDetails] = useRecoilState(environmentDetails);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetQuickConfig] = useRecoilState(quickConfigDetails);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();
    const [, setIsLoadingCostApi] = useRecoilState(isLoadingCostApiState);

    const costCalcApi = useMutation((payload) => axios.post(`${endPoints.ssp.privateEndpointV2.costCalculation}`, payload));
    async function getCosts() {
        const { data } = await axios.post(`${endPoints.ssp.privateEndpointV2.costCalculation}`, {
            catalogServiceCode: catalogCode,
            quantity: 1,
        });
        return data;
    }
    async function checkCatalog() {
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        setCatalog(data);
        const costs = await getCosts();
        if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                initCost: costs,
            }));
            setEnvDetails({
                Approval_Manager: orderObj?.entityDetails?.owner?.name,
                Project: orderObj?.entityDetails?.projectName,
            });
        } else {
            setOrder((previous) => ({
                ...previous,
                sku: data?.images?.sku,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
                initCost: costs,
            }));
        }
        setLoading(false);
    }
    const reset = () => {
        setOrder({ ...order, ...resetOrder });
        resetOrderId('');
        resetEnvDetails(resetEnvironmentDetails);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetQuickConfig(undefined);
        setCostEstimate({ ...resetCostEstimateDetails });
    };
    const isShowBackBtn = () => {
        if (order?.showPreview === 'pre-preview-order') {
            return true;
        }
        return false;
    };
    const handleBackBtn = () => {
        if (order?.showPreview === 'pre-preview-order') {
            setOrder({ ...order, showPreview: null });
        }
    };
    const getHeaderButton = () => {
        if (order?.showPreview === 'pre-preview-order') {
            return <CartBtn isBgApiLoading={false} />;
        }
        return null;
    };
    React.useEffect(() => {
        if (cmpCatalogs?.length && catalogCode) {
            setLoading(true);
            reset();
            checkCatalog();
        }
    }, [cmpCatalogs, catalogCode]);

    React.useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        if (isReadyToSubmit({ order })) {
            costCalcApi?.mutate({
                catalogServiceCode: catalogCode,
                quantity: order?.instanceDetails?.length || 1,
            });
        }
        // eslint-disable-next-line
    }, [order?.appProfile, order?.sector, order?.appOwner, order?.instanceDetails]);

    React.useEffect(() => {
        setIsLoadingCostApi({ isLoading: costCalcApi?.isLoading });
    }, [costCalcApi?.isLoading, costCalcApi?.isSuccess]);
    React.useEffect(() => {
        if (costCalcApi?.data?.data && isReadyToSubmit({ order })) {
            if (costCalcApi?.data?.data) setCostEstimate(costCalcApi?.data?.data);
            setOrder({ ...order, estimatedCost: costCalcApi?.data?.data });
        }
        // eslint-disable-next-line
    }, [costCalcApi?.isSuccess]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Private Endpoint Provisioning - CMP</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                }
            >
                {isLoading && (
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <>
                        {catalog && (
                            <StickyHeader
                                title={operation === 'edit' && order?.showPreview === 'pre-preview-order' ? `Order ID - ${cartItemId}` : catalog?.displayName}
                                iconPath={catalog?.iconPath}
                                back={isShowBackBtn()}
                                handleBackBtn={() => handleBackBtn()}
                                showCartBtn={order?.showPreview}
                                cartBtn={getHeaderButton()}
                            />
                        )}
                        <Box sx={{ display: !order?.showPreview ? 'block' : 'none' }} data-testid="index-form">
                            <Box sx={ProvisioningStyles.provisioningBox}>
                                <Box sx={ProvisioningStyles.sspform_wrapper}>
                                    <SspForm />
                                </Box>
                            </Box>
                        </Box>
                        {order?.showPreview === 'pre-preview-order' && <PreviewDetails />}
                        <SourceResourceModal />
                        <ShowCostDetails />
                        <AddedToCartConfirmModal />
                    </>
                )}
            </Suspense>
        </>
    );
};

export default PrivateEndpointProvision;
