import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { memberForm, OrderPayload, showisExistADGrp } from 'pages/myidm/store';
import { resetCostEstimateDetails } from 'pages/myidm/utils';

export const GroupType = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [form, setForm] = useRecoilState(memberForm);
    const [, setIsExistADGrp] = useRecoilState(showisExistADGrp);
    const [options, setOptions] = React.useState([]);
    const groupTypeApi = useMutation(() => axios.get(endPoints.myIdm.groupTypes));
    const setEnv = (groupType) => {
        setOrder({
            ...order,
            groupType,
            isNewADGroup: null,
            // businessApplication: null,
            // businessAppDetails: null,
            // sector: null,
            // environment: null,
            // envShortName: null,
            appService: null,
            appShortName: null,
            adGroupType: null,
            adGroupTypeDetails: null,
            applicationName: null,
            adGroup: null,
            adGroupMembers: [],
            nonSafeDetailsGpid: [],
            members: [],
            groupOwner: null,
            groupOwnerDetails: null,
            projectName: null,
            estimatedCost: resetCostEstimateDetails,
        });
        setIsExistADGrp(false);
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);

    const handleOnSelectChange = (selected) => setEnv(selected.value);

    React.useEffect(() => {
        if (groupTypeApi?.isSuccess) {
            setOptions(
                groupTypeApi?.data?.data?.filter((app) => app?.isTypeToDisplay)?.map((app) => ({ label: app?.displayName, value: app?.displayName, data: app }))
            );
        }
    }, [groupTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.groupType) {
            setOrder({ ...order, groupType: options?.[0].value });
        }
        if (options && options?.length && order?.groupType) {
            const groupType = isExistsInOptions(order?.groupType);
            setOrder({ ...order, groupType: groupType?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        groupTypeApi.mutate();
    }, []);

    React.useEffect(() => {
        resetOptions();
        groupTypeApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.groupType && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.groupType, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="group-type-autocomplete"
                key="groupType"
                label="Group Type *"
                value={order?.groupType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={groupTypeApi.isLoading || !order?.environment}
                isLoading={groupTypeApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(GroupType);
