import { camelToTitle } from 'utils';

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        ...(data?.id && { id: data?.id }),
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: catalogConfig?.budgetCode,
            // projectName: entityDetails?.project,
            // billingEntity: entityDetails?.billingEntity,
            sector: catalogConfig?.sector,
        },
        sector: catalogConfig?.sector || null,
        environment: catalogConfig?.environment || null,
        applicationName: catalogConfig?.applicationName,
        region: catalogConfig?.region || null,
        regionDisplayname: catalogConfig?.region,
        appOwner: data?.manager?.id,
        hCode: catalogConfig?.budgetCode || null,
        hCodeDetails: { label: catalogConfig?.budgetCode, value: catalogConfig?.budgetCode, projectName: environmentDetails?.projectName },
        applicationGwTier: catalogConfig?.applicationGatewayTier,
        virtualNetwork: catalogConfig?.virtualNetworkName,
        catalogCode: catalog?.catalogServiceCode,
        subscription: catalogConfig?.subscription,
        resourceGroup: catalogConfig?.resourceGroupName,
        dataClassification: catalogConfig?.dataClassification,
        subNet: catalogConfig?.subnetName,
        appGWIPAddress: catalogConfig?.privateIpAddress,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        provisioning: true,
        isTouched: false,
    };
};

export const formUploadObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;

    return (
        catalogConfig?.filesUploadResponse?.uploadResponses?.map((item) => ({
            id: item?.id,
            name: item?.fileName,
            blobRelativeLink: item?.blobRelativeLink,
            size: item?.fileSize || 0,
        })) || []
    );
};

export const environmentConfig = {
    development: {
        regex: /^[a-zA-Z0-9-]{3,20}\.dev\.(mypepsico|pepsico)\.com$/,
        message: 'Application URL is Invalid. Must be in the format: xxx.dev.mypepsico.com or xxx.dev.pepsico.com (where xxx is 3-20 characters)',
    },
    qa: {
        regex: /^[a-zA-Z0-9-]{3,20}\.(qa|ite)\.(mypepsico|pepsico)\.com$/,
        message:
            'Application URL is Invalid. Must be in the format: xxx.qa.mypepsico.com or xxx.qa.pepsico.com or xxx.ite.mypepsico.com or xxx.ite.pepsico.com (where xxx is 3-20 characters)',
    },
    'pre-production': {
        regex: /^[a-zA-Z0-9-]{3,20}\.(preprod|ite)\.(mypepsico|pepsico)\.com$/,
        message: 'Application URL is Invalid. Must be in the format: xxx.preprod.mypepsico.com or xxx.ite.mypepsico.com (where xxx is 3-20 characters)',
    },
    production: {
        regex: /^[a-zA-Z0-9-]{3,20}\.(mypepsico|pepsico)\.com$/,
        message: 'Application URL is Invalid. Must be in the format: xxx.mypepsico.com or xxx.pepsico.com (where xxx is 3-20 characters)',
    },
};

export const validateAppURL = (url, environment) => {
    const config = environmentConfig[environment?.toLowerCase()];

    if (!config) {
        return {
            isValid: false,
            message: 'Invalid environment specified',
        };
    }

    const isValid = config.regex.test(url);
    return {
        isValid,
        message: isValid ? 'Valid URL' : config.message,
    };
};

export const resetOrder = {
    appProfile: null,
    region: null,
    regionDisplayname: null,
    appProfileDetails: null,
    appOwner: null,
    appOwnerDetails: null,
    hCode: null,
    hCodeDetails: null,
    entityDetails: null,
    environment: null,
    envShortName: null,
    applicationName: null,
    sector: null,
    sectorDetails: null,
    appGWIPAddress: null,
    appGatewayProvisioning: null,
    dataClassification: null,
    subscription: null,
    estimatedCost: null,
    provisioning: false,
    resourceGroup: null,
    applicationGwTier: 'Application Gateway Standard v2',
    subNet: null,
    subNetDetails: null,
    virtualNetwork: null,
    placeNewOrder: new Date(),
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetCostEstimateDetails = {
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: 0,
        currency: 'USD',
    },
    totalOneTimeCost: {
        frequency: 'One Time',
        estCost: 0,
        currency: 'USD',
    },

    notes: 'Each capacity unit is composed of at most: 1 compute unit, or 2,500 persistent connections, or 2.22-Mbps throughput. If any one of these metrics are exceeded, then another n capacity unit(s) are necessary, even if the other two metrics don’t exceed this single capacity unit’s limits. Outbound data transfer extra at actuals.',
};

export const resetEnvironmentDetails = {
    Subscription: '---',
    Resource_Group: '---',
    'Application gateway Name': '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};
export const getValueFromKey = (searchKey, keyValuePairs) => {
    return keyValuePairs[searchKey] ? keyValuePairs[searchKey] : camelToTitle(searchKey);
};
export const mappingLabel = {
    isaNumber: 'ISA Number',
    isaStatus: 'ISA Status',
    parentIsaNumber: 'Parent ISA Number',
    parentIsaNumberStatus: 'Parent ISA Number Status',
    scanNumber: 'SCAN Number',
    scanStatus: 'SCAN Status',
    scanTaskNumber: 'SCAN Task Number',
    scanTaskState: 'SCAN Task State',
};

export const isDuplicate = (adGroupArray, adGroup) => {
    const duplicateReaderADGroups = adGroupArray?.filter((item, index) => adGroupArray.indexOf(item) !== index);
    if (adGroup) {
        return duplicateReaderADGroups.indexOf(adGroup) > -1;
    }
    return false;
};

export const isADGroupError = (validationMessage = [], adGroup = null) => {
    let errorCount = 0;
    let adGroupError = false;
    if (Array.isArray(validationMessage)) {
        validationMessage?.forEach((item) => {
            if (item.includes(adGroup)) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
        });
    }
    adGroupError = errorCount !== 0;
    return adGroupError;
};
