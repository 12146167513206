import { atom } from 'recoil';

export const toggleSamRequest = atom({
    key: 'toggleSamRequest',
    default: false,
});

export const toggleQuickConfig = atom({
    key: 'toggleQuickConfig',
    default: false,
});

export const defaultVmDataDisk = atom({
    key: 'defaultVmDataDisk',
    default: undefined,
});
export const isDataDiskLoaded = atom({
    key: 'isDataDiskLoaded',
    default: false,
});
export const defaultOsDiskType = atom({
    key: 'defaultOsDiskType',
    default: undefined,
});
export const quickConfigDetails = atom({
    key: 'quickConfigDetails',
    default: undefined,
});
export const toggleSspModels = atom({
    key: 'toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isDiskOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
        isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
        isPgtDiskOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'isEnvDetailsLoading',
    default: false,
});

export const isConfigDiskFulfilled = atom({
    key: 'isConfigDiskFulfilled',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'sspOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        environment: null,
        appTier: null,
        appTierCode: null,
        region: null,
        regionDisplayName: null,
        landingZone: null,
        landingZoneDetails: null,
        osType: null,
        osVersion: null,
        osVersionDetail: null,
        osDiskType: null,
        vmType: null,
        vmTypeDetails: null,
        quantity: 1,
        maxDataDiskCount: null,
        dataDisksCount: null,
        dataDisks: [],
        defaultDataDisks: [],
        adGroup: null,
        hoursOfOperation: null,
        hoursOfOperationDetail: null, // { active: 1, id: '763cf923-85d9-43f7-b338-7be17fded399', name: 'Always ON', type: 'hours_of_operation' },
        timeZone: 'UTC',
        timeZoneDetail: null, // { id: 'd3bb59b6-72b3-49c0-a1c2-c0494ea28802', name: 'UTC', type: 'time_zones', value: 'Default-UTC' }
        domain: null,
        domainDetails: null,
        hCode: null,
        hCodeDetails: null,
        billingEntity: null,
        billingEntityDetails: null,
        adousDefault: null,
        adous: null,
        adousDetails: null,
        sector: null,
        sectorDetails: null,
        tags: [],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        catalogCode: null,
        serverRefresh: 'no',
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
        haDeployment: null,
        availabilityZone: null,
        availabilityZoneOptions: [],
        retiringServerName: null,
        retiringServerSysId: null,
        sapGid: null,
        sapSid: null,
        Gid: null,
        Uid: null,
        sapSolution: null,
        catalogOffer: null,
        catalogOfferDetail: null,
    },
});

export const environmentDetails = atom({
    key: 'environmentDetails',
    default: {
        Subscription: '---',
        'DR Priority': '---',
        Resource_Group: '---',
        Metallic_Selection: '---',
        Backup_Tier: '---',
        Virtual_Network: '---',
        // Network_Security_Group: '-',
        Subnet_Name: '---',
        // Application_Security_Group: '-',
        Recovery_Vault_Name: '---',
        Approval_Manager: '---',
        Project: '---',
        Availability_Set: '---',
        Azure_Site_Recovery: '---',
    },
});
export const toggleCurrentServer = atom({
    key: 'toggleCurrentServer',
    default: false,
});

export const CurrentServerDetails = atom({
    key: 'CurrentServerDetails',
    default: null,
});

export const costEstimateState = atom({
    key: 'vm-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'vm-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
export const isSapSidValid = atom({
    key: 'vm-isSapSidValid',
    default: true,
});

export const isGidValid = atom({
    key: 'vm-isGidValid',
    default: true,
});

export const isUidValid = atom({
    key: 'vm-isUidValid',
    default: true,
});
export const toggleSapPgtRequest = atom({
    key: 'vm-toggleSapPgtRequest',
    default: false,
});
export const isValidProvisioningForm = atom({
    key: 'vm-isValidProvisioningForm',
    default: false,
});

export const isQuickConfigSelected = atom({
    key: 'vm-isQuickConfigSelected',
    default: false,
});
