export default function LoggedUser() {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')) || { accessToken: undefined };

    return {
        FirstName() {
            return accessToken?.claims.FirstName || 'FirstName';
        },
        LastName() {
            return accessToken?.claims.LastName || 'LastName';
        },
    };
}

export function LoggedUserEmailId() {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage')) || { accessToken: undefined };
    return accessToken?.claims?.email || 'EmailId';
}
